import {TEXT_LIGHT} from './../../styles/colors';
import styled from 'styled-components'
import {CircularProgress} from '@material-ui/core'
import Button from '../../components/Button/Button'
import {Form} from 'formik'
import MicIcon from '@material-ui/icons/Mic'
import FastForwardIcon from '@material-ui/icons/FastForward'
import {ReactComponent as PigIconSvg} from "../../assets/icons/pig.svg";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 20px 64px;
  gap: 64px;
  color: ${TEXT_LIGHT};

  @media (max-width: 600px) {
    padding: 16px;
    flex-wrap: wrap;
  }
`

export const TitleAndLinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 42px;
  width: 70%;

  @media (max-width: 600px) {
    align-items: center;
    width: 100%;
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  margin-bottom: 42px;
  width: 30%;

  background: #000000;
  border-radius: 24px;
  padding: 42px 16px 84px 16px;
  margin-top: 4rem;
  gap: 14px;

  @media (max-width: 600px) {
    align-items: center;
    width: 100%;
  }
`

export const MenuHeader = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;

  text-align: right;
  text-transform: uppercase;

  color: #FFFFFF;
`

export const MenuButton = styled(Button)`
  &.MuiButtonBase-root {
    padding: 4px 42px 4px 24px;

    .MuiButton-label {
      svg {
        margin-right: 24px;
      }
    }
  }
`

export const MenuButtonWithoutIcon = styled(Button)`
  &.MuiButtonBase-root {
    padding: 4px 24px;

    .MuiButton-label {
      svg {
        margin-right: 24px;
      }
    }
  }
`

export const ItemGrid = styled.div`
  display: flex;
  gap: 12px;
  padding: 0 6%;
  flex-wrap: wrap;
  flex: 0 0 100%;

  @media (max-width: 600px) {
    padding: 0;
  }
`

interface ItemStylesInterface {
  isDark?: boolean
  textCenter?: boolean
}

export const EmptyStateMesage = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 40px;

  text-align: center;

  color: #000000;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-top: 3rem;

  flex: 1;

  u {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    order: 1;
    margin-top: 0;
  }
`

export const Item = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 20px;
  flex: 0 0 100%;

  @media (max-width: 600px) {
    flex: 0 0 100%;
    order: 2;
  }
`

export const ItemWrapper = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`

export const ItemIcons = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: end;
  flex: 0 0 72px;
  gap: 8px;

  @media (max-width: 600px) {
    flex-wrap: nowrap;
    display: flex;
  }
`

export const ItemDescription = styled.div.attrs(
  (props: { disabled: boolean }) => props,
)`
  opacity: ${props => props.disabled ? '0.40' : '1'};
`

export const ItemInformation = styled.div.attrs(
  (props: { disabled: boolean }) => props,
)`
  opacity: ${props => props.disabled ? '0.40' : '1'};
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 0 0 50%;
  width: 100%;

  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
`

export const ItemTitle = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
  white-space: nowrap;
`

export const ItemReleaseDate = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  display: inline;
  margin-right: 2rem;
  white-space: nowrap;
  
  span {
    margin-left: 4px;
  }
`

export const ItemPlayCount = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  display: inline;
  white-space: nowrap;
`

export const ItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const ItemBody = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 32px 32px 48px 32px;
  word-break: break-word;
`

export const ButtonWrapper = styled.div`
  justify-content: end;
  width: 100%;
  display: flex;

  @media (max-width: 600px) {
    justify-content: center;
  }
`

export const PageTitle = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 80px;
  color: #000000;
  margin-top: 16px;
  width: 100%;
  margin-left: 42px;

  @media (max-width: 600px) {
    text-align: center;
    font-size: 2.5rem;
    line-height: normal;
    margin-bottom: 0;
    margin-left: 0;
  }
`

export const MessageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const LoadingCircle = styled(CircularProgress)`
  margin-left: 12px;
`

export const SupportTierCardWrapper = styled.div`
  width: 40%;
  margin-bottom: 16px;

  &.MuiCard-root {
    background-color: #1f1f1f;
    color: white;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`

export const TiersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

export const SearchBarWrapper = styled.div`
  margin: 32px 0 16px 0;
  width: 100%;
`

export const ListHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 3rem 0 2rem 0;

  span {
    font-family: 'Gellix';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
    background: black;
    padding: 0.5rem 2rem;
    border: 2px solid #000000;
    border-radius: 99px;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: #000;
      left: 0;
      top: 50%;
      position: absolute;
      z-index: -1;
    }
  }
`

export const MicrophoneCircledIcon = styled(MicIcon)`
  background: black;
  color: #fff;
  border-radius: 100%;
  width: 1.6rem;
  height: 1.6rem;
  padding: 4px;
  margin-top: 6px;
  margin-bottom: 6px;

  @media (max-width: 600px) {
    margin-top: 0;
    margin-left: 0;
  }
`

export const PigIcon = styled(PigIconSvg)`
  width: 2rem;
  height: 2rem;
  margin-top: 6px;
  margin-bottom: 6px;

  @media (max-width: 600px) {
    margin-top: 0;
  }
`

export const MicrophoneIcon = styled(MicIcon)`
  width: 1.4rem;
  height: 1.4rem;
`

export const ForwardIcon = styled(FastForwardIcon)`
  background: black;
  color: #fff;
  border-radius: 100%;
  width: 1.3rem;
  height: 1.3rem;
  padding: 2px;
`

export const headerAndNavigation = styled.div``
