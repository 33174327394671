import * as R from 'ramda'
import { auth } from '../services/firebase'

import { getProfiles } from '../services/api'

const localStorage = window.localStorage

export type ProfileType = {
  profile: 'listener' | 'brand' | 'podcast'
  id: string
  name: string
  avatar: string
  selectedPlan: 'free' | 'premium' | undefined
  podcastRole: 'manager' | 'editor' | undefined
}

export type ProfilesType = {
  availableProfiles: ProfileType[] | []
  currentProfile: ProfileType | undefined
  errorMsg?: string | undefined
}

const initialState = {
  availableProfiles: [],
  currentProfile: undefined,
}

const TYPES = {
  REFRESH_AVAILABLE_PROFILES: 'REFRESH_AVAILABLE_PROFILES',
  CHANGE_PROFILE: 'CHANGE_PROFILE',
  SET_PROFILE_ERROR: 'SET_PROFILE_ERROR',
  SET_LISTENER_PLAN: 'SET_LISTENER_PLAN',
  SET_STATE_FROM_STORAGE: 'SET_STATE_FROM_STORAGE',
  CLEAR_PROFILE_STATE: 'CLEAR_PROFILE_STATE',
}

const saveState = (state: any): void => {
  const currentUser = auth.currentUser
  if (currentUser) {
    const userProfilesState = {
      email: currentUser.email,
      profilesState: state,
    }
    localStorage.setItem(
      `@UserProfilesState`,
      JSON.stringify(userProfilesState),
    )
  }
}

export const profiles = (
  state = { ...initialState } as ProfilesType,
  action: any,
): any => {
  switch (action.type) {
    case TYPES.REFRESH_AVAILABLE_PROFILES: {
      let currentProfile
      const refreshedPreviousCurrentProfile = R.find(
        R.propEq('id', state.currentProfile && state.currentProfile.id),
      )(action.availableProfiles)
      if (!state.currentProfile || !refreshedPreviousCurrentProfile) {
        currentProfile = R.find(R.propEq('profile', 'listener'))(
          action.availableProfiles,
        )
      } else {
        currentProfile = refreshedPreviousCurrentProfile
      }
      const newState = {
        availableProfiles: action.availableProfiles,
        currentProfile,
      }
      saveState(newState)
      return newState
    }

    case TYPES.CHANGE_PROFILE: {
      const newState = {
        ...state,
        currentProfile: action.profile,
      }
      saveState(newState)
      return newState
    }

    case TYPES.SET_PROFILE_ERROR:
      return {
        availableProfiles: [],
        currentProfile: undefined,
        errorMsg: 'Erro ao carregar perfis',
      }

    case TYPES.SET_LISTENER_PLAN: {
      const newState = {
        availableProfiles: R.map((availableProfile) => {
          if (availableProfile.profile !== 'listener') return availableProfile
          return {
            ...availableProfile,
            selectedPlan: action.plan,
          }
        }, state.availableProfiles),
        currentProfile: {
          ...state.currentProfile,
          selectedPlan: action.plan,
        },
      }
      saveState(newState)
      return newState
    }

    case TYPES.SET_STATE_FROM_STORAGE: {
      return action.state
    }

    case TYPES.CLEAR_PROFILE_STATE: {
      return { ...initialState }
    }

    default:
      return state
  }
}

export const refreshAvailableProfiles = (): any => {
  return async (dispatch: any) => {
    const availableProfiles = await getProfiles()
    if (availableProfiles.error) {
      alert(
        'Ops! Não conseguimos carregar seus perfis... Verifique sua conexão com a internet.',
      )
      dispatch({
        type: TYPES.SET_PROFILE_ERROR,
      })
      return Promise.reject(availableProfiles.error)
    }

    dispatch({
      type: TYPES.REFRESH_AVAILABLE_PROFILES,
      availableProfiles: availableProfiles.data,
    })

    return Promise.resolve()
  }
}

export const setListenerPlan = (plan: ProfileType['selectedPlan']): any => ({
  type: TYPES.SET_LISTENER_PLAN,
  plan,
})

export const setProfileStoreFromStorage = (state: ProfileType): any => ({
  type: TYPES.SET_STATE_FROM_STORAGE,
  state,
})

export const clearProfileState = (): any => ({
  type: TYPES.CLEAR_PROFILE_STATE,
})

export const isPremium = (state: ProfilesType): boolean | undefined => {
  const plan = R.path(['currentProfile', 'selectedPlan'], state)
  return plan === 'premium'
}

export const changeProfile = (profile: ProfileType): any => {
  return async (dispatch: any) => {
    dispatch({
      type: TYPES.CHANGE_PROFILE,
      profile,
    })
  }
}
