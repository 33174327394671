import React from 'react'
import CardWithDocumentForm from './CardWithDocumentForm'

import { PostCreditCard } from '../../types'

type Props = {
  onAddCard: (creditCard: PostCreditCard) => Promise<void>
}

const CardAndAddressInputs: React.FC<Props> = ({ onAddCard }: Props) => {
  const onAddNewCard = async (creditCardInfo: any): Promise<void> => {
    const creditCard = {
      type: 'creditCard' as 'creditCard',
      creditCard: {
        cardNumber: creditCardInfo.card.cardNumber,
        cardName: creditCardInfo.card.cardName,
        cardDueDate: creditCardInfo.card.cardDueDate,
        cardCVV: creditCardInfo.card.cardCVV,
        countryCode: 'br',
      },
      document: {
        type: 'cpf' as 'cpf',
        number: creditCardInfo.card.cpf,
      },
    }
    onAddCard(creditCard)
  }

  return (
    <div>
      <CardWithDocumentForm
        onSave={(card: any): void => {
          onAddNewCard({ card })
        }}
      />
    </div>
  )
}

export default CardAndAddressInputs
