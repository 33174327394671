import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactPlayer from 'react-player/file'
import { CircularProgress, Tabs } from '@material-ui/core'

import useFetch from '../../hooks/useFetch'
import {
  authenticatedGetPlaylistEpisodes,
  deleteTopic,
  getEpisodeDetails,
  getFeed,
  getPodcastArticlesList,
  getPodcastArticlesListByUrlPathName,
  getPodcastArticlesListUnauthenticated,
  getPodcastEpisodes,
  getPodcastEpisodesByUrlPathName,
  getPlaylistsFromPodcastProfile,
  getPodcastPlaylistsByUrlPathName,
  getPodcastTopicsList,
  getSupportInformationByPodcastId,
  getSupportInformationByUrlPathName,
  unauthenticatedGetPlaylistEpisodes,
  unauthenticatedGetPodcastEpisodesById,
  unauthenticatedGetPodcastEpisodesByUrlPathName,
} from '../../services/api'
import { logAnalyticsEvent } from '../../services/firebase'
import { ApiFetch } from '../../services/ApiFetch'
import BasePage from '../../components/BasePage/BasePage'
import Button from '../../components/Button/Button'
import EpisodeCardWrapper from '../../components/EpisodeCard/EpisodeCardWrapper'
import {
  ArticleSummaryDTO,
  Episode,
  FeedDTO,
  FeedItemDTO,
  FullSupportInformation,
  Playlist,
  SupportTier,
  TopicSummaryDTO,
} from '../../types'
import { useTypedSelector } from '../../reducers'

import * as Styled from './Podcast.styled'
import { useGoToRoute, useQuery } from '../../Routes/RouteAux'
import { getCreateTopicPath, getHomePath, getSupportPodcastPath } from '../../Routes/RouteNames'

import TabPanel from './TabPanel'
import PlaylistsTab from './PlaylistsTab'
import ArticlesTab from './ArticlesTab'
import PodcastSummary from './components/PodcastSummary/index'
import usePlayer from 'hooks/usePlayer'
import OptionsButton from 'components/OptionsButton/OptionsButton'
import FeedTab from './FeedTab'
import { uniqBy } from 'ramda'
import { FORUM_FF } from '../../services/featureFlags'
import Dialog from '../../components/Dialog/Dialog'
import { Colors } from 'styles'
import SupportTierCard from 'components/SupportTierCard/SupportTierCard'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

interface PodcastProps {
  signOut: () => Promise<void>
  podcastId: string
  avoidRedirect?: boolean
}

const PROGRESS_INTERVAL = 1000

export interface PlayerCurrentEpisode {
  currentTime: number
  duration: number
  totalPlayedTime: number
  currentAudioUrl: string
  currentEpisodeId: string
}

const PodcastContent: React.FC<PodcastProps> = ({
  signOut,
  podcastId,
  avoidRedirect,
}) => {
  const { podcastId: podcastIdParam } = useParams<{ podcastId: string }>()
  const { podcastUrlPathName } = useParams<{ podcastUrlPathName: string }>()

  const query = useQuery()
  const goToForum = query.get('forum')

  const [isPlaying, setIsPlaying] = useState(false)
  const [playerCurrentEpisode, setPlayerCurrentEpisode] =
    useState<PlayerCurrentEpisode>()
  const [episodes, setEpisodes] = useState<Episode[]>([])
  const [isLoadingEpisodes, setIsLoadingEpisodes] = useState(false)
  const [isLoadingMoreEpisodes, setIsLoadingMoreEpisodes] = useState(false)
  const [errorEpisodes, setErrorEpisodes] = useState(false)
  const [episodesPage, setEpisodesPage] = useState(0)
  const [hasFetchedAllEpisodes, setHasFetchedAllEpisodes] = useState(false)
  const [isLoadingPlaylists, setIsLoadingPlaylists] = useState(false)
  const [errorPlaylist, setErrorPlaylists] = useState(false)
  const [playlists, setPlaylists] = useState<Playlist[]>([])
  const [oldestFeedItemDate, setOldestFeedItemDate] = useState<number>(null)
  const [oldestTopicFeedItemDate, setOldestTopicFeedItemDate] =
    useState<number>(null)
  const [viewingForum, setViewingForum] = useState<boolean>(
    goToForum === 'true',
  )
  const [feed, setFeed] = useState<FeedDTO>({ items: [], oldestItemDate: null })
  const [topicFeed, setTopicFeed] = useState<FeedDTO>({
    items: [],
    oldestItemDate: null,
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [topicToDelete, setTopicToDelete] = useState<FeedItemDTO | undefined>()
  const [errorOnDelete, setErrorOnDelete] = useState<string>()

  const [currentTab, setCurrentTab] = useState('podcasts')

  const [supportValue, setSupportValue] = useState<SupportTier | null>(null)
  const [isModalFollowerIsOpen, setIsModalFollowerOpen] = useState<boolean>(false)
  const [isModalPodcastTeamOpen, setIsModalPodcastTeamOpen] = useState<boolean>(
    false,
  )
  const [isModalSupporterOpen, setIsModalSupporterOpen] = useState(false)


  const goToRoute = useGoToRoute()

  const currentProfile = useTypedSelector(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const playerController = usePlayer()

  // Fetch podcast information
  const {
    isLoading: isLoadingPodcast,
    data: podcastInformation,
    error: errorPodcast,
    fetchData: fetchPodcast,
  } = useFetch<FullSupportInformation>(
    useCallback(() => {
      if (podcastUrlPathName) {
        return getSupportInformationByUrlPathName(podcastUrlPathName)
      } else {
        return getSupportInformationByPodcastId(podcastId)
      }
    }, [podcastId, podcastUrlPathName]),
  )

  const {
    isLoading: isLoadingFeed,
    data: feedData,
    error: errorFeed,
    fetchData: fetchFeed,
  } = useFetch<FeedDTO>(
    useCallback(
      (oldestFeedItemDate) => getFeed(podcastId, oldestFeedItemDate || ''),
      [podcastId, oldestFeedItemDate],
    ),
    false,
  )

  // Fetch episodes
  const authenticatedGetPodcastEpisodes = (
    page?: number,
  ): Promise<ApiFetch> => {
    if (podcastUrlPathName) {
      return getPodcastEpisodesByUrlPathName(podcastUrlPathName, page)
    } else {
      return getPodcastEpisodes(podcastId, page)
    }
  }

  const unauthenticatedGetPodcastEpisodes = (
    page?: number,
  ): Promise<ApiFetch> => {
    if (podcastUrlPathName) {
      return unauthenticatedGetPodcastEpisodesByUrlPathName(
        podcastUrlPathName,
        page,
      )
    } else {
      return unauthenticatedGetPodcastEpisodesById(podcastId, page)
    }
  }

  const getEpisodesFetch = (page?: number): Promise<ApiFetch> => {
    if (currentProfile) {
      return authenticatedGetPodcastEpisodes(page)
    } else {
      return unauthenticatedGetPodcastEpisodes(page)
    }
  }

  const initialFetchEpisodes = async () => {
    setIsLoadingEpisodes(true)
    const res = await getEpisodesFetch()

    if (res.hasFailed()) {
      setErrorEpisodes(true)
    } else {
      if (res.data?.length < 100) {
        setHasFetchedAllEpisodes(true)
      }

      setEpisodes(res.data)
    }

    setIsLoadingEpisodes(false)
  }

  useEffect(() => {
    initialFetchEpisodes()
  }, [])

  const fetchEpisodesWithPage = async () => {
    const res = await getEpisodesFetch(episodesPage)

    if (!res.hasFailed()) {
      const currentEpisodes = episodes.slice()

      if (res.data?.length < 100) {
        setHasFetchedAllEpisodes(true)
      }

      currentEpisodes.push(...res.data)

      setEpisodes(currentEpisodes)
    } else {
      alert(
        'Ops! Não conseguimos carregar mais episódios. Por favor, tente novamente.',
      )
    }

    setIsLoadingMoreEpisodes(false)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }
  const handleCloseModalSupport = () => {
    setIsModalSupporterOpen(false)
  }

  const doDeleteTopic = async (topicId: string): Promise<void> => {
    const res = await deleteTopic(topicId)
    if (res.error) {
      setErrorOnDelete(res.error.message)
    }
  }

  useEffect(() => {
    if (episodesPage !== null && episodesPage !== 0) {
      fetchEpisodesWithPage()
    }
  }, [episodesPage])

  useEffect(() => {
    if (viewingForum) {
      fetchTopics()
    }
  }, [viewingForum])

  useEffect(() => {
    fetchFeed(oldestFeedItemDate)
  }, [oldestFeedItemDate])

  useEffect(() => {
    fetchTopics(oldestTopicFeedItemDate)
  }, [oldestTopicFeedItemDate])

  // Fetch playlists
  const getPlaylistsFetch = async () => {
    if (podcastUrlPathName) {
      return getPodcastPlaylistsByUrlPathName(podcastUrlPathName)
    } else {
      return getPlaylistsFromPodcastProfile(podcastId)
    }
  }

  const getPlaylistsEpisodesFetch = async (playlistId) => {
    if (currentProfile) {
      return authenticatedGetPlaylistEpisodes(playlistId)
    } else {
      return unauthenticatedGetPlaylistEpisodes(playlistId)
    }
  }

  const fetchPlaylists = async () => {
    setIsLoadingPlaylists(true)
    setPlaylists([])
    const resPlaylists = await getPlaylistsFetch()

    if (resPlaylists.hasFailed()) {
      setErrorPlaylists(true)
      setIsLoadingPlaylists(false)
      return
    }

    await Promise.all(
      resPlaylists.data.map(async (playlist) => {
        const resPlaylistEpisodes = await getPlaylistsEpisodesFetch(
          playlist.playlistId,
        )

        if (resPlaylistEpisodes.hasFailed()) {
          setErrorPlaylists(true)
          setIsLoadingPlaylists(false)
          return
        }

        setPlaylists((playlistsState) => {
          const updatedPlaylists = playlistsState.slice()

          updatedPlaylists.push({
            playlistId: playlist.playlistId,
            playlistTitle: playlist.title,
            episodes: resPlaylistEpisodes.data,
          })

          return updatedPlaylists
        })
      }),
    )

    setIsLoadingPlaylists(false)
  }

  useEffect(() => {
    fetchPlaylists()
  }, [])

  // Fetch articles
  const {
    isLoading: isLoadingArticles,
    data: articles,
    error: errorArticles,
    fetchData: fetchArticles,
  } = useFetch<ArticleSummaryDTO[]>(
    useCallback(() => {
      if (podcastId) {
        if (currentProfile) {
          return getPodcastArticlesList(podcastId)
        } else {
          return getPodcastArticlesListUnauthenticated(podcastId)
        }
      } else {
        return getPodcastArticlesListByUrlPathName(podcastUrlPathName)
      }
    }, [podcastId, podcastUrlPathName, currentProfile]),
  )

  // Fetch topics
  const {
    isLoading: isLoadingTopics,
    data: feedTopicsData,
    error: errorTopics,
    fetchData: fetchTopics,
  } = useFetch<FeedDTO>(
    useCallback(
      async (oldestTopicFeedItemDate) =>
        await getPodcastTopicsList(
          podcastId || podcastInformation?.supportSummary?.podcastId,
          oldestTopicFeedItemDate,
        ),
      [
        podcastId,
        podcastInformation?.supportSummary?.podcastId,
        oldestTopicFeedItemDate,
      ],
    ),
    false,
  )

  // Set initial current tab
  useEffect(() => {
    if (
      !isLoadingEpisodes &&
      !isLoadingPlaylists &&
      !isLoadingArticles &&
      !isLoadingFeed
    ) {
      if (feedData?.items?.length) {
        setCurrentTab('feed')
      } else {
        if (episodes?.length === 0) {
          if (playlists?.length !== 0) {
            setCurrentTab('playlists')
          } else {
            setCurrentTab('articles')
          }
        }
      }
    }
  }, [
    isLoadingEpisodes,
    isLoadingPlaylists,
    isLoadingArticles,
    isLoadingFeed,
    episodes,
    playlists,
    feedData,
  ])

  useEffect(() => {
    if (feedData) {
      setFeed({
        items: uniqBy(
          (feedItem: any) => feedItem.item.episodeId || feedItem.item.id,
          [...feed.items, ...feedData.items],
        ),
        oldestItemDate: feedData.oldestItemDate,
      })
    }
  }, [feedData])

  useEffect(() => {
    if (feedTopicsData) {
      setTopicFeed({
        items: uniqBy(
          (feedItem: any) => feedItem.item.episodeId || feedItem.item.id,
          [...topicFeed.items, ...feedTopicsData.items],
        ).filter(item => item.itemType === 'topic').sort(
          (a, b) =>
            Date.parse(b.item.createdAt.split('/').reverse().join('-')) -
            Date.parse(a.item.createdAt.split('/').reverse().join('-')),
        ),
        oldestItemDate: feedTopicsData.oldestItemDate,
      })
    }
  }, [feedTopicsData])


  const onCreateTopic = () => {
    if (podcastInformation.userCanManageForum) {
      return goToRoute(getCreateTopicPath(podcastInformation.supportSummary.podcastId))
    }

    switch (podcastInformation.forumTopicCreation) {
      default:
      case 'podcastTeam':
        if (
          currentProfile.profile === 'podcast' &&
          currentProfile.id ===
          podcastInformation.supportSummary.podcastId
        ) {
          goToRoute(getCreateTopicPath(podcastInformation.supportSummary.podcastId))
        } else {
          setIsModalPodcastTeamOpen(true)
        }
        return

      case 'supporters':
        if (podcastInformation.userInformation.isSupporter) {
          goToRoute(getCreateTopicPath(podcastInformation.supportSummary.podcastId))
        } else {
          setIsModalSupporterOpen(true)
        }
        return

      case 'followers':
        if (
          podcastInformation.userInformation.isFollower ||
          podcastInformation.userInformation.isSupporter
        ) {
          goToRoute(getCreateTopicPath(podcastInformation.supportSummary.podcastId))
        } else {
          setIsModalFollowerOpen(true)
        }
    }
  }

  // Player
  const playerRef = useRef<ReactPlayer>(null)

  const skipTime = (timeToSkip: number) => {
    playerRef.current?.seekTo(
      (playerRef.current ? playerRef.current.getCurrentTime() : 0) + timeToSkip,
      'seconds',
    )

    let newTime = playerCurrentEpisode?.currentTime + timeToSkip
    if (newTime <= 0) {
      newTime = 0
    } else if (newTime >= playerCurrentEpisode.duration) {
      newTime = playerCurrentEpisode.duration
    }

    setPlayerCurrentEpisode((prev) => {
      const newState = { ...prev }
      newState.currentTime = newTime

      return newState
    })
  }

  const setPlayerIsPlaying = (isPlayerPlaying: boolean) => {
    setIsPlaying(isPlayerPlaying)
  }

  const changeEpisode = (episode: Episode): void => {
    if (
      !playerCurrentEpisode ||
      episode.episodeId !== playerCurrentEpisode.currentEpisodeId
    ) {
      logAnalyticsEvent('play', {
        podcastId: episode.podcast.podcastId,
        episodeId: episode.episodeId,
        audioLength: episode.duration,
        episodeName: episode.title,
        podcastName: episode.podcast.title,
      })
      setPlayerCurrentEpisode({
        currentEpisodeId: episode.episodeId,
        currentAudioUrl: episode.audioUrl,
        totalPlayedTime: episode.userProfileData.totalPlayedTime || 0,
        currentTime: episode.userProfileData.currentPlayerTime,
        duration: episode.duration,
      })
    }
    setIsPlaying(true)
  }

  useEffect(() => {
    if (isPlaying && !currentProfile) {
      setIsPlaying(false)
    }
  }, [currentProfile, isPlaying])

  const hasContent = (): boolean =>
    !!(
      episodes?.length ||
      playlists?.length ||
      articles?.length ||
      feedData?.items?.length
    )

  const toggleViewingForum = (): void => {
    setViewingForum(!viewingForum)
  }

  const emptyState = (
    <Styled.MessageWrapper>
      Este creator ainda não publicou nenhum conteúdo
    </Styled.MessageWrapper>
  )

  const renderSeeMoreButtonOrLoading = () => {
    if (hasFetchedAllEpisodes) return

    if (isLoadingMoreEpisodes) {
      return <CircularProgress />
    } else {
      return (
        <ButtonShadow
          variant="secondary"
          onPress={() => {
            setIsLoadingMoreEpisodes(true)

            setEpisodesPage(episodesPage + 1)
          }}
          label={'Ver mais episódios'}
        />
      )
    }
  }

  if (
    isLoadingPodcast ||
    isLoadingEpisodes ||
    isLoadingPlaylists ||
    isLoadingArticles
  ) {
    return (
      <BasePage signOut={signOut} isDark>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <Styled.MessageWrapper>
            <Styled.LoadingSpinner />
            Carregando...
          </Styled.MessageWrapper>
        </div>
      </BasePage>
    )
  }

  if (
    errorPodcast ||
    errorEpisodes ||
    errorPlaylist ||
    errorArticles ||
    errorTopics ||
    errorFeed
  ) {
    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>
            Ops, parece que não conseguimos encontrar o podcast ou tivemos algum
            erro
          </Styled.MessageWrapper>
          <Button
            variant="contained"
            fontColor="black"
            onClick={() => {
              fetchPodcast()
              initialFetchEpisodes()
              fetchArticles()
              fetchPlaylists()
            }}
          >
            Tentar novamente
          </Button>
          <Styled.ButtonWithMarginWrapper>
            <Button
              variant="contained"
              fontColor="black"
              onClick={() => goToRoute(getHomePath())}
            >
              Voltar para página inicial
            </Button>
          </Styled.ButtonWithMarginWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (podcastInformation?.hasCampaignEnabled && !avoidRedirect) {
    goToRoute(getSupportPodcastPath(podcastId))
  }

  const onDeleteTopic = (topic: FeedItemDTO) => {
    setTopicToDelete(topic)
    setIsModalOpen(true)
  }

  const renderTable = () => (
    <Styled.ContentWrapper>
      <Tabs
        value={currentTab}
        textColor="inherit"
        TabIndicatorProps={{
          style: {
            background: Colors.PURPLE[400],
          },
        }}
        onChange={(_event, value) => {
          setCurrentTab(value)
        }}
        variant="scrollable"
        scrollButtons='off'
      >
        <Styled.StyledTab label="mais recentes" value={'feed'} />
        {articles && articles.length > 0 && (
          <Styled.StyledTab label="textos e newsletters" value={'articles'} />
        )}
        {episodes && episodes.length > 0 && (
          <Styled.StyledTab
            label="Podcast"
            value={'podcasts'}
          />
        )}
        {playlists &&
          playlists.length > 0 &&
          playlists.find((p) => p.episodes && p.episodes.length) && (
            <Styled.StyledTab label="playlists" value={'playlists'} />
          )}

      </Tabs>
      <TabPanel value={'podcasts'} index={currentTab}>
        {episodes &&
          episodes.length > 0 &&
          <Styled.ListHeader>
            <Styled.Title>Nome</Styled.Title>
            <Styled.AditionalInfo>Data</Styled.AditionalInfo>
          </Styled.ListHeader>}
        {episodes &&
          episodes.length > 0 &&
          episodes.map((episode, idx) => (
            <EpisodeCardWrapper
              key={episode.episodeId}
              idx={idx}
              episode={episode}
              episodeId={episode.episodeId}
              isPlaying={
                playerCurrentEpisode?.currentEpisodeId ===
                episode.episodeId && isPlaying
              }
              onPlay={async () => {
                logAnalyticsEvent('play', {
                  podcastId: episode.podcast.podcastId,
                  episodeId: episode.episodeId,
                  audioLength: episode.duration,
                  episodeName: episode.title,
                  podcastName: episode.podcast.title,
                })

                try {
                  const response = await getEpisodeDetails(
                    episode.episodeId,
                  )
                  if (response?.error) {
                    playerController.playNewAudio({
                      currentAudioUrl: episode.audioUrl,
                      title: episode.title,
                      link: episode.link,
                      author: episode.author,
                      podcastTitle: episode.podcast.title,
                      currentTime:
                        episode.userProfileData.currentPlayerTime,
                      thumbnail: episode.thumbnail,
                      currentEpisodeId: episode.episodeId,
                    })
                  } else {
                    const episodeUpdated = response.data
                    playerController.playNewAudio({
                      currentAudioUrl: episodeUpdated.audioUrl,
                      title: episodeUpdated.title,
                      link: episodeUpdated.link,
                      author: episodeUpdated.author,
                      podcastTitle: episodeUpdated.podcast.title,
                      currentTime:
                        episodeUpdated.userProfileData
                          .currentPlayerTime,
                      thumbnail: episodeUpdated.thumbnail,
                      currentEpisodeId: episodeUpdated.episodeId,
                    })
                  }
                } catch (e) {
                  playerController.playNewAudio({
                    currentAudioUrl: episode.audioUrl,
                    title: episode.title,
                    link: episode.link,
                    author: episode.author,
                    podcastTitle: episode.podcast.title,
                    currentTime:
                      episode.userProfileData.currentPlayerTime,
                    thumbnail: episode.thumbnail,
                    currentEpisodeId: episode.episodeId,
                  })
                }
              }}
              onStop={() => setPlayerIsPlaying(false)}
              onRewind={() => skipTime(-30)}
              onSkip={() => skipTime(30)}
              currentTime={
                playerCurrentEpisode?.currentEpisodeId ===
                episode.episodeId && playerCurrentEpisode?.currentTime
              }
              podcastId={podcastInformation?.supportSummary?.podcastId}
            />
          ))}
        {renderSeeMoreButtonOrLoading()}
      </TabPanel>
      <TabPanel value={'playlists'} index={currentTab}>
        <PlaylistsTab
          playerCurrentEpisode={playerCurrentEpisode}
          playerIsPlaying={isPlaying}
          setIsPlaying={setPlayerIsPlaying}
          skipTime={skipTime}
          playlists={playlists}
          podcastId={podcastInformation?.supportSummary?.podcastId}
        />
      </TabPanel>
      <TabPanel value={'articles'} index={currentTab}>
        <Styled.ListHeader>
          <Styled.Title>Nome</Styled.Title>
          <Styled.AditionalInfo>Data</Styled.AditionalInfo>
        </Styled.ListHeader>
        <ArticlesTab
          podcastImage={
            podcastInformation?.supportSummary?.thumbnail ||
            podcastInformation?.supportSummary?.image
          }
          articlesList={articles}
          podcastId={podcastInformation?.supportSummary?.podcastId}
        />
      </TabPanel>
      <TabPanel value="feed" index={currentTab}>
        <FeedTab
          setOldestFeedItemDate={setOldestFeedItemDate}
          podcastImage={
            podcastInformation?.supportSummary?.thumbnail ||
            podcastInformation?.supportSummary?.image
          }
          feedList={{ oldestItemDate: feed.oldestItemDate, items: feed.items.filter(content => content.itemType !== 'topic') }}
          podcastId={podcastInformation?.supportSummary?.podcastId}
          isFetching={isLoadingFeed}
          playerCurrentEpisode={playerCurrentEpisode}
          playerIsPlaying={isPlaying}
          setIsPlaying={setPlayerIsPlaying}
          skipTime={skipTime}
          forumVisibility={podcastInformation.forumVisibility}
          userCanManageForum={podcastInformation.userCanManageForum}
          onDeleteTopic={onDeleteTopic}
        />
      </TabPanel>
    </Styled.ContentWrapper>
  )

  const renderTopics = () => (<Styled.TopicWrapper>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Styled.SectionTitle>
        Mural
      </Styled.SectionTitle>
      {viewingForum && <ButtonShadow label='Criar novo tópico' leftIcon={<FontAwesomeIcon
        icon={faPlus}
        color={Colors.BRAND_SECONDARY}
        name="plus-icon"
      />}
        onPress={() => onCreateTopic()}
      />}
    </div>
    <FeedTab
      setOldestFeedItemDate={setOldestTopicFeedItemDate}
      podcastImage={
        podcastInformation?.supportSummary?.thumbnail ||
        podcastInformation?.supportSummary?.image
      }
      feedList={topicFeed}
      podcastId={podcastInformation?.supportSummary?.podcastId}
      isFetching={isLoadingTopics}
      playerCurrentEpisode={playerCurrentEpisode}
      playerIsPlaying={isPlaying}
      setIsPlaying={setPlayerIsPlaying}
      skipTime={skipTime}
      forumVisibility={podcastInformation.forumVisibility}
      userCanManageForum={podcastInformation.userCanManageForum}
      onDeleteTopic={onDeleteTopic}
    />
  </Styled.TopicWrapper>)

  const renderTierCards = () => (!podcastInformation.supportSummary.hideSupportValues &&
    podcastInformation.supportSummary.canBeSupported && (
      <Styled.Tiers>
        <Styled.SectionTitle>
          Apoios
        </Styled.SectionTitle>
        <Styled.TiersCards>
          {podcastInformation.supportTiers.map((tier) => (
            <Styled.SupportCardContainer>
              <SupportTierCard
                key={tier.title}
                title={tier.title}
                description={tier.description}
                value={tier.value}
                color={
                  podcastInformation.podcastBackgroundColor
                    ? Colors.BRAND_SECONDARY
                    : undefined
                }
                fontColor={
                  podcastInformation.podcastBackgroundColor
                    ? Colors.BRAND_PRIMARY
                    : undefined
                }
                colorOnHover={
                  podcastInformation.podcastBackgroundColor
                    ? Colors.LIGHT_GREY
                    : undefined
                }
                supportRoute={`/payments/support/${podcastId}?supportId=${tier.id}&value=${tier.value}`}
              />
              <Styled.Whitespace />
            </Styled.SupportCardContainer>
          ))}
        </Styled.TiersCards>
      </Styled.Tiers>
    ))

  return (
    <BasePage signOut={signOut}>
      <Dialog
        isDialogOpen={isModalOpen}
        handleCloseDialog={handleCloseModal}
        dialogText="Tem certeza que deseja apagar o tópico?"
        secondaryDialogText="Essa ação não pode ser desfeita  "
        dialogActionButtonText="Apagar"
        onClickDialogActionButton={async () => {
          await doDeleteTopic(
            (topicToDelete.item as unknown as TopicSummaryDTO).id,
          )
          handleCloseModal()
          setTopicFeed({
            items: topicFeed.items.filter((item) => item !== topicToDelete),
            oldestItemDate: topicFeed.oldestItemDate,
          })
          setFeed({
            items: feedData.items.filter((item) => item !== topicToDelete),
            oldestItemDate: feedData.oldestItemDate,
          })
        }}
        noActionText="cancelar"
      />
      <Dialog
        isDialogOpen={isModalPodcastTeamOpen}
        handleCloseDialog={() => setIsModalPodcastTeamOpen(false)}
        dialogText={`Apenas o perfil deste creator pode criar tópicos`}
        noActionText="ok"
      />
      <Dialog
        isDialogOpen={isModalFollowerIsOpen}
        handleCloseDialog={() => setIsModalFollowerOpen(false)}
        dialogText={`Apenas seguidores deste creator podem criar tópicos`}
        noActionText="ok"
      />
      <Styled.PageWrapper>
        <Styled.PodcastMenuWrapper>
          <OptionsButton
            podcastId={
              podcastId || podcastInformation?.supportSummary?.podcastId
            }
          />
        </Styled.PodcastMenuWrapper>

        <PodcastSummary
          podcastFullSupportInformation={podcastInformation}
          onToggleViewingForum={toggleViewingForum}
          isViewingForum={viewingForum}
          showPodcastOptions={!supportValue?.id}
        />
        <Styled.PageWrapperLeft>

          {!hasContent() ? (
            emptyState
          ) : viewingForum && FORUM_FF ? (
            renderTopics()
          ) :
            (
              <>
                {renderTable()}
                {renderTierCards()}
              </>
            )}
        </Styled.PageWrapperLeft>

      </Styled.PageWrapper >
      <Dialog
        isDialogOpen={isModalSupporterOpen}
        handleCloseDialog={handleCloseModalSupport}
        dialogText={'Apenas apoiadores deste creator podem criar tópicos'}
        dialogActionButtonText="apoiar podcast"
        onClickDialogActionButton={() =>
          goToRoute(getSupportPodcastPath(podcastInformation.supportSummary.podcastId))
        }
        noActionText="ok"
      />
    </BasePage >
  )
}

export default PodcastContent
