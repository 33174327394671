import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BasePage from '../../components/BasePage/BasePage'
import useFetch from '../../hooks/useFetch'
import { ArticleDTO, FullSupportInformation } from '../../types'
import {
  getArticle,
  getSupportInformationByPodcastId,
  markArticlePageView,
} from '../../services/api'
import * as Styled from './Article.styled'
import { useGoToRoute } from '../../Routes/RouteAux'
import Button from '../../components/Button/Button'
import RichTextEditor from '../../components/RichTextEditor'
import ShareButton from '../../components/ShareButton/ShareButton'
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu'
import ReportContentDialog from '../../components/ReportContentDialog/ReportContentDialog'
import { useTypedSelector } from '../../reducers'
import { getPodcastPath, getSupportPodcastPath } from '../../Routes/RouteNames'
import RoundedCard from 'components/RoundedCard'
import { Colors } from '../../styles'

interface ArticleProps {
  signOut: () => Promise<void>
}

const Article: React.FC<ArticleProps> = ({ signOut }) => {
  const { articleId } = useParams<{ articleId: string }>()
  const [isLoadingPodcast, setIsLoadingPodcast] = useState(true)
  const [podcastInformation, setPodcastInformation] =
    useState<FullSupportInformation>()
  const [errorPodcast, setErrorPodcast] = useState(false)
  const [reportDialogOpen, setReportDialogOpen] = useState<boolean>(false)

  const goToRoute = useGoToRoute()

  const currentProfile = useTypedSelector(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const {
    isLoading: isLoadingArticle,
    data: articleInformation,
    error: errorArticle,
    fetchData: fetchArticle,
  } = useFetch<ArticleDTO>(
    useCallback(() => {
      return getArticle(articleId)
    }, [articleId]),
  )

  const fetchPodcast = async (podcastId: string): Promise<void> => {
    setIsLoadingPodcast(true)
    setErrorPodcast(false)

    const res = await getSupportInformationByPodcastId(podcastId)

    if (res.hasFailed()) {
      setErrorPodcast(true)
    } else {
      setPodcastInformation(res.data)
    }

    setIsLoadingPodcast(false)
  }

  useEffect(() => {
    if (articleInformation) {
      fetchPodcast(articleInformation.podcastId)
    }
  }, [articleInformation])

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (articleInformation) {
      timeout = setTimeout(() => {
        markArticlePageView(articleInformation.id)
      }, 10000)
    }

    return () => clearTimeout(timeout)
  }, [articleInformation])

  const errorMessage = (errorIsPaymentRequired: boolean): string => {
    return errorIsPaymentRequired && podcastInformation
      ? `Ops, parece que esse artigo é exclusivo para apoiadores de ${podcastInformation.supportSummary?.title}.`
      : `Ops, parece que tivemos um erro aqui.`
  }

  const errorButtonLabel = (errorIsPaymentRequired: boolean): string => {
    return errorIsPaymentRequired ? 'Apoiar' : 'Tentar novamente'
  }

  if (errorPodcast || errorArticle) {
    const errorIsPaymentRequired =
      !!errorArticle && errorArticle.status === 402 && !errorPodcast

    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <Styled.ErrorWrapper>
            <Styled.MessageWrapper>
              {errorMessage(errorIsPaymentRequired)}
            </Styled.MessageWrapper>
            <Button
              variant="contained"
              buttonColorOnHover="#940a00"
              buttonColor="#cc0033"
              onClick={() => {
                if (errorIsPaymentRequired) {
                  goToRoute(
                    getSupportPodcastPath(
                      podcastInformation?.supportSummary?.podcastId,
                    ),
                  )
                } else {
                  fetchArticle()
                }
              }}
            >
              <b>{errorButtonLabel(errorIsPaymentRequired)}</b>
            </Button>
          </Styled.ErrorWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (isLoadingPodcast || isLoadingArticle) {
    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  return (
    <BasePage signOut={signOut} isDark>
      <ReportContentDialog
        contentType="article"
        contentId={articleInformation.id}
        isDialogOpen={reportDialogOpen}
        handleCloseDialog={() => setReportDialogOpen(false)}
      />
      <Styled.PageWrapper>
        <RoundedCard flex={1}>
          <Styled.ColoredBackground>
            <Styled.PodcastInfoAndFollowButton>
              <Styled.TitleAndImageWrapper
                onClick={() =>
                  goToRoute(getPodcastPath(articleInformation.podcastId))
                }
              >
                <Styled.PodcastProfileImage
                  src={
                    podcastInformation.supportSummary.thumbnail ||
                    podcastInformation.supportSummary.image
                  }
                />
                <Styled.PodcastTitle>
                  {podcastInformation.supportSummary.title}
                </Styled.PodcastTitle>
              </Styled.TitleAndImageWrapper>
            </Styled.PodcastInfoAndFollowButton>
            <Styled.ArticleWrapper>
              <Styled.ArticleTitle>
                {articleInformation.title}
              </Styled.ArticleTitle>
              <Styled.ArticleDescription>
                {articleInformation.description}
              </Styled.ArticleDescription>
              <Styled.InfoAndArticleMenuWrapper>
                <Styled.AdditionalInfoWrapper>
                  <Styled.AdditionalInfo>
                    {articleInformation.createdAt}
                  </Styled.AdditionalInfo>
                  <ShareButton
                    shareType="article"
                    shareId={articleInformation.id}
                    linkTitle={articleInformation.title}
                    linkImage={
                      articleInformation.image ||
                      podcastInformation.supportSummary.image
                    }
                    linkDescription={articleInformation.description}
                    redirectToMobileApp={false}
                    iconButton
                    color={'rgba(255,255,255, 0.5)'}
                  />
                </Styled.AdditionalInfoWrapper>
                <DropdownMenu
                  items={[
                    {
                      title: 'Denunciar txt',
                      onClick: () => {
                        setReportDialogOpen(true)
                      },
                    },
                  ]}
                  color={Colors.BRAND_SECONDARY}
                />
              </Styled.InfoAndArticleMenuWrapper>
              <RichTextEditor
                readonly
                initialValue={articleInformation.body}
                fontFamily="PT-Serif"
              />
            </Styled.ArticleWrapper>
            <Styled.FullWidthDivider />
            <Styled.CreatorResume>
              <Styled.CreatorResumeImageWithFollowButton>
                <Styled.CreatorResumeImage
                  src={
                    podcastInformation.supportSummary.thumbnail ||
                    podcastInformation.supportSummary.image
                  }
                  onClick={() =>
                    goToRoute(getPodcastPath(articleInformation.podcastId))
                  }
                />
              </Styled.CreatorResumeImageWithFollowButton>
              <Styled.CreatorResumeContent>
                <Styled.CreatorResumeTitleWithFollowButton>
                  <Styled.CreatorResumeTitle
                    onClick={() =>
                      goToRoute(getPodcastPath(articleInformation.podcastId))
                    }
                  >
                    {podcastInformation.supportSummary.title}
                  </Styled.CreatorResumeTitle>
                </Styled.CreatorResumeTitleWithFollowButton>
                <Styled.CreatorResumeDescription>
                  {podcastInformation.supportSummary.description}
                </Styled.CreatorResumeDescription>
              </Styled.CreatorResumeContent>
            </Styled.CreatorResume>
          </Styled.ColoredBackground>
        </RoundedCard>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default Article
