import React, { useState } from 'react'
import styled from 'styled-components'
import DOMPurify from 'dompurify'
import {
  Collapse,
  Snackbar,
  Modal,
} from '@material-ui/core'

import {
  useGoToRoute,
  usePathName,
  createPathWithQueryParam,
} from '../../../../Routes/RouteAux'
import {
  getCreateTopicPath,
  getLoginPath,
  getSupportPodcastPath,
} from '../../../../Routes/RouteNames'

import { useTypedSelector } from '../../../../reducers'

import { followPodcast, getUserSupports } from '../../../../services/api'

import { FullSupportInformation, SupportData } from '../../../../types'

import FollowerImages from './components/FollowerImages'
import UserMembershipModal from './components/UserMembershipModal'

import Dialog from '../../../../components/Dialog/Dialog'
import { FORUM_FF } from '../../../../services/featureFlags'
import { Colors } from 'styles'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import { LETTER_SPACING_1 } from 'styles/typography'

const Wrapper = styled.div`
  display: flex;
  width: calc(50% - 3.5rem);
  align-items: center;
  margin: 20px 3.5rem;
  color: #e4e4e4;
  flex-direction: column;
  align-self: start;

  @media (max-width: 900px) {
    width: 80%;
    margin: 20px 2rem;
  }
`

const PodcastImage = styled.img`
  width: 8rem;
  height: 8rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: 5px solid ${Colors.GREY[400]};
  z-index: 1;
`

const PodcastImageShadow = styled.div`
  width: 8rem;
  height: 8rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: 5px solid ${Colors.GREY[400]};
  transform: translate(.8rem, .8rem);
  position: absolute;
`

const PodcastTitle = styled.div`
  font-size: 3rem;
  color: ${Colors.GREY[400]};
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  letter-spacing: -1.2px;
  font-family: PP-Neue-Machina;
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 1.8rem;
    margin-bottom: 0px;
    text-align: left;
  }
`

const CollapsedArea = styled.div<{ expandedDescription: boolean }>`
  background: ${(props) =>
    !props.expandedDescription
      ? '-webkit-linear-gradient(top, black 70%, white)'
      : 'black'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  word-break: break-word;
  justify-content: start;
  align-self: start;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
`

export const SeeMoreInteractiveArea = styled.div`
`

export const Description = styled.div<{ expandedDescription: boolean }>`
  word-break: break-word;
  font-size: 16px;
  font-family: Inter;
  letter-spacing: -${LETTER_SPACING_1}px;

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 100%;
  }
`

const FollowerImagesAndButton = styled.div<{ imagesLength: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5rem;

  @media (max-width: 600px) {
    height: auto;
    margin-bottom: 16px;
  }
`
const FollowerImagesContainer = styled.div`
  margin-right: 75px;

  @media (max-width: 500px) {
    margin-right: 0;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  align-self: start;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`

const Text = styled.span`
  font-family: Inter;
  color: ${Colors.GREY[400]};
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -${LETTER_SPACING_1}px;
`

const PodcastInfoContainer = styled.div`
  margin-left: 1.5rem;

  @media (max-width: 400px) {
    margin-left: 0;
  }
`


interface PodcastSummaryInterface {
  podcastFullSupportInformation: FullSupportInformation
  onToggleViewingForum?: Function
  isViewingForum: boolean
  showPodcastOptions: boolean
  onClick?: () => void
}

const PodcastSummary: React.FC<PodcastSummaryInterface> = ({
  podcastFullSupportInformation,
  onToggleViewingForum,
  isViewingForum,
  showPodcastOptions,
  onClick
}) => {
  const goToRoute = useGoToRoute()
  const pathName = usePathName()
  const loginRoute = createPathWithQueryParam(getLoginPath(), {
    redirectTo: pathName,
  })


  const [followersThumbImages] = useState(
    podcastFullSupportInformation.followersInfo.thumbnails.slice(0, 10),
  )
  const [podcastInfo, setPodcastInfo] = useState(podcastFullSupportInformation)
  const [expandedDescription, setExpandedDescription] = useState(false)
  const [isLoadingJoinButton, setIsLoadingJoinButton] = useState(false)
  const [joinButtonError, setJoinButtonError] = useState<boolean>()
  const [userMembershipModalOpen, setUserMembershipModalOpen] =
    useState<boolean>(false)
  const [loginDialogOpen, setLoginDialogOpen] = useState<boolean>(false)
  const [wrongProfileDialogOpen, setWrongProfileDialogOpen] =
    useState<boolean>(false)
  const [supportData, setSupportData] = useState<SupportData | null>(null)

  const currentProfile = useTypedSelector(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const openUserMembershipModal = async (): Promise<void> => {
    const supportDataRes = await getUserSupports(
      podcastFullSupportInformation.supportSummary.podcastId,
    )
    setIsLoadingJoinButton(false)
    if (supportDataRes.error && supportDataRes.error.status !== 404) {
      setJoinButtonError(true)
    } else {
      setSupportData(supportDataRes.data)
      setUserMembershipModalOpen(true)
    }
  }

  const onPressButton = async (): Promise<void> => {
    if (!currentProfile) return setLoginDialogOpen(true)
    if (currentProfile.profile !== 'listener')
      return setWrongProfileDialogOpen(true)
    if (!podcastInfo.userInformation.isFollower) {
      setIsLoadingJoinButton(true)
      const req = await followPodcast(
        podcastFullSupportInformation.supportSummary.podcastId,
      )

      if (req.hasFailed()) {
        setIsLoadingJoinButton(false)
        setJoinButtonError(true)
      } else {
        setPodcastInfo({
          ...podcastInfo,
          userInformation: { ...podcastInfo.userInformation, isFollower: true },
        })
        openUserMembershipModal()
      }
    } else {
      openUserMembershipModal()
    }
  }

  const onForum = () => {
    onToggleViewingForum()
  }

  return (
    <Wrapper>
      <Row>
        <>
          <PodcastImage src={podcastInfo.supportSummary.image} onClick={onClick ? onClick : null} />
          <PodcastImageShadow />
        </>
        <PodcastInfoContainer >
          <PodcastTitle>{podcastInfo.supportSummary.title}</PodcastTitle>
          <FollowerImagesAndButton imagesLength={followersThumbImages.length}>
            <FollowerImagesContainer>
              <FollowerImages
                images={followersThumbImages}
                isFollower={podcastInfo.userInformation.isFollower}
              />
            </FollowerImagesContainer>
            <Text>{followersThumbImages.length} membros</Text>
          </FollowerImagesAndButton>
        </PodcastInfoContainer>
      </Row>
      {showPodcastOptions &&
        <>
          <Row style={{ justifyContent: 'start', width: '100%', gap: 24 }}>
            {FORUM_FF && podcastFullSupportInformation.canBeSupported && <ButtonShadow variant='secondary' onPress={onForum}
              label={isViewingForum ? 'ver conteúdos' : 'ver mural'} />}
            {!isLoadingJoinButton &&

              <ButtonShadow label={podcastInfo.userInformation.isFollower
                ? 'opções'
                : 'fazer parte'} variant='primary' onPress={onPressButton} />}
          </Row>

          <CollapsedArea expandedDescription={expandedDescription}>
            <SeeMoreInteractiveArea
              onClick={() => setExpandedDescription(!expandedDescription)}
            >
              <Collapse in={expandedDescription} collapsedSize={'4rem'}>
                <Description
                  expandedDescription={expandedDescription}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      podcastInfo.supportSummary.description,
                    ),
                  }}
                />
              </Collapse>
            </SeeMoreInteractiveArea>
          </CollapsedArea>
        </>
      }



      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!joinButtonError}
        autoHideDuration={10000}
        onClose={() => setJoinButtonError(undefined)}
        message={`Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte`}
      />

      <Dialog
        isDialogOpen={loginDialogOpen}
        handleCloseDialog={() => setLoginDialogOpen(false)}
        dialogText={`você precisa estar logado`}
        dialogActionButtonText={`login`}
        onClickDialogActionButton={() => goToRoute(loginRoute)}
        noActionText="ok"
      />

      <Dialog
        isDialogOpen={wrongProfileDialogOpen}
        handleCloseDialog={() => setWrongProfileDialogOpen(false)}
        dialogText={`Mude para o seu perfil de usuário para realizar essa ação`}
        noActionText="ok"
      />

      <Modal
        open={userMembershipModalOpen}
        onClose={() => setUserMembershipModalOpen(false)}
      >
        <UserMembershipModal
          podcastFullSupportInformation={podcastInfo}
          supportData={supportData}
          closeModal={() => setUserMembershipModalOpen(false)}
          showErrorSnack={() => setJoinButtonError(true)}
        />
      </Modal>
    </Wrapper>
  )
}

export default PodcastSummary
