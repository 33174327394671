import styled from 'styled-components'
import Button from "../Button/Button";
import { ReactComponent as OreloCloudIconSvg } from "../../assets/icons/orelo-cloud.svg";
import { ReactComponent as PigIconSvg } from "../../assets/icons/pig.svg";
import { ReactComponent as QuoteIconSvg } from "../../assets/icons/quote.svg";
import MicIcon from "@material-ui/icons/Mic";
import PaidIcon from '@mui/icons-material/Paid';
import PieChartIcon from '@mui/icons-material/PieChart';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


export const NavigationBarWrapper = styled.nav`
  position: sticky;
  top: 0;
  z-index: 1000 !important;
  margin-top: 80px;
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  gap: 14px;

  @media (max-width: 1200px) {
    align-items: center;
    width: 90%;
    flex-direction: column;
    margin: auto;
  }
`

export const MenuButton = styled(Button)`
  &.MuiButtonBase-root {
    .MuiButton-label {
      text-transform: uppercase;
      gap: 8px;
      white-space: nowrap;
    }

    
    height: 38px;
  }

  @media (max-width: 1366px) {
    &.MuiButtonBase-root {
      padding: 4px 22px !important;
    }
  }

  @media (max-width: 600px) {
    &.MuiButtonBase-root {
      width: 100%;
    }
  }
`

export const OreloCloudIcon = styled(OreloCloudIconSvg)`
  width: 1.2rem;
  height: 1.2rem;
`

export const QuoteIcon = styled(QuoteIconSvg).attrs(
  (props: { isActive?: boolean }) => props
)`
  width: 1.2rem;
  height: 1.2rem;
  fill: ${props => props.isActive ? 'white !important' : 'initial'};
  
  path {
    fill: ${props => props.isActive ? 'white !important' : 'initial'};
  }
`

export const PigIcon = styled(PigIconSvg)`
  width: 1.2rem;
  height: 1.2rem;
`

export const MicrophoneIcon = styled(MicIcon)`
  width: 1.2rem;
  height: 1.2rem;
`

export const MicrophoneCircledIcon = styled(MicIcon)`
  background: black;
  color: #fff;
  border-radius: 100%;
  width: 1.2rem;
  height: 1.2rem;
`

export const DollarCircledIcon = styled(PaidIcon)`
  width: 1.2rem;
  height: 1.2rem;
`

export const GraphsIcon = styled(PieChartIcon)`
  width: 1.2rem;
  height: 1.2rem;
`

export const SettingsCircledIcon = styled(MoreHorizIcon)`
  background: black;
  color: #fff;
  border-radius: 100%;
  width: 1.2rem;
  height: 1.2rem;
  padding: 1px;
`