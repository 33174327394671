import React, {useState} from 'react'
import {CardContent, MenuItem, Snackbar, Tooltip} from '@material-ui/core'
import {updateUser, uploadProfileImage,} from '../../services/api'
import * as Styled from './EditProfile.styled'
import BasePage from 'components/BasePage/BasePage'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {useTypedSelector} from "../../reducers";
import TextFieldField from "../../components/Fields/TextFieldField/TextFieldField";
import UploadImageButton from "../editPodcastInfo/UploadImageButton";
import {refreshAvailableProfiles} from "../../reducers/profiles";
import {useDispatch} from "react-redux";
import NumberFormatField from "../../components/Fields/NumberFormatField/NumberFormatField";
import SelectField from 'components/Fields/SelectField/SelectField'

interface EditProfileProps {
  signOut: () => Promise<void>
}

interface FormValues {
  name: ''
  email: '',
  secondaryEmail: ''
  phone: ''
  streetAddress: ''
  number: ''
  complement: ''
  city: ''
  // country: ''
  state: ''
  zipCode: ''
}

const editProfileValidationSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  email: Yup.string(),
  secondaryEmail: Yup.string(),
  phone: Yup.string().max(255).nullable(),
  streetAddress: Yup.string().max(255).nullable(),
  number: Yup.string().max(255).nullable(),
  complement: Yup.string().max(255).nullable(),
  city: Yup.string().max(255).nullable(),
  country: Yup.string().max(255).nullable(),
  state: Yup.string().max(255).nullable(),
  zipCode: Yup.string().max(255).nullable(),
})

const UPLOAD_ERROR_MESSAGE = 'Parece que ocorreu um erro no upload da imagem. Tente novamente ou entre em contato com o suporte.'

const EditProfile: React.FC<EditProfileProps> = ({signOut}) => {
  const profile = useTypedSelector(
    state => state.profiles && state.profiles.currentProfile,
  )

  const [snackbarMessage, setSnackbarMessage] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [canSubmitForm, setCanSubmitForm] = useState(true)
  const [profileImage, setProfileImage] = useState(profile?.avatar)
  const reduxDispatch = useDispatch()

  const getNewImage = async (file: File): Promise<void> => {
    try {
      await uploadProfileImage(file)
        .then(async res => {
          if (res.error) {
            setSnackbarMessage(UPLOAD_ERROR_MESSAGE)
          } else {
            setProfileImage(await file.arrayBuffer())
          }
        })
        .catch(() => {
          setSnackbarMessage(UPLOAD_ERROR_MESSAGE)
        })
    } catch (e) {
      setSnackbarMessage(
        'Só é possível fazer upload de imagem de perfil para perfis do tipo ouvinte.',
      )
    }

    setCanSubmitForm(true)
  }

  const submitForm = async (formValues: FormValues): Promise<void> => {
    const body = {
      name: formValues.name,
      secondaryEmail: formValues.secondaryEmail,
      phone: formValues.phone,
      address: {
        streetAddress: formValues.streetAddress,
        number: formValues.number,
        complement: formValues.complement,
        city: formValues.city,
        country: 'BR', // formValues.country,
        state: formValues.state,
        zipCode: formValues.zipCode,
      }
    }

    setIsLoading(true)

    const res = await updateUser(body)
    if (res.error) {
      setSnackbarMessage(
        'Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte',
      )
    } else {
      setSnackbarMessage('Informações atualizadas com sucesso!' +
        (formValues.secondaryEmail && formValues.secondaryEmail !== profile.secondaryEmail
          ? ' Você irá receber um link de ativação para confirmar seu e-mail secundário.'
          : ''))

      await reduxDispatch(refreshAvailableProfiles())
    }

    setIsLoading(false)
  }

  return (
    <BasePage signOut={signOut} isDark>
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        open={!!snackbarMessage}
        autoHideDuration={15000}
        onClose={() => setSnackbarMessage(undefined)}
        message={snackbarMessage}
      />

      <Styled.PageWrapper>
        <Styled.PageTitle>Editar Perfil</Styled.PageTitle>

        <Styled.CardItem variant="outlined">
          <CardContent>
            <Styled.CardTitleAndValue>
              <Formik
                validationSchema={editProfileValidationSchema}
                initialValues={{
                  name: profile?.name || '',
                  email: profile?.email || '',
                  secondaryEmail: profile?.secondaryEmail,
                  phone: profile?.phone || '',
                  streetAddress: profile?.streetAddress || '',
                  number: profile?.number || '',
                  complement: profile?.complement || '',
                  city: profile?.city || '',
                  // country: profile?.country || '',
                  state: profile?.state || '',
                  zipCode: profile?.zipCode || '',
                }}
                onSubmit={async (values) => {
                  await submitForm(values as FormValues)
                }}
                enableReinitialize
              >
                {({handleSubmit, values, touched, errors, setFieldValue}) => {
                  return (
                    <>
                      <Styled.ProfileAvatar src={profileImage}/>
                      <UploadImageButton
                        imageCallback={getNewImage}
                        style={{'margin': '8px auto'}}
                      />

                      <Styled.InputWrapper>
                        <TextFieldField
                          label="Nome"
                          placeholder="Nome"
                          name="name"
                          variant="outlined"
                          error={!!(touched.name && errors?.name)}
                          helperText={touched.name && errors?.name}
                          transparentBorder
                          fullWidth
                          style={{fontSize: 24, fontWeight: 500}}
                          value={values.name}
                        />
                      </Styled.InputWrapper>

                      <Styled.InputWrapper>
                        <TextFieldField
                          label="Email"
                          placeholder="Email"
                          name="email"
                          variant="outlined"
                          error={!!(touched.email && errors?.email)}
                          helperText={touched.email && errors?.email}
                          transparentBorder
                          fullWidth
                          style={{fontSize: 24, fontWeight: 500}}
                          value={values.email}
                          disabled
                        />
                      </Styled.InputWrapper>

                      <Styled.InputWrapper>
                        <TextFieldField
                          label="Email Secundário"
                          placeholder="Email Secundário"
                          name="secondaryEmail"
                          variant="outlined"
                          error={!!(touched.secondaryEmail && errors?.secondaryEmail)}
                          helperText={touched.secondaryEmail && errors?.secondaryEmail}
                          transparentBorder
                          fullWidth
                          style={{fontSize: 24, fontWeight: 500}}
                          value={values.secondaryEmail}
                        >
                          { profile.secondaryEmailVerified && <Styled.ActiveIcon color={'success'} />}
                          { !profile.secondaryEmailVerified && <Tooltip disableFocusListener title="E-mail secundário pendente de confirmação."><Styled.InactiveIcon color={'warning'} /></Tooltip>}
                        </TextFieldField>
                      </Styled.InputWrapper>

                      <NumberFormatField
                        name="phone"
                        variant="outlined"
                        placeholder="Telefone"
                        label="Telefone"
                        fullWidth
                        format={'(##) #####-####'}
                        mask={'_'}
                        value={values.phone}
                        error={!!(touched.phone && errors?.phone)}
                        helperText={touched.phone && (errors?.phone as string)}
                        hasEmptyValue
                        style={{fontSize: 24, fontWeight: 500}}
                      />

                      <NumberFormatField
                        name="zipCode"
                        variant="outlined"
                        placeholder="CEP"
                        label="CEP"
                        fullWidth
                        format={'#####-###'}
                        mask={'_'}
                        value={values.zipCode}
                        error={!!(touched.zipCode && errors?.zipCode)}
                        helperText={touched.zipCode && (errors?.zipCode as string)}
                        hasEmptyValue
                        style={{fontSize: 24, fontWeight: 500}}
                      />

                      <Styled.InputWrapper>
                        <TextFieldField
                          placeholder="Logradouro"
                          label="Logradouro"
                          name="streetAddress"
                          variant="outlined"
                          error={!!(touched.streetAddress && errors?.streetAddress)}
                          helperText={touched.streetAddress && errors?.streetAddress}
                          transparentBorder
                          fullWidth
                          style={{fontSize: 24, fontWeight: 500}}
                          value={values.streetAddress}
                        />
                      </Styled.InputWrapper>

                      <Styled.InputWrapper>
                        <TextFieldField
                          placeholder="Número"
                          label="Número"
                          name="number"
                          variant="outlined"
                          error={!!(touched.number && errors?.number)}
                          helperText={touched.number && errors?.number}
                          transparentBorder
                          fullWidth
                          style={{fontSize: 24, fontWeight: 500}}
                          value={values.number}
                        />
                      </Styled.InputWrapper>

                      <Styled.InputWrapper>
                        <TextFieldField
                          placeholder="Complemento"
                          label="Complemento"
                          name="complement"
                          variant="outlined"
                          error={!!(touched.complement && errors?.complement)}
                          helperText={touched.complement && errors?.complement}
                          transparentBorder
                          fullWidth
                          style={{fontSize: 24, fontWeight: 500}}
                          value={values.complement}
                        />
                      </Styled.InputWrapper>

                      <Styled.InputWrapper>
                        <TextFieldField
                          placeholder="Cidade"
                          label="Cidade"
                          name="city"
                          variant="outlined"
                          error={!!(touched.city && errors?.city)}
                          helperText={touched.city && errors?.city}
                          transparentBorder
                          fullWidth
                          style={{fontSize: 24, fontWeight: 500}}
                          value={values.city}
                        />
                      </Styled.InputWrapper>

                      <SelectField
                        placeholder="Estado"
                        label="Estado"
                        name="state"
                        variant="outlined"
                        displayEmpty
                        error={!!(touched.state && errors?.state)}
                        helperText={touched.state && errors?.state as string}
                        fullWidth
                        style={{fontSize: 24, fontWeight: 500}}
                        value={values.state}
                      >
                        <MenuItem value="AC">Acre</MenuItem>
                        <MenuItem value="AL">Alagoas</MenuItem>
                        <MenuItem value="AP">Amapá</MenuItem>
                        <MenuItem value="AM">Amazonas</MenuItem>
                        <MenuItem value="BA">Bahia</MenuItem>
                        <MenuItem value="CE">Ceará</MenuItem>
                        <MenuItem value="DF">Distrito Federal</MenuItem>
                        <MenuItem value="ES">Espírito Santo</MenuItem>
                        <MenuItem value="GO">Goiás</MenuItem>
                        <MenuItem value="MA">Maranhão</MenuItem>
                        <MenuItem value="MT">Mato Grosso</MenuItem>
                        <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                        <MenuItem value="MG">Minas Gerais</MenuItem>
                        <MenuItem value="PA">Pará</MenuItem>
                        <MenuItem value="PB">Paraíba</MenuItem>
                        <MenuItem value="PR">Paraná</MenuItem>
                        <MenuItem value="PE">Pernambuco</MenuItem>
                        <MenuItem value="PI">Piauí</MenuItem>
                        <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                        <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                        <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                        <MenuItem value="RO">Rondônia</MenuItem>
                        <MenuItem value="RR">Roraima</MenuItem>
                        <MenuItem value="SC">Santa Catarina</MenuItem>
                        <MenuItem value="SP">São Paulo</MenuItem>
                        <MenuItem value="SE">Sergipe</MenuItem>
                        <MenuItem value="TO">Tocantins</MenuItem>
                      </SelectField>

                      <Styled.LargeButton
                        variant="contained"
                        buttonColorOnHover="#303030"
                        buttonColor="#414141"
                        onClick={() => {
                          if (canSubmitForm) {
                            setCanSubmitForm(false)
                            handleSubmit();
                          }
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Styled.LoadingSpinner/>
                        ) : (
                          'Atualizar informações'
                        )}
                      </Styled.LargeButton>
                    </>
                  )
                }}
              </Formik>
            </Styled.CardTitleAndValue>
          </CardContent>
        </Styled.CardItem>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default EditProfile
