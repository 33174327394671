import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './EpisodeListingPage.styled'
import useFetch from '../../hooks/useFetch'
import { Episode, FullSupportInformation } from '../../types'
import {
  archiveArticle,
  archiveEpisode,
  getPodcastEpisodes,
  getShareLink,
  getSupportInformationByPodcastId,
} from '../../services/api'
import Button from '../../components/Button/Button'
import {
  MessageWrapper,
  PageWrapper,
} from '../podcastSupport/PodcastSupport.styled'
import { CircularProgress, Snackbar } from '@material-ui/core'
import { useGoToRoute } from '../../Routes/RouteAux'
import {
  getEpisodeCreatePath,
  getEpisodeEditPath,
  getPlaylistListingPath,
} from '../../Routes/RouteNames'
import { DateClass } from '../../utils/domain/DateClass'
import handleCopyToClipboard from '../../services/copyToClipboard'
import SearchBar from '../../components/SearchBar/SearchBar'
import Dialog from '../../components/Dialog/Dialog'
import { Colors } from '../../styles'
import {MenuButtonWithoutIcon} from "./EpisodeListingPage.styled";
import parseSecondsToText from "../../utils/parseSecondsToText";

interface EditPodcastSupportPageProps {
  signOut: () => Promise<void>
}

const EpisodeListingPage: React.FC<EditPodcastSupportPageProps> = ({
  signOut,
}) => {
  const { podcastId } = useParams<{ podcastId: string }>()

  const [isLoadingEpisodes, setIsLoadingEpisodes] = useState(false)
  const [errorEpisodes, setErrorEpisodes] = useState(false)
  const [episodesPage, setEpisodesPage] = useState(0)
  const [hasFetchedAllEpisodes, setHasFetchedAllEpisodes] = useState(false)
  const [isLoadingMoreEpisodes, setIsLoadingMoreEpisodes] = useState(false)
  const [isShowingAllEpisodes, setIsShowingAllEpisodes] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [episodes, setEpisodes] = useState<Episode[]>([])
  const [archivingEpisode, setArchivingEpisode] = useState<Episode>()
  const [errorOnDelete, setErrorOnDelete] = useState<string>()

  const goToRoute = useGoToRoute()

  const { isLoading, data, error, fetchData } =
    useFetch<FullSupportInformation>(
      useCallback(() => {
        return getSupportInformationByPodcastId(podcastId)
      }, [podcastId]),
    )

  const initialFetchEpisodes = async () => {
    setIsLoadingEpisodes(true)
    const res = await getPodcastEpisodes(
      podcastId,
      undefined,
      true,
      'desc',
      true,
    )

    if (res.hasFailed()) {
      setErrorEpisodes(true)
    } else {
      if (res.data?.length < 100) {
        setHasFetchedAllEpisodes(true)
      } else {
        setHasFetchedAllEpisodes(false)
      }

      setEpisodes(res.data)
    }

    setIsLoadingEpisodes(false)
  }

  const fetchEpisodesWithPage = async () => {
    const res = await getPodcastEpisodes(
      podcastId,
      episodesPage,
      true,
      'desc',
      true,
    )

    if (!res.hasFailed()) {
      const currentEpisodes = episodes.slice()

      if (res.data?.length < 100) {
        setHasFetchedAllEpisodes(true)
      } else {
        setHasFetchedAllEpisodes(false)
      }

      currentEpisodes.push(...res.data)

      setEpisodes(currentEpisodes)
    } else {
      alert(
        'Ops! Não conseguimos carregar mais episódios. Por favor, tente novamente.',
      )
    }

    setIsLoadingMoreEpisodes(false)
  }

  const fetchEpisodesBySearchTerm = async () => {
    const res = await getPodcastEpisodes(
      podcastId,
      undefined,
      true,
      'desc',
      true,
      searchTerm,
    )

    if (!res.hasFailed()) {
      if (res.data?.length < 100) {
        setHasFetchedAllEpisodes(true)
      } else {
        setHasFetchedAllEpisodes(false)
      }

      setEpisodes(res.data)
    } else {
      alert(
        'Ops! Não conseguimos carregar mais episódios. Por favor, tente novamente.',
      )
    }

    setIsLoadingMoreEpisodes(false)
  }

  useEffect(() => {
    initialFetchEpisodes()
  }, [])

  useEffect(() => {
    if (episodesPage !== null && episodesPage !== 0) {
      fetchEpisodesWithPage()
    }
  }, [episodesPage])

  useEffect(() => {
    if (searchTerm) {
      fetchEpisodesBySearchTerm()
    } else {
      initialFetchEpisodes()
    }
  }, [searchTerm])

  if (isLoading || isLoadingEpisodes) {
    return (
      <BasePage signOut={signOut} isDark showNavigationBar>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (error || errorEpisodes) {
    return (
      <BasePage signOut={signOut} isDark showNavigationBar>
        <PageWrapper>
          <MessageWrapper>Ops, parece que tivemos um erro aqui.</MessageWrapper>
          <Button variant="contained" fontColor="black" onClick={fetchData}>
            Tentar novamente
          </Button>
        </PageWrapper>
      </BasePage>
    )
  }

  const onAddNewEpisode = () => {
    goToRoute(getEpisodeCreatePath(podcastId))
  }

  const onEditEpisode = (episode: Episode) => {
    goToRoute(getEpisodeEditPath(podcastId, episode.episodeId))
  }

  const onCopyEpisodeUrl = async (episode: Episode) => {
    const res = await getShareLink({
      shareType: 'episode',
      shareId: episode.episodeId,
      linkTitle: 'Compartilhar Episódio',
      linkImage: episode.image ?? '',
      linkDescription: 'Compartilhar Episódio',
      redirectToMobileApp: false,
    })

    if (!res.hasFailed()) {
      await handleCopyToClipboard(res.data)
      alert(`Link copiado! (${res.data})`)
    }
  }

  const renderSeeMoreButtonOrLoading = () => {
    if (!isShowingAllEpisodes) {
      return (
        <Button
          buttonColor="white"
          buttonColorOnHover="#919191"
          fontColor="black"
          borderColor="black"
          style={{ marginLeft: 'auto' }}
          size="small"
          onClick={() => {
            setIsShowingAllEpisodes(true)
          }}
        >
          Ver mais...
        </Button>
      )
    }

    if (hasFetchedAllEpisodes) return

    if (isLoadingMoreEpisodes) {
      return <CircularProgress />
    } else {
      return (
        <Button
          buttonColor="white"
          buttonColorOnHover="#919191"
          fontColor="black"
          borderColor="black"
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            setIsLoadingMoreEpisodes(true)

            setEpisodesPage(episodesPage + 1)
          }}
        >
          Ver mais...
        </Button>
      )
    }
  }

  const isEpisodeReleased = (episode: Episode) =>
    DateClass.fromLongDTO(episode.longReleaseDate)
      .getValue()
      .isBefore(DateClass.now())

  const onArchiveEpisode = async (episodeId: string) => {
    const res = await archiveEpisode(podcastId, episodeId)
    if (res.error) {
      setErrorOnDelete(res.error.message)
      return false
    }

    return true
  }

  return (
    <BasePage signOut={signOut} showNavigationBar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errorOnDelete}
        autoHideDuration={10000}
        onClose={() => setErrorOnDelete(undefined)}
        message={`Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte`}
      />

      <Dialog
        isDialogOpen={!!archivingEpisode}
        handleCloseDialog={() => setArchivingEpisode(null)}
        dialogText={`tem certeza que deseja arquivar o episódio: ${archivingEpisode?.title}?`}
        secondaryDialogText="essa ação não pode ser desfeita"
        dialogActionButtonText="arquivar"
        onClickDialogActionButton={async () => {
          if (await onArchiveEpisode(archivingEpisode.episodeId)) {
            initialFetchEpisodes()
          }
          setArchivingEpisode(null)
        }}
        noActionText="cancelar"
      />

      <Styled.PageWrapper>
        <Styled.TitleAndLinkWrapper>
          <Styled.PageTitle>Meus Episódios</Styled.PageTitle>

          <Styled.ListHeader>
            <span>PODCASTS</span>
          </Styled.ListHeader>

          <Styled.ItemGrid>
            {(!episodes || episodes?.length <= 0) && !isShowingAllEpisodes ? (
              <Styled.EmptyStateMesage>
                Você ainda não possui nenhum episódio registrado.
                <br />
                <u onClick={() => onAddNewEpisode()}>
                  Clique aqui para criar um.
                </u>
              </Styled.EmptyStateMesage>
            ) : null}

            {isShowingAllEpisodes ? (
              <Styled.SearchBarWrapper>
                <SearchBar
                  initialSearchTerm={searchTerm}
                  placeholder="Buscar por episódios"
                  onChange={(term) => setSearchTerm(term)}
                  fullWidth={true}
                />
              </Styled.SearchBarWrapper>
            ) : null}

            {(!episodes || episodes?.length <= 0) && isShowingAllEpisodes ? (
              <Styled.EmptyStateMesage>
                Nenhum episódio encontrado com os termos informados.
              </Styled.EmptyStateMesage>
            ) : null}

            {episodes?.length > 0 &&
              episodes.map((episode) => (
                <Styled.Item>
                  <Styled.ItemWrapper isDark>
                    <Styled.ItemInformation>
                      <Styled.ItemIcons>
                        {episode.exclusiveToSupporters && <Styled.PigIcon />}
                        <Styled.MicrophoneCircledIcon />
                      </Styled.ItemIcons>

                      <Styled.ItemDescription
                        disabled={!isEpisodeReleased(episode)}
                      >
                        <Styled.ItemTitle title={episode.title}>
                          {episode.title}
                        </Styled.ItemTitle>
                        <Styled.ItemReleaseDate>
                          {DateClass.fromLongDTO(episode.longReleaseDate)
                            .getValue()
                            .toDTO()}

                          <span>{parseSecondsToText(episode.duration)}</span>
                        </Styled.ItemReleaseDate>
                        <Styled.ItemPlayCount>
                          {episode.playsCount ?? 0} plays
                        </Styled.ItemPlayCount>
                      </Styled.ItemDescription>
                    </Styled.ItemInformation>

                    <Styled.ItemActions>
                      <Button
                        buttonColor="white"
                        buttonColorOnHover="#919191"
                        fontColor="black"
                        borderColor="black"
                        size="small"
                        fontSize="small"
                        onClick={() => onEditEpisode(episode)}
                        disabled={!!episode.guid}
                      >
                        Editar
                      </Button>

                      <Button
                        buttonColor="white"
                        buttonColorOnHover="#919191"
                        fontColor="black"
                        borderColor="black"
                        size="small"
                        fontSize="small"
                        onClick={() => onCopyEpisodeUrl(episode)}
                        disabled={!isEpisodeReleased(episode)}
                      >
                        Copiar url
                      </Button>

                      <Button
                        buttonColor="white"
                        fontColor={Colors.SUPPORT_ERROR}
                        borderColor={Colors.SUPPORT_ERROR}
                        size="small"
                        fontSize="small"
                        onClick={() => setArchivingEpisode(episode)}
                        disabled={episode.isArchived}
                      >
                        Arquivar
                      </Button>
                    </Styled.ItemActions>
                  </Styled.ItemWrapper>
                </Styled.Item>
              ))}
          </Styled.ItemGrid>

          {episodes.length > 0 && renderSeeMoreButtonOrLoading()}
        </Styled.TitleAndLinkWrapper>

        <Styled.MenuWrapper>
          <Styled.MenuHeader>EPISÓDIOS</Styled.MenuHeader>

          <Styled.MenuButton
            buttonColor="white"
            buttonColorOnHover="#919191"
            fontColor="black"
            borderColor="black"
            onClick={() => onAddNewEpisode()}
          >
            <Styled.MicrophoneIcon /> CRIAR NOVO EPISÓDIO
          </Styled.MenuButton>

          <Styled.MenuButton
            buttonColor="white"
            buttonColorOnHover="#919191"
            fontColor="black"
            borderColor="black"
            onClick={() => goToRoute(getPlaylistListingPath(podcastId))}
          >
            <Styled.ForwardIcon /> VER LISTAS DE EPISÓDIOS
          </Styled.MenuButton>

          <Styled.MenuButtonWithoutIcon
            buttonColor="white"
            buttonColorOnHover="#919191"
            fontColor="black"
            borderColor="black"
            onClick={() =>
              window.open(
                'https://orelohelp.zendesk.com/hc/pt-br/articles/360051747574-Registro-de-Autoria',
                '_blank',
              )
            }
          >
            VINCULAR PODCAST
          </Styled.MenuButtonWithoutIcon>
        </Styled.MenuWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default EpisodeListingPage
