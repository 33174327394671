import React from 'react'
import * as Styled from './ComparativeTable.styled'

const ComparativeTable: React.FC<{}> = () => {
  const isMobile = (): boolean => {
    const resolution = window.innerWidth

    return resolution < 768
  }

  return (
    <Styled.SectionWrapper>
      <Styled.TitleStroke>Compare</Styled.TitleStroke>
      <Styled.ImageWrapper>
        {isMobile() ? <Styled.ImageMobile /> : <Styled.Image />}
      </Styled.ImageWrapper>
      <Styled.Description>
        * Informações obtidas nos sites das respectivas plataformas em 12 de
        novembro de 2022.
      </Styled.Description>
    </Styled.SectionWrapper>
  )
}

export default ComparativeTable
