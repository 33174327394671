import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './PlaylistListingPage.styled'
import useFetch from '../../hooks/useFetch'
import {Episode, FullSupportInformation, Playlist, PlaylistItemDetailDTO,} from '../../types'
import {
  addItemToPlaylist,
  createPlaylist,
  deletePlaylist,
  getPodcastEpisodes,
  getPodcastPlaylists,
  getSupportInformationByPodcastId,
  listPlaylistItems,
  removeItemFromPlaylist,
  reorderItemInPlaylist,
  updatePlaylist,
} from '../../services/api'
import Button from '../../components/Button/Button'
import {MessageWrapper, PageWrapper,} from '../podcastSupport/PodcastSupport.styled'
import TextFieldField from '../../components/Fields/TextFieldField/TextFieldField'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {Colors} from '../../styles'
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu'
import Dialog from '../../components/Dialog/Dialog'
import SearchBar from '../../components/SearchBar/SearchBar'
import {PlaylistEpisode} from './PlaylistEpisode'
import {DateClass} from '../../utils/domain/DateClass'
import update from 'immutability-helper'
import {LoadingSpinner} from '../editForumInfo/EditForumInfo.styled'
import DialogActions from '@material-ui/core/DialogActions'
import {getEpisodeListingPath} from '../../Routes/RouteNames'
import ReturnButton from '../../components/ReturnButton/ReturnButton'
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

interface EditPodcastSupportPageProps {
  signOut: () => Promise<void>
}

const createEpisodeValidationSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório').min(1, 'Campo obrigatório'),
})

const PlaylistListingPage: React.FC<EditPodcastSupportPageProps> = ({
                                                                      signOut,
                                                                    }) => {
  const {podcastId} = useParams<{ podcastId: string }>()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingEpisodes, setIsLoadingEpisodes] = useState(false)
  const [hasFetchedAllEpisodes, setHasFetchedAllEpisodes] = useState(false)
  const [isLoadingMoreEpisodes, setIsLoadingMoreEpisodes] = useState(false)
  const [error, setError] = useState(false)
  const [podcastPlaylists, setPodcastPlaylist] = useState<Playlist[]>([])
  const [podcastEpisodes, setPodcastEpisodes] = useState<Episode[]>([])
  const [deletingPlaylist, setDeletingPlaylist] = useState<Playlist>()
  const [editingPlaylist, setEditingPlaylist] = useState<Playlist>()
  const [activePlaylist, setActivePlaylist] = useState<Playlist>()
  const [formInitialValues, setFormInitialValues] = useState<{
    name: string
    playlistId: string
  }>({
    name: '',
    playlistId: '',
  })
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showingPodcastEpisodes, setShowingPodcastEpisodes] = useState<Episode[]>([])

  const {fetchData} = useFetch<FullSupportInformation>(
    useCallback(() => {
      return getSupportInformationByPodcastId(podcastId)
    }, [podcastId]),
  )

  const fetchPlaylists = async () => {
    setIsLoading(true)
    const res = await getPodcastPlaylists(podcastId)

    if (res.hasFailed()) {
      setError(true)
    } else {
      setPodcastPlaylist(res.data)
    }

    setIsLoading(false)
  }

  const fetchEpisodes = async () => {
    setIsLoadingEpisodes(true)
    const res = await getPodcastEpisodes(podcastId, undefined, true)

    if (res.hasFailed()) {
      setError(true)
    } else {
      setPodcastEpisodes(res.data)
    }

    setIsLoadingEpisodes(false)
  }

  const updatePlaylists = (playlist: Playlist) => {
    setPodcastPlaylist((prevState) => [
      ...prevState.map((podcastPlaylist) => {
        if (podcastPlaylist.playlistId === playlist.playlistId) {
          return Object.assign({}, playlist)
        }

        return podcastPlaylist
      }),
    ])
  }

  const getPlaylistEpisodes = async (playlist: Playlist) => {
    if (playlist.isLoading || playlist.episodes) {
      return
    }

    playlist.isLoading = true
    updatePlaylists(playlist)

    const res = await listPlaylistItems(playlist.playlistId)

    if (res.data && !res.error) {
      playlist.episodes = res.data
    }

    playlist.isLoading = false
    updatePlaylists(playlist)
  }

  useEffect(() => {
    fetchPlaylists()
  }, [])

  useEffect(() => {
    if (activePlaylist) {
      fetchEpisodes()
    } else {
      fetchPlaylists()
    }
  }, [activePlaylist])

  useEffect(() => {
    if (activePlaylist) {
      setShowingPodcastEpisodes(
        podcastEpisodes?.filter(
          (episode) =>
            !activePlaylist.episodes.find(
              (playlistEpisode) =>
                playlistEpisode.episode.episodeId === episode.episodeId,
            ),
        ) ?? [],
      )
    }
  }, [podcastEpisodes])

  const fetchEpisodesBySearchTerm = async () => {
    const res = await getPodcastEpisodes(
      podcastId,
      undefined,
      true,
      'desc',
      true,
      searchTerm,
    )

    if (!res.hasFailed()) {
      if (res.data?.length < 100) {
        setHasFetchedAllEpisodes(true)
      } else {
        setHasFetchedAllEpisodes(false)
      }

      setShowingPodcastEpisodes(res.data)
    } else {
      alert(
        'Ops! Não conseguimos carregar mais episódios. Por favor, tente novamente.',
      )
    }
    setIsLoadingMoreEpisodes(false)
  }

  useEffect(() => {
    if (searchTerm) {
      fetchEpisodesBySearchTerm()
    } else {
      fetchEpisodes()
    }
  }, [searchTerm])

  useEffect(() => {
    if (podcastPlaylists) {
      podcastPlaylists.map((playlist) => {
        getPlaylistEpisodes(playlist)
      })
    }
  }, [podcastPlaylists])

  const doSavePlaylist = async (formValues: {
    name: string
    playlistId: string
  }) => {
    setIsDialogOpen(false)
    setIsLoading(true)
    if (formValues.playlistId) {
      await updatePlaylist(podcastId, formValues.playlistId, formValues)
    } else {
      await createPlaylist(podcastId, formValues)
    }
    await fetchPlaylists()
    setIsLoading(false)
  }

  const onAddNewPlaylist = async () => {
    setFormInitialValues({name: '', playlistId: ''})
    setEditingPlaylist(undefined)
    setIsDialogOpen(true)
  }

  const onAddNewEpisode = (playlist: Playlist) => {
    setActivePlaylist(playlist)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const onEditPlaylist = (playlist: Playlist) => {
    setEditingPlaylist(playlist)
    setFormInitialValues({
      name: playlist.title,
      playlistId: playlist.playlistId,
    })
  }

  const onRemovePlaylist = async (playlist: Playlist) => {
    setIsDialogOpen(false)
    setDeletingPlaylist(playlist)
  }

  const doRemovePlaylist = async () => {
    setIsLoading(true)
    await deletePlaylist(podcastId, deletingPlaylist.playlistId)
    setDeletingPlaylist(undefined)
    setEditingPlaylist(undefined)
    await fetchPlaylists()
    setIsLoading(false)
  }

  const dropdownOptions = (playlist: Playlist) => [
    {
      title: 'Editar nome',
      onClick: () => onEditPlaylist(playlist),
    },
    {
      title: 'Excluir lista',
      onClick: () => onRemovePlaylist(playlist),
    },
  ]

  const onAddToPlaylist = (episode: Episode) => {
    addItemToPlaylist(podcastId, activePlaylist.playlistId, {
      episodeId: episode.episodeId,
    })

    setPodcastEpisodes((prevState) => [
      ...prevState.filter(
        (podcastEpisode) => podcastEpisode.episodeId !== episode.episodeId,
      ),
    ])
  }

  const onRemoveItemFromPlaylist = async (
    episode: PlaylistItemDetailDTO,
    playlist: Playlist,
  ) => {
    await removeItemFromPlaylist(
      podcastId,
      playlist.playlistId,
      episode.playlistItemId,
    )

    setPodcastPlaylist((prevState) => [
      ...prevState.map((podcastPlaylist) => {
        if (podcastPlaylist.playlistId === playlist.playlistId) {
          podcastPlaylist.episodes = playlist.episodes.filter(
            (playlistEpisode) =>
              playlistEpisode.episode.episodeId !== episode.episode.episodeId,
          )
        }

        return podcastPlaylist
      }),
    ])
  }

  const moveEpisode = (
    episode: PlaylistItemDetailDTO,
    playlist: Playlist,
    dragIndex: number,
    hoverIndex: number,
  ) => {
    let position = dragIndex

    const hoverEpisode = playlist.episodes[hoverIndex]

    if (hoverIndex > dragIndex) {
      // Going down
      if (hoverIndex + 1 in playlist.episodes) {
        const afterEpisode = playlist.episodes[hoverIndex + 1]

        position = (hoverEpisode.position + afterEpisode.position) / 2
      } else {
        // Hover episode it's the last item
        position = hoverEpisode.position - 1
      }
    } else {
      // Going up
      if (hoverIndex - 1 in playlist.episodes) {
        const beforeEpisode = playlist.episodes[hoverIndex - 1]

        position = (hoverEpisode.position + beforeEpisode.position) / 2
      } else {
        // Hover episode it's the first item
        position = hoverEpisode.position + 1
      }
    }

    reorderItemInPlaylist(
      podcastId,
      playlist.playlistId,
      playlist.episodes[dragIndex].playlistItemId,
      {position},
    )

    setPodcastPlaylist((prevState) => [
      ...prevState.map((podcastPlaylist) => {
        if (podcastPlaylist.playlistId === playlist.playlistId) {
          podcastPlaylist.episodes = update(playlist.episodes, {
            $splice: [
              [dragIndex, 1],
              [
                hoverIndex,
                0,
                playlist.episodes[dragIndex] as PlaylistItemDetailDTO,
              ],
            ],
          })
        }

        return podcastPlaylist
      }),
    ])
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    width: '100%',
    userSelect: "none",
    background: isDragging ? "lightgreen" : "transparent",
    ...draggableStyle
  });

  const getListStyle = () => ({
    width: '100%',
  });

  const onDragEnd = (result) => {
    const {source, destination} = result;

    if (!destination || source.droppableId !== destination.droppableId) {
      return;
    }

    const playlist = podcastPlaylists.find(p => p.playlistId === source.droppableId)

    const episode = playlist.episodes[source.index]

    const dragIndex = source.index

    const hoverIndex = destination.index

    moveEpisode(episode, playlist, dragIndex, hoverIndex)
  }

  const RenderEpisode = useCallback(
    (episode: PlaylistItemDetailDTO, playlist: Playlist, index: number) => {
      return (
        <Draggable key={episode.episode.episodeId} draggableId={`${playlist.playlistId}/${episode.episode.episodeId}`}
                   index={index}>
          {(provided, snapshot) =>
            (<div ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
            >
              <PlaylistEpisode
                key={episode.episode.episodeId}
                episode={episode}
                onRemoveItemFromPlaylist={() =>
                  onRemoveItemFromPlaylist(episode, playlist)
                }
              /></div>)}
        </Draggable>
      )
    },
    [],
  )

  if (isLoading) {
    return (
      <BasePage signOut={signOut} isDark showNavigationBar>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (error) {
    return (
      <BasePage signOut={signOut} isDark showNavigationBar>
        <PageWrapper>
          <MessageWrapper>Ops, parece que tivemos um erro aqui.</MessageWrapper>
          <Button variant="contained" fontColor="black" onClick={fetchData}>
            Tentar novamente
          </Button>
        </PageWrapper>
      </BasePage>
    )
  }

  return (
    <BasePage signOut={signOut} showNavigationBar>
      <Dialog
        isDialogOpen={!!deletingPlaylist}
        handleCloseDialog={() => setDeletingPlaylist(undefined)}
        dialogText="tem certeza que deseja apagar esta lista?"
        secondaryDialogText="essa ação não pode ser desfeita  "
        dialogActionButtonText="apagar"
        onClickDialogActionButton={() => doRemovePlaylist()}
        noActionText="cancelar"
      />

      <Styled.MuiDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        fullWidth
      >
        <Styled.DialogTextWrapper>
          <Styled.DialogText disableTypography>
            Criar Nova Lista
          </Styled.DialogText>
        </Styled.DialogTextWrapper>
        <Styled.MuiDialogActions>
          <Formik
            initialValues={formInitialValues}
            onSubmit={(values) => doSavePlaylist(values)}
            validationSchema={createEpisodeValidationSchema}
          >
            {({handleSubmit, errors, touched, values, setFieldValue}) => {
              return (
                <Styled.FlexForm>
                  <TextFieldField
                    placeholder="Nome"
                    name="name"
                    variant="outlined"
                    error={!!(touched.name && errors?.name)}
                    helperText={touched.name && errors?.name}
                    transparentBorder
                    fullWidth
                    value={values.name}
                  />

                  <Button
                    onClick={() => handleSubmit()}
                    fontColor="white"
                    buttonColor="#cc0033"
                    buttonColorOnHover="#940a00"
                  >
                    Salvar
                  </Button>

                  <Button onClick={handleCloseDialog} fontColor="black">
                    Cancelar
                  </Button>
                </Styled.FlexForm>
              )
            }}
          </Formik>
        </Styled.MuiDialogActions>
      </Styled.MuiDialog>

      <Styled.MuiDialog
        open={!!activePlaylist}
        onClose={() => setActivePlaylist(undefined)}
        fullWidth={true}
        maxWidth={'md'}
      >
        <Styled.DialogTextWrapper>
          <Styled.DialogText disableTypography>
            Adicionar Episódios a Lista
          </Styled.DialogText>
        </Styled.DialogTextWrapper>
        <DialogActions>
          <Styled.PodcastModalWrapper>
            <SearchBar
              initialSearchTerm={searchTerm}
              placeholder="Buscar por episódios"
              onChange={(term) => setSearchTerm(term)}
              fullWidth={true}
            />

            {isLoadingEpisodes && (
              <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
            )}

            {showingPodcastEpisodes.length <= 0 && (
              <Styled.EmptyStateMesage>
                Nenhum episódio encontrado.
              </Styled.EmptyStateMesage>
            )}

            {showingPodcastEpisodes.length > 0 && (
              <Styled.ItemList>
                {showingPodcastEpisodes.map((episode) => (
                  <Styled.ItemListItem>
                    <Styled.ItemIcons>
                      {episode.exclusiveToSupporters && <Styled.PigIcon/>}
                      <Styled.MicrophoneCircledIcon/>
                    </Styled.ItemIcons>

                    <Styled.ItemDescription>
                      <Styled.ItemTitle title={episode.title}>
                        {episode.title}
                      </Styled.ItemTitle>
                      <Styled.ItemReleaseDate>
                        {DateClass.fromLongDTO(episode.longReleaseDate)
                          .getValue()
                          .toLongDTO(false)}
                      </Styled.ItemReleaseDate>
                    </Styled.ItemDescription>

                    <Styled.ItemActions>
                      <Button
                        buttonColor="white"
                        buttonColorOnHover="#919191"
                        fontColor="black"
                        borderColor="black"
                        onClick={() => onAddToPlaylist(episode)}
                        size="small"
                      >
                        <b>+</b>
                      </Button>
                    </Styled.ItemActions>
                  </Styled.ItemListItem>
                ))}
              </Styled.ItemList>
            )}

            <Button
              buttonColor="white"
              buttonColorOnHover="#919191"
              fontColor="black"
              borderColor="black"
              onClick={() => setActivePlaylist(undefined)}
              size="small"
            >
              OK
            </Button>
          </Styled.PodcastModalWrapper>
        </DialogActions>
      </Styled.MuiDialog>

      <Styled.PageWrapper>
        <Styled.TitleAndLinkWrapper>
          <ReturnButton route={getEpisodeListingPath(podcastId)}/>
          <Styled.PageTitle>Minhas Listas</Styled.PageTitle>

          <Styled.ButtonWrapper>
            <Button
              buttonColor="white"
              buttonColorOnHover="#919191"
              fontColor="black"
              borderColor="black"
              size="small"
              onClick={() => onAddNewPlaylist()}
            >
              <b>+</b> Adicionar Nova Lista
            </Button>
          </Styled.ButtonWrapper>

          <DragDropContext onDragEnd={onDragEnd}>
            <Styled.ItemGrid>
              {!podcastPlaylists ||
                (podcastPlaylists?.length <= 0 && (
                  <Styled.EmptyStateMesage>
                    Você ainda não possui nenhuma lista.
                    <br/>
                    <u onClick={() => onAddNewPlaylist()}>
                      Clique aqui para criar uma.
                    </u>
                  </Styled.EmptyStateMesage>
                ))}

              {podcastPlaylists?.length > 0 &&
                podcastPlaylists.map((playlist) => (
                  <Styled.Item>
                    <Styled.ItemWrapper isDark>
                      <Styled.ItemHead>
                        {editingPlaylist !== playlist && (
                          <Styled.ItemHeader>{playlist.title}</Styled.ItemHeader>
                        )}
                        {editingPlaylist === playlist && (
                          <Formik
                            initialValues={formInitialValues}
                            onSubmit={(values) => doSavePlaylist(values)}
                            validationSchema={createEpisodeValidationSchema}
                          >
                            {({
                                handleSubmit,
                                errors,
                                touched,
                                values,
                                setFieldValue,
                              }) => {
                              return (
                                <Styled.FlexForm>
                                  <TextFieldField
                                    placeholder="Nome"
                                    name="name"
                                    variant="outlined"
                                    error={!!(touched.name && errors?.name)}
                                    helperText={touched.name && errors?.name}
                                    transparentBorder
                                    fullWidth
                                    value={values.name}
                                  />

                                  <Button
                                    onClick={() => setEditingPlaylist(undefined)}
                                    fontColor="black"
                                  >
                                    Cancelar
                                  </Button>

                                  <Button
                                    onClick={() => handleSubmit()}
                                    fontColor="white"
                                    buttonColor="#cc0033"
                                    buttonColorOnHover="#940a00"
                                  >
                                    Salvar
                                  </Button>
                                </Styled.FlexForm>
                              )
                            }}
                          </Formik>
                        )}

                        {editingPlaylist !== playlist && (
                          <Styled.ItemOptions>
                            <DropdownMenu
                              items={dropdownOptions(playlist)}
                              color={Colors.BRAND_PRIMARY}
                            />
                          </Styled.ItemOptions>
                        )}
                      </Styled.ItemHead>

                      {playlist.isLoading && <LoadingSpinner/>}

                      {!playlist.isLoading && playlist.episodes?.length <= 0 && (
                        <Styled.EmptyStateMesage>
                          Esta lista não possui nenhum episódio.
                          <br/>
                          <u onClick={() => onAddNewEpisode(playlist)}>
                            Clique aqui para adicionar um.
                          </u>
                        </Styled.EmptyStateMesage>
                      )}

                      {!playlist.isLoading && playlist.episodes?.length > 0 && (
                        <>
                          <Button
                            buttonColor="white"
                            buttonColorOnHover="#919191"
                            fontColor="black"
                            borderColor="black"
                            size="small"
                            onClick={() => onAddNewEpisode(playlist)}
                          >
                            <b>+</b> Adicionar Episódio
                          </Button>

                          <Droppable droppableId={playlist.playlistId} type={`playlist/${playlist.playlistId}`}>
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                <Styled.ItemList>
                                  {playlist.episodes?.map((episode, index) =>
                                    RenderEpisode(episode, playlist, index),
                                  )}
                                </Styled.ItemList>
                              </div>
                            )}
                          </Droppable>
                        </>
                      )}
                    </Styled.ItemWrapper>
                  </Styled.Item>
                ))}
            </Styled.ItemGrid>
          </DragDropContext>
        </Styled.TitleAndLinkWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default PlaylistListingPage
