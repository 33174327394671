import {TEXT_LIGHT} from './../../styles/colors';
import styled from 'styled-components'
import {CircularProgress, Dialog, DialogActions} from '@material-ui/core'
import Button from '../../components/Button/Button'
import {Form} from 'formik'
import CloseIconSvg from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import {ReactComponent as PigIconSvg} from "../../assets/icons/pig.svg";
import {
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core'
import MicIcon from "@material-ui/icons/Mic";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 20px 64px;
  color: ${TEXT_LIGHT};

  @media (max-width: 600px) {
    padding: 16px;
  }
`

export const TitleAndLinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 42px;
  width: 100%;

  @media (max-width: 600px) {
    align-items: center;
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  margin-bottom: 42px;
  width: 30%;

  background: #000000;
  border-radius: 24px;
  padding: 24px 32px;
  margin-top: 4rem;
  gap: 12px;

  @media (max-width: 600px) {
    align-items: center;
  }
`

export const MenuHeader = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;

  text-align: right;
  text-transform: uppercase;

  color: #FFFFFF;
`

export const MenuButton = styled.div`

`

export const ItemGrid = styled.div`
  display: flex;
  gap: 36px;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 24px;
  justify-content: flex-start;

  @media (max-width: 600px) {
    padding: 0;
  }
`

interface ItemStylesInterface {
  isDark?: boolean
  textCenter?: boolean
}

export const EmptyStateMesage = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 40px;

  text-align: center;

  color: #000000;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  //margin-top: 3rem;

  flex: 1;
  width: 100%;

  u {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    order: 1;
    margin-top: 0;
  }
`

export const Item = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 20px;
  flex: 0 0 31%;
  overflow: hidden;

  @media (max-width: 600px) {
    flex: 0 0 100%;
    order: 2;
  }
`

export const ItemHead = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const ItemOptions = styled.div`
  flex: 0 0 20%;
  text-align: right;
`

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin: 24px 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 35vh;
`

export const ItemListItem = styled.div.attrs(
  (props: {disabled: boolean}) => props,
)`
  opacity: ${props => props.disabled ? '0.21' : '1'};
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
`

export const ItemHeader = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 40px;
  padding-bottom: 12px;
  border-color: ${props => props.isDark ? 'white' : 'black'} !important;
  text-align: ${props => props.textCenter ? 'center' : 'left'};

  flex: 0 0 80%;;
`

export const ItemSubHeader = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 40px;
  border-bottom: 1px solid #000000;
  padding: 12px 32px;
  border-color: ${props => props.isDark ? 'white' : 'black'} !important;
  text-align: ${props => props.textCenter ? 'center' : 'left'};
`

export const ItemWrapper = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border: 2px solid #000000;
  border-radius: 36px;
  height: 100%;

  padding: 24px;
`

export const ItemIcons = styled.div`
  flex: 0 0 58px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ItemDescription = styled.div`
  flex: 0 0 63%;
  overflow: hidden;
`

export const ItemTitle = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 84%
`

export const ItemReleaseDate = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  display: inline;
`

export const SeeMoreButton = styled(Button)`
  &.MuiButton-root {
    margin-top: 24px;
    display: block;
    background-color: #000;

    &:hover {
      color: #000;
    }
  }
`

export const ItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  flex: 0 0 16%;
`

export const ItemBody = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 32px 32px 48px 32px;
  word-break: break-word;
`

export const ButtonWrapper = styled.div`
  justify-content: end;
  width: 100%;
  display: flex;

  @media (max-width: 600px) {
    justify-content: center;
  }
`

export const PageTitle = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 80px;
  color: #000000;
  margin-top: 16px;
  width: 100%;

  @media (max-width: 600px) {
    text-align: center;
    font-size: 2.5rem;
    line-height: normal;
    margin-bottom: 32px;
  }
`

export const ReturnText = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  padding-left: 8px;
  padding-bottom: 4px;
`

export const ReturnButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const MessageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const LoadingCircle = styled(CircularProgress)`
  margin-left: 12px;
`

export const SupportTierCardWrapper = styled.div`
  width: 40%;
  margin-bottom: 16px;

  &.MuiCard-root {
    background-color: #1f1f1f;
    color: white;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`

export const TiersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const AddTiersButton = styled(Button)`
  align-self: center;

  &.MuiButtonBase-root {
    margin-bottom: 24px;
  }
`

export const ErrorText = styled.div`
  color: red;
  margin-bottom: 12px;
`

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 32px;
`

export const FullWidthForm = styled(Form)`
  width: 100%;
`

export const ListHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 3rem 0 2rem 0;

  span {
    font-family: 'Gellix';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
    background: black;
    padding: 0.5rem 2rem;
    border: 2px solid #000000;
    border-radius: 99px;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: #000;
      left: 0;
      top: 50%;
      position: absolute;
      z-index: -1;
    }
  }
`

export const MicrophoneCircledIcon = styled(MicIcon)`
  background: black;
  color: #fff;
  border-radius: 100%;
  width: 1.3rem !important;
  height: 1.3rem !important;
  padding: 4px;
`

export const PigIcon = styled(PigIconSvg)`
  width: 1.8rem !important;
  height: 1.8rem !important;
`

export const OrderIcon = styled(MenuIcon)`
  width: 1.1rem;
  height: 1.1rem;
  cursor: move;
`

export const CloseIcon = styled(CloseIconSvg)`
  width: 1.1rem;
  height: 1.1rem;
  cursor: pointer;
  color: red;
`

export const MuiDialog = styled(Dialog)`
  &.MuiPaper-root {
    padding: 24px;
  }
`

export const MuiDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 24px;
  }
`

export const DialogTextWrapper = styled.div`
  padding: 18px 24px;
`

export const PodcastModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 12px;
  align-items: stretch;
`

export const DialogText = styled(MuiDialogTitle)`
  &.MuiDialogTitle-root {
    font-family: 'Gellix';
    font-size: 1.2em;
    font-weight: bold;
    padding: 0px 0px;
  }
`