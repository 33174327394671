import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import useFetch from '../../../hooks/useFetch'
import { createPathWithQueryParam, useGoToRoute, usePathName, useQuery, } from '../../../Routes/RouteAux'
import CreditCards from '../../../components/CreditCards'
import { useTypedSelector } from '../../../reducers'
import { FullSupportInformation, Payment, PaymentMethod, PostCreditCard, SupportTier, } from '../../../types'
import { Colors } from '../../../styles'
import {
  createPixTipCharge,
  createSupport,
  CreateSupportInterface,
  createTipCharge,
  createUserPaymentMethod,
  getPodcastDetails,
  getSupportInformationByPodcastId,
  getUserPaymentMethods,
  getUserSupports,
  listTipsByUserByPodcast,

} from '../../../services/api'
import { logAnalyticsEvent } from '../../../services/firebase'
import { getErrorPath, getPodcastPath } from '../../../Routes/RouteNames'
import BasePage from '../../../components/BasePage/BasePage'
import * as Styled from './Support.styled'
import StyledNavLink from '../../../components/StyledNavLink'
import UserSupporter from './UserSupporter'
import { ProfileType } from '../../../reducers/profiles'

import * as monitor from '../../../services/monitor'
import DOMPurify from 'dompurify'
import RichTextEditor from 'components/RichTextEditor'
import PixLogoImage from '../../../assets/images/pix.png'
import * as Yup from 'yup'
import CPF from 'cpf'
import { Formik } from 'formik'
import NumberFormatField from '../../../components/Fields/NumberFormatField/NumberFormatField'
import copyToClipboard from '../../../services/copyToClipboard'
import PodcastSummary from 'pages/podcast/components/PodcastSummary'
import SupportTierCard from 'components/SupportTierCard/SupportTierCard'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

const pixFormValidationSchema = Yup.object().shape({
  userCpf: Yup.string()
    .when('userCnpj', {
      is: (value) => !value,
      then: Yup.string().required('É necessário informar o CPF'),
    })
    .test(
      'cpfValidation',
      'CPF inválido',
      (value) => !value || CPF.isValid(value),
    ),
})

interface FormValues {
  userCpf: string
}

interface PaymentProps {
  signOut: () => Promise<void>
}

const Support: React.FC<PaymentProps> = ({ signOut }) => {
  const { podcastId } = useParams<{ podcastId: string }>()
  const query = useQuery()
  const supportId = query.get('supportId');

  const [supportValue, setSupportValue] = useState<string>(
    query.get('value') || '5.00',
  )
  const allowValueEditing = !!query.get('allowValueEditing')
  const [createSupportData, setCreateSupportData] =
    useState<CreateSupportInterface>()
  const [isCreatingSupport, setIsCreatingSupport] = useState(false)
  const [isInsertingNewCard, setIsInsertingNewCard] = useState(false)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()
  const [finishedPayment, setFinishedPayment] = useState(false)
  const [podcastDetails, setPodcastDetails] = useState<any>()
  const [userTipsForPodcast, setUserTipsForPodcast] = useState<Payment[]>([])
  const [isFetching, setIsFetching] = useState<boolean>()
  const [userSupport, setUserSupport] = useState<any>()
  const [podcastFullSupportInformation, setPodcastFullSupportInformation] =
    useState<FullSupportInformation>(null)
  const [isPayingByPix, setIsPayingByPix] = useState<boolean>(false)
  const [pixQRCode, setPixQRCode] = useState<string>()
  const [pixQRCodeUrl, setPixQRCodeUrl] = useState<string>()

  const [pixExpiresAt, setPixExpiresAt] = useState<string | undefined>(
    undefined,
  )
  const [shouldReloadPage, setShouldReloadPage] = useState<boolean>(false)
  const [showPixForm, setShowPixForm] = useState(false)

  const goToRoute = useGoToRoute()

  const errorRoute = createPathWithQueryParam(getErrorPath(), {
    redirectTo: getPodcastPath(podcastId),
  })

  const currentProfile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const {
    isLoading,
    data: paymentMethods,
    fetchData: refetchPaymentMethods,
  } = useFetch<PaymentMethod[]>(useCallback(() => getUserPaymentMethods(), []))

  // Fetch podcast information
  const {
    isLoading: isLoadingPodcast,
    data: podcastInformation,
    error: errorPodcast,
    fetchData: fetchPodcast,
  } = useFetch<FullSupportInformation>(
    useCallback(() => {
      return getSupportInformationByPodcastId(podcastId)
    }, [podcastId]),
  )

  useEffect(() => {
    setIsFetching(true)
    getSupportInformationByPodcastId(podcastId)
      .then((res) => {
        setPodcastFullSupportInformation(res?.data)
      })
      .finally(() => {
        setIsFetching(false)
      })

    getUserSupports(podcastId).then((res) => {
      setIsFetching(false)
      if (!res.error) {
        setUserSupport(res.data)
      }
    })
    getPodcastDetails(podcastId).then((res) => {
      setIsFetching(false)
      if (!res.error) {
        setPodcastDetails(res.data)
      } else {
        goToRoute(errorRoute)
      }
    })
    listTipsByUserByPodcast(currentProfile.id, podcastId).then((res) => {
      if (!res.error) {
        setUserTipsForPodcast(res.data)
      }
    })
    setShouldReloadPage(false)
  }, [shouldReloadPage])

  useEffect(() => {

  }, [podcastInformation])


  const supportTier: SupportTier = useMemo(() => {
    if (podcastInformation?.supportTiers.length && supportId) {
      return podcastInformation?.supportTiers.filter(tier => tier.id === supportId?.toString())[0]
    }
    return []
  }, [podcastInformation, supportId])


  if (currentProfile.profile !== 'listener') {
    return (
      <BasePage signOut={signOut}>
        <Styled.PageWrapper>
          <Styled.ErrorContainer>
            <Styled.TitleTextWithMargin>Ops, temos um problema!</Styled.TitleTextWithMargin>
            <Styled.BodyText>
              Por enquanto apenas ouvintes podem apoiar um podcast, para acessar
              as informações dessa tela você deve utilizar um perfil de ouvinte
            </Styled.BodyText>
          </Styled.ErrorContainer>
        </Styled.PageWrapper>
      </BasePage>
    )
  }


  if (isLoading || isFetching || isLoadingPodcast) {
    return (
      <BasePage signOut={signOut}>
        <Styled.LoadingWrapper>
          <CircularProgress />
          <Styled.LoadingText>Carregando ...</Styled.LoadingText>
        </Styled.LoadingWrapper>
      </BasePage>
    )
  }

  const onSupportPayment = (pixDocument?: string): void => {
    if (!supportValue || supportValue.length === 0)
      return setErrorMessage('Insira um valor.')
    setIsCreatingSupport(true)
    setErrorMessage(undefined)
    const newCreateSupportData = {
      paymentMethodId: pixDocument ? '' : createSupportData.paymentMethodId,
      paymentMethodType: pixDocument
        ? 'pix'
        : createSupportData.paymentMethodType,
      podcastId: podcastId,
      value: supportValue,
      documentNumber: pixDocument,
    }

    setCreateSupportData(newCreateSupportData)
    createSupport(newCreateSupportData)
      .then((res) => {
        if (res.error) {
          if (res.error.message.indexOf('minimumSupportValue=') >= 0) {
            const minimumSupportValue = res.error.message.split(
              'minimumSupportValue=',
            )[1]
            setErrorMessage(`O valor mínimo é R$ ${minimumSupportValue.replace(
              '.',
              ',',
            )}
          .`)
          } else if (res.error.message.indexOf('maxSupportValue=') >= 0) {
            const maxSupportValue =
              res.error.message.split('maxSupportValue=')[1]
            setErrorMessage(`O valor máximo é R$ ${maxSupportValue.replace(
              '.',
              ',',
            )}
          .`)
          } else {
            goToRoute(errorRoute)
          }
        } else {
          logAnalyticsEvent('supportStart', {
            podcastId: newCreateSupportData.podcastId,
            paymentMethodType: newCreateSupportData.paymentMethodType,
            value: newCreateSupportData.value,
          })

          if (res.data?.pixQrCode) {
            setPixQRCode(res.data.pixQrCode)
            setPixQRCodeUrl(res.data.pixQrCodeUrl)
            setPixExpiresAt(res.data.expiresAt)
          } else {
            setFinishedPayment(true)
          }
        }
      })
      .finally(() => setIsCreatingSupport(false))
  }

  const onTipPayment = (): void => {
    if (!supportValue || supportValue.length === 0)
      return setErrorMessage('Insira um valor.')
    setIsCreatingSupport(true)
    setErrorMessage(undefined)
    const newCreateSupportData = {
      paymentMethodId: createSupportData.paymentMethodId,
      paymentMethodType: createSupportData.paymentMethodType,
      podcastId: podcastId,
      value: supportValue,
    }
    setCreateSupportData(newCreateSupportData)
    createTipCharge(newCreateSupportData)
      .then((res) => {
        if (res.error) {
          if (res.error.message.indexOf('minimumTipValue=') >= 0) {
            const minimumSupportValue = res.error.message.split(
              'minimumSupportValue=',
            )[1]
            setErrorMessage(`O valor mínimo é R$ ${minimumSupportValue.replace(
              '.',
              ',',
            )}
          .`)
          } else {
            goToRoute(errorRoute)
          }
        } else {
          logAnalyticsEvent('TipStart', {
            podcastId: newCreateSupportData.podcastId,
            paymentMethodType: newCreateSupportData.paymentMethodType,
            value: newCreateSupportData.value,
          })
          setFinishedPayment(true)
        }
      })
      .finally(() => setIsCreatingSupport(false))
  }

  const onAddCard = async (creditCard: PostCreditCard): Promise<void> => {
    setIsInsertingNewCard(true)
    const resCreatePaymentMethod = await createUserPaymentMethod(creditCard)

    if (resCreatePaymentMethod.error) {
      setIsInsertingNewCard(false)
      monitor.notify(
        resCreatePaymentMethod.error,
        'erro ao criar cartão ou atualizar endereço',
      )
      if (
        resCreatePaymentMethod.error &&
        resCreatePaymentMethod.error.message &&
        resCreatePaymentMethod.error.message.indexOf(
          'Expiration year is invalid',
        ) >= 0
      ) {
        alert(
          'Ops! Erro ao criar cartão. Verifique a data de validade do cartão.',
        )
        return
      }
      alert(
        'Ops! Erro ao criar cartão. Verifique sua conexão com a internet e tente novamente ou entre em contato com nosso suporte',
      )
      return
    }
    refetchPaymentMethods()
    setIsInsertingNewCard(false)
  }

  if (userSupport && userSupport.isActive) {
    return (
      <UserSupporter
        signOut={signOut}
        creditCardNumber={userSupport.creditCardNumber}
        nextBillingDate={userSupport.nextBillingDate}
        podcastTitle={podcastDetails ? podcastDetails.title : ''}
        supportId={userSupport.supportId}
        supportValue={userSupport.value}
        podcastId={podcastId}
        pixQRCodeUrl={userSupport.pixQRCodeUrl}
        pixQRCode={userSupport.pixQRCode}
      />
    )
  }

  const mainContent = () => {
    if (isCreatingSupport || isInsertingNewCard) {
      return (
        <Styled.LoadingWrapper>
          <CircularProgress />
          <Styled.LoadingText>
            {isInsertingNewCard
              ? 'Adicionando novo cartão'
              : 'Aguarde enquanto processamos o seu pagamento...'}
          </Styled.LoadingText>
        </Styled.LoadingWrapper>
      )
    }

    if (finishedPayment) {
      return (
        <Styled.PaymentDataWrapper>
          <Styled.FinishedPaymentWrapper>
            <Styled.BodyTextWithMargin>
              Pagamento concluído com sucesso!
            </Styled.BodyTextWithMargin>
            <Styled.TitleTextWithMargin>✅</Styled.TitleTextWithMargin>
            <Styled.TitleTextWithMargin>
              Você faz parte da comunidade de apoiadores de{' '}
              {podcastDetails && podcastDetails.title}
            </Styled.TitleTextWithMargin>
            <Styled.BodyTextWithBiggerMargin>
              Ficamos felizes em ter você por aqui. Obrigado por apoiar a
              cultura e o jornalismo.
            </Styled.BodyTextWithBiggerMargin>
            <StyledNavLink to={`/podcast/${podcastId}`}>
              <ButtonShadow variant="primary" type="colored" label='Voltar para página do creator' />

            </StyledNavLink>
          </Styled.FinishedPaymentWrapper>
        </Styled.PaymentDataWrapper>
      )
    }

    const onPix = async (formValues: FormValues): Promise<void> => {
      setIsFetching(true)

      setIsPayingByPix(true)

      if (podcastDetails?.hasCampaignEnabled) {
        await createPixTipCharge(podcastId, supportValue, formValues.userCpf)
          .then((value) => {
            setPixQRCode(value.data.pixQrCode)
            setPixQRCodeUrl(value.data.pixQrCodeUrl)
            setPixExpiresAt(value.data.expiresAt)
          })
          .catch((reason) => {
            setPixQRCode(undefined)
          })
      } else {
        await onSupportPayment(formValues.userCpf)
      }

      setIsFetching(false)
    }

    if (isPayingByPix && !errorMessage) {
      if (pixQRCode || pixQRCodeUrl) {
        return (
          <>
            <Styled.SupportPriceWrapperColumn>
              <Styled.TitleTextWithMargin>Pix</Styled.TitleTextWithMargin>
              <Styled.BodyTextWithMargin>
                Acesse o aplicativo ou site do seu banco, selecione <b>Pix</b> e
                depois clique em <b>Pagar</b>
              </Styled.BodyTextWithMargin>
              <img
                src={pixQRCodeUrl}
                style={{ background: Colors.BRAND_PRIMARY, padding: '16px', width: '65%', height: 'auto' }}
              />
              <Styled.CopyPixButtonWrapper>
                <ButtonShadow
                  onPress={() => {
                    copyToClipboard(pixQRCode).then(() => {
                      alert(`Código pix copiado!`)
                    })
                  }}
                  label='Copiar código pix'
                />

              </Styled.CopyPixButtonWrapper>
              <Styled.BodyTextWithMargin>
                Leia o QR Code no celular ou copie o código Pix no computador.
              </Styled.BodyTextWithMargin>
              <Styled.BodyTextWithMargin>
                O seu pagamento pode demorar alguns minutos para ser registrado
                em nosso sistema.
              </Styled.BodyTextWithMargin>
              {pixExpiresAt && (
                <Styled.BodyTextWithMargin>
                  Seu código expira às: {pixExpiresAt}
                </Styled.BodyTextWithMargin>
              )}

              <Styled.PaymentButtonWrapper>
                <StyledNavLink to={`/podcast/${podcastId}`}>
                  <ButtonShadow variant='secondary' type='black' label='Voltar para página do creator' />
                </StyledNavLink>
              </Styled.PaymentButtonWrapper>
            </Styled.SupportPriceWrapperColumn>
          </>
        )
      } else {
        return (
          <>
            <Styled.SupportPriceWrapper>
              <Styled.BodyTextWithMargin>Pix</Styled.BodyTextWithMargin>
              <Styled.TitleTextWithMargin>
                Oops! Não foi possível gerar o Pix para pagamento.
              </Styled.TitleTextWithMargin>
              <Styled.TitleTextWithMargin>
                Por favor, clique no botão abaixo para tentar novamente ou entre
                em contato com nosso suporte.
              </Styled.TitleTextWithMargin>
              <Styled.CustomStyleButton
                variant="contained"
                fontColor="black"
                onClick={() => {
                  setIsPayingByPix(false)
                  setShouldReloadPage(true)
                }}
              >
                Recarregar página
              </Styled.CustomStyleButton>
            </Styled.SupportPriceWrapper>
          </>
        )
      }
    }

    return (
      <>
        {allowValueEditing ?
          <Styled.SupportPriceWrapper>
            <Styled.SupportText>
              Digite abaixo o valor do apoio
            </Styled.SupportText>
            <Styled.NumberFormatStyled
              decimalSeparator=","
              thousandSeparator="."
              allowNegative={false}
              prefix="R$ "
              sufix="/Mes"
              decimalScale={2}
              fixedDecimalScale={true}
              value={Number(supportValue)}
              hintText="Valor do apoio"
              placeholder="Valor do apoio"
              onValueChange={(values) => {
                setSupportValue(values.value)
              }}
            />
            {errorMessage && <Styled.ErrorText>{errorMessage}</Styled.ErrorText>}
          </Styled.SupportPriceWrapper>
          :
          <Styled.SupportText>
            Agora escolha como fazer seu apoio
          </Styled.SupportText>
        }

        <CreditCards
          creditCards={paymentMethods}
          value={supportValue}
          onSelectCreditCard={(pm) =>
            setCreateSupportData({
              paymentMethodId: pm.paymentMethodId,
              paymentMethodType: pm.type,
              podcastId: podcastId,
              value: supportValue,
            })
          }
          onPayment={() => {
            podcastDetails?.hasCampaignEnabled
              ? onTipPayment()
              : onSupportPayment()
          }}
          onAddCard={onAddCard}
          customHeadlineColor={Colors.SUPPORT_ALERT}
        />

        <Styled.SupportPriceWrapperWithMargin>
          <Styled.PixWrapper>
            <Styled.PixLogo src={PixLogoImage} />
            <Formik
              validationSchema={pixFormValidationSchema}
              initialValues={{ userCpf: undefined }}
              onSubmit={async (values) => {
                await onPix(values)
              }}
              enableReinitialize
            >
              {({ handleSubmit, values, touched, errors }) => {
                return (
                  <Styled.PixWrapper>
                    {showPixForm && (
                      <NumberFormatField
                        name={'userCpf'}
                        variant="outlined"
                        label={'Insira seu CPF'}
                        fullWidth
                        format={'###.###.###-##'}
                        mask={'_'}
                        value={values.userCpf}
                        error={!!(touched.userCpf && errors?.userCpf)}
                        helperText={
                          touched.userCpf && (errors?.userCpf as string)
                        }
                        hasEmptyValue
                      />
                    )}
                    <ButtonShadow
                      variant="secondary"
                      type="black"
                      label='Pagar com Pix'
                      onPress={() => {
                        if (showPixForm) {
                          handleSubmit()
                        } else {
                          setShowPixForm(true)
                        }
                      }}
                    />

                  </Styled.PixWrapper>
                )
              }}
            </Formik>
          </Styled.PixWrapper>
        </Styled.SupportPriceWrapperWithMargin>
      </>
    )
  }

  const renderMainText = (data: FullSupportInformation) => {
    if (!data) return null
    if (!data.supportSummary.canBeSupported)
      return (
        <Styled.BodyText>
          Ops, parece que esse podcast ainda não pode ser apoiado aqui na Orelo.
          Entre em contato com o pessoal do podcast para que eles façam o
          cadastro aqui com a gente.
        </Styled.BodyText>
      )

    if (data.supportSummary.supportDescription) {
      return (
        <RichTextEditor
          initialValue={data.supportSummary.supportDescription}
          readonly
        />
      )
    }

    return (
      <Styled.BodyText
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(data.supportSummary.description),
        }}
      />
    )
  }

  return (
    <BasePage signOut={signOut}>
      <Styled.PageWrapper>
        <PodcastSummary
          podcastFullSupportInformation={podcastInformation}
          isViewingForum={false}
          showPodcastOptions={false}
          onClick={() => goToRoute(getPodcastPath(podcastId))}
        />
        <Styled.FlexWrapper>
          {podcastDetails?.hasCampaignEnabled &&
            userTipsForPodcast?.length > 0 && (
              <Styled.PaymentDataWrapper>
                <Styled.SubTitle>
                  Você já fez o{userTipsForPodcast?.length > 1 ? 's' : ''}{' '}
                  seguinte{userTipsForPodcast?.length > 1 ? 's' : ''} apoio
                  {userTipsForPodcast?.length > 1 ? 's' : ''} para esta
                  campanha:
                </Styled.SubTitle>
                <Styled.Description>
                  <ul>
                    {userTipsForPodcast.map((userTip) => (
                      <li>
                        R${userTip.value} no dia {userTip.createdAt}
                      </li>
                    ))}
                  </ul>
                  <Styled.BodyText>
                    <i>Você pode realizar quantos apoios quiser!</i>
                  </Styled.BodyText>
                </Styled.Description>
              </Styled.PaymentDataWrapper>
            )}
          <Styled.SupportCardColumn>
            <Styled.SupportText>Você escolheu {supportTier?.title}</Styled.SupportText>
            <Styled.SupportValueContainer>
              <Styled.SupportTextValue>
                {finishedPayment
                  ? `Apoio confirmado para: ${podcastFullSupportInformation?.supportSummary.title}`
                  : 'Apoio'}
              </Styled.SupportTextValue>
              <Styled.SupportValue>
                R$ {Number(supportTier?.value)}/mês
              </Styled.SupportValue>
            </Styled.SupportValueContainer>
            <SupportTierCard
              key={supportTier?.title}
              title={supportTier?.title}
              description={supportTier?.description}
              value={supportTier?.value}
            />
          </Styled.SupportCardColumn>

          <Styled.SupportCardColumn>{mainContent()}</Styled.SupportCardColumn>
        </Styled.FlexWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default Support
