import styled from 'styled-components'
import { Card } from '@material-ui/core'
import { Colors } from 'styles'

export const CardTitleAndValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

`

export const CardTitle = styled.div`
  font-size: 32px;
  width: 50%;
  font-weight: bold;
  font-family: SpaceGrotesk;
  word-break: break-word;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`

export const CardDescription = styled.div`
  font-size: 16px;
  text-justify: inter-word;
  white-space: break-spaces;
  font-family: Inter;
  padding: 16px 0;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`

export const DescriptionTitle = styled.div`
  font-family: SpaceGrotesk;
  font-size: 22px;
  font-weight: bold;
`

export const StyledCard = styled(Card)`
  height: auto;
  width: 100%;

  &.MuiPaper-rounded {
    border-radius: 12px;
    background-color:${Colors.GREY[400]};
    color: ${Colors.WHITE};
  }
`

export const CardValue = styled.span`
  font-family: SpaceGrotesk;
  font-weight: 600;
  font-size: 1.3rem;
  padding: 8px;
  background-color: ${Colors.PURPLE[400]};
`
