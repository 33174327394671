import React from 'react'
import * as Styled from './NavigationBar.styled'
import {
  getCreatorDashBoardPath,
  getEditSupportPath,
  getEpisodeListingPath,
  getCreatorProfilePath,
  getListArticlesPath,
  getMetricsPath,
} from '../../Routes/RouteNames'
import { useGoToRoute } from '../../Routes/RouteAux'
import { useTypedSelector } from '../../reducers'
import { ProfileType } from '../../reducers/profiles'
import { useLocation } from 'react-router-dom'

const NavigationBar: React.FC = () => {
  const goToRoute = useGoToRoute()

  const location = useLocation()

  const currentProfile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const renderMenuButton = (targetRoute: string, Icon: any, title: string) => (
    <Styled.MenuButton
      buttonColor={location.pathname === targetRoute ? 'black' : 'white'}
      fontColor={location.pathname === targetRoute ? 'white' : 'black'}
      borderColor="black"
      buttonColorOnHover="#919191"
      onClick={() => goToRoute(targetRoute)}
    >
      <Icon isActive={location.pathname === targetRoute} /> {title}
    </Styled.MenuButton>
  )

  return (
    <Styled.NavigationBarWrapper>
      <Styled.MenuWrapper>
        <Styled.MenuButton
          buttonColor="white"
          buttonColorOnHover="#919191"
          fontColor="black"
          borderColor="black"
          onClick={() => goToRoute(getCreatorDashBoardPath(currentProfile.id))}
        >
          <Styled.OreloCloudIcon />
        </Styled.MenuButton>

        {renderMenuButton(
          getListArticlesPath(currentProfile.id),
          Styled.QuoteIcon,
          'Textos e newsletters',
        )}

        {renderMenuButton(
          getEpisodeListingPath(currentProfile.id),
          Styled.MicrophoneIcon,
          'Episódios',
        )}

        {currentProfile.podcastRole === 'manager' && renderMenuButton(
          getEditSupportPath(currentProfile.id),
          Styled.DollarCircledIcon,
          'Campanhas e Apoios',
        )}

        {currentProfile.podcastRole === 'manager' && renderMenuButton(
          getMetricsPath(currentProfile.id),
          Styled.GraphsIcon,
          'Métricas',
        )}

        {currentProfile.podcastRole === 'manager' && renderMenuButton(
          getCreatorProfilePath(currentProfile.id),
          Styled.SettingsCircledIcon,
          'Configurações',
        )}
      </Styled.MenuWrapper>
    </Styled.NavigationBarWrapper>
  )
}

export default NavigationBar
