import React, {useCallback, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {CSVLink} from 'react-csv'

import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './CreatorMetricsPage.styled'
import useFetch from '../../hooks/useFetch'
import {SummarizedAnalytics} from '../../types'
import {getPodcastPayments, getSummarizedAnalytics,} from '../../services/api'
import Button from '../../components/Button/Button'
import MetricsCard from "../../components/MetricsCard/MetricsCards";
import {parseDateToStringWithTime} from "../../utils/parseDateToString";

const HEADERS = [
  {label: 'Data', key: 'date'},
  {label: 'Hora', key: 'time'},
  {label: 'Nome', key: 'payerName'},
  {label: 'Email', key: 'payerEmail'},
  {label: 'Tipo', key: 'type'},
  {label: 'Valor', key: 'value'},
  {label: 'Email Secundário', key: 'payerSecondaryEmail'},
  {label: 'Telefone', key: 'payerPhone'},
  {label: 'País', key: 'country'},
  {label: 'Estado', key: 'state'},
  {label: 'Cidade', key: 'city'},
  {label: 'CEP', key: 'zipCode'},
  {label: 'Logradouro', key: 'address'},
  {label: 'Número', key: 'streetNumber'},
  {label: 'Complemento', key: 'streetComplement'},
]

interface CreatorMetricsProps {
  signOut: () => Promise<void>
}

const CreatorMetricsPage: React.FC<CreatorMetricsProps> = ({
                                                             signOut,
                                                           }) => {
  const {podcastId} = useParams<{ podcastId: string }>()
  const [exportData, setExportData] = useState([])
  const csvLinkEl = useRef<CSVLink>(null)

  const {isLoading, data, error, fetchData} = useFetch<SummarizedAnalytics>(
    useCallback(() => {
      return getSummarizedAnalytics(podcastId)
    }, [podcastId]),
  )

  const getExportData = async () => {
    const res = await getPodcastPayments(podcastId)
    if (res.hasFailed()) {
      alert('Ops, parece que houve um erro, tente novamente')
    } else {
      setExportData(res.data)
      setTimeout(() => {
        csvLinkEl.current.link.click()
      })
    }
  }

  if (isLoading) {
    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (error) {
    return (
      <BasePage signOut={signOut} isDark showNavigationBar>
        <Styled.ErrorPageWrapper>
          <Styled.MessageWrapper>
            Ops, parece que tivemos um erro aqui.
          </Styled.MessageWrapper>
          <Button
            variant="contained"
            fontColor="black"
            onClick={() => {
              fetchData()
            }}
          >
            Tentar novamente
          </Button>
        </Styled.ErrorPageWrapper>
      </BasePage>
    )
  }

  return (
    <BasePage signOut={signOut} showNavigationBar>
      <Styled.PageWrapper>
        <Styled.TitleAndLinkWrapper>Minhas Métricas</Styled.TitleAndLinkWrapper>

        <Styled.CardsWrapper>
          <Styled.CardsLine>
            <MetricsCard
              title="Streams"
              value={data.streamingAnalytics.thirtyDays.count}
              // secondaryValue={data.streamingAnalytics.ninetyDays.count}
              subtitle={'Últimos 30 dias'}
              // secondarySubtitle={'Últimos 90 dias'}
            />
            <MetricsCard
              title="Apoiadores ativos"
              value={data.paymentsAnalytics.thirtyDays.count}
              subtitle={'Últimos 30 dias'}
            />
          </Styled.CardsLine>
          <Styled.CardsLine>
            <MetricsCard
              title="Remuneração por Stream (R$)"
              value={data.streamingAnalytics.thirtyDays.value}
              // secondaryValue={data.streamingAnalytics.ninetyDays.value}
              subtitle={'Últimos 30 dias'}
              // secondarySubtitle={'Últimos 90 dias'}
            />
            <MetricsCard
              title="Remuneração por Apoios (R$)"
              value={data.paymentsAnalytics.thirtyDays.value}
              // secondaryValue={data.paymentsAnalytics.ninetyDays.value}
              subtitle={'Ativos (30 dias)'}
              // secondarySubtitle={'Acumulados (90 dias)'}
            />
          </Styled.CardsLine>
        </Styled.CardsWrapper>

        <Styled.FooterWrapper>
          <Button
            variant="contained"
            buttonColor="black"
            buttonColorOnHover="#424242"
            fontColor="white"
            onClick={getExportData}
            fullWidth
          >
            BAIXAR LISTA DE APOIOS RECEBIDOS NOS ÚLTIMOS 60 DIAS
          </Button>

          <CSVLink
            filename={`${parseDateToStringWithTime(new Date())}.csv`}
            data={exportData}
            headers={HEADERS}
            asyncOnClick={true}
            ref={csvLinkEl}
          />

          <Styled.FooterInfo>
            <a
              href="https://orelohelp.zendesk.com/hc/pt-br/articles/360048418353-Quanto-recebo"
              target="_blank"
            >
              <Styled.InfoIcon/> Clique aqui para entender melhor como as métricas são contabilizadas
            </a>
          </Styled.FooterInfo>
        </Styled.FooterWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default CreatorMetricsPage
