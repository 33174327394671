import styled from 'styled-components'
import { Form } from 'formik'
import Button from '../../components/Button/Button'

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;

  @media (max-width: 600px) {
    width: 95%;
  }
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 32px;
`

export const TextPageWrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    width: 95%;
  }
`

export const PageSubTitle = styled.div`
  font-size: 1.4em;
  font-weight: bold;
  width: 100%;
  margin: 24px 0;
  text-align: center;

  @media (max-width: 600px) {
    text-align: center;
  }
`

export const TitleWrapper = styled.div`
  width: 30%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 600px) {
    align-items: center;
    width: 95%;
  }
`

export const LargeButton = styled(Button)`
  width: 250px;
  height: 32px;
  align-self: center;
  margin: 32px 0;
`
