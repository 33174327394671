import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'

export const Wrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  ${(props) => props.fullWidth && `width: 100%`};
  position: relative;

  > div {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    background: white;
    padding: 8px;
    
    .react-datepicker-wrapper {
      display: flex;

      .react-datepicker__input-container {
        display: flex;

        input {
          border: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          text-align: center;

          font-family: 'Gellix';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 50px;
          color: #000000;
        }
      }
    }
    
    p {
      margin: 0;
      font-size: 0.75rem;
      text-align: left;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      color: #f44336;
    }
  }
`

export const StyledLabel = styled.label.attrs(
  (props: { darkMode?: boolean, borderless?: boolean }) => props,
)`
  font-weight: bold;
  font-size: 22px;
  color: ${props => props.darkMode ? '#000' : 'inherit'} !important;

  &.MuiFormLabel-root {
    font-family: 'Gellix';
  }
`

export const DatePickerFieldWrapper = styled.div.attrs(
  (props: { darkMode?: boolean, borderless?: boolean, fullWidth?: boolean }) => props,
)`
  border: ${props => props.darkMode ? '2px solid #000000' : 'inherit'};
  min-height: 1.1876em;
  padding: 8px 0;
  border-radius: 24px;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  font-family: Gellix;
  font-size: 24px;
`

// This is a material-ui style. We are using this here, because is easier to override MUI style this way
export const transparentBorderStyle = (theme) => ({
  cssFocused: {
    borderColor: 'transparent',
    '&$cssFocused $notchedOutline': {
      borderColor: 'transparent',
    },
  },
  notchedOutline: {
    borderColor: 'transparent',
  },
  root: {
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: 'transparent',
    },
  },
  input: {
    fontFamily: 'Gellix',
  },
  error: {},
  disabled: {},
})