import React from 'react'
import { CardContent } from '@material-ui/core'
import {
  StyledCard,
  CardTitle,
  CardTitleAndValue,
  CardDescription,
  CardValue,
  DescriptionTitle
} from './SupportTierCard.styled'
import StyledNavLink from '../StyledNavLink'
import ButtonShadow from '../ButtonShadow/ButtonShadow'

interface SupportTierCardProps {
  title: string | React.ReactElement
  value: string
  description: string | React.ReactElement
  supportRoute?: string
  color?: string
  fontColor?: string
  colorOnHover?: string
  onPress?: () => void
}

const SupportTierCard: React.FC<SupportTierCardProps> = ({
  title,
  value,
  description,
  supportRoute,
  onPress
}) => {
  return (
    <StyledCard>
      <CardContent>
        <CardTitleAndValue>
          <CardTitle>{title}</CardTitle>
          <CardValue>
            R${Number(value)}
          </CardValue>
        </CardTitleAndValue>
        <CardDescription>
          <DescriptionTitle>
            Descrição
          </DescriptionTitle>
          {description}</CardDescription>
        {supportRoute ?
          <StyledNavLink to={supportRoute}>
            <ButtonShadow
              variant="primary"
              label={`apoiar com R$ ${Number(value)}`}
              onPress={null}
            />
          </StyledNavLink> : onPress ?
            <ButtonShadow
              variant="primary"
              label={`apoiar com R$ ${Number(value)}`}
              onPress={onPress}
            />
            : <></>
        }
      </CardContent>
    </StyledCard >
  )
}

export default SupportTierCard
