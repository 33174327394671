import { TEXT_LIGHT } from './../../styles/colors'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import Button from '../../components/Button/Button'
import { Form } from 'formik'
import { Colors } from 'styles'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px 64px;
  color: ${TEXT_LIGHT};

  @media (max-width: 600px) {
    padding: 16px;
  }
`

export const TitleAndLinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 42px;
  width: 100%;

  @media (max-width: 600px) {
    align-items: center;
  }
`

export const ItemGrid = styled.div`
  display: flex;
  gap: 36px;
  padding: 10px;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 600px) {
    padding: 0;
  }
`

interface ItemStylesInterface {
  isDark?: boolean
  textCenter?: boolean
}

export const EmptyStateMesage = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 40px;
  text-align: center;
  color: #000000;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-top: 3rem;
  flex: 1;

  u {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    order: 1;
    margin-top: 0;
  }
`

export const Item = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 20px;
  flex: 0 0 20%;

  @media (max-width: 600px) {
    flex: 0 0 100%;
    order: 2;
  }
`

export const TierHeader = styled.div`
  display: flex;
  align-items: center;
`

export const ItemHeader = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  font-family: SpaceGrotesk;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 40px;
  border-bottom: 1px solid #000000;
  padding: 12px 32px;
  word-break: break-word;
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
`

export const ItemSubHeader = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 40px;
  border-bottom: 1px solid #000000;
  padding: 12px 32px;
  border-color: ${(props) => (props.isDark ? 'white' : 'black')} !important;
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
`

export const ItemValue = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  font-family: SpaceGrotesk;
  font-weight: 600;
  font-size: 1.3rem;
  padding: 8px;
  background-color: ${Colors.PURPLE[400]};
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
`

export const ItemWrapper = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  border: 1px solid #000000;
  border-radius: 16px;
  color: ${(props) => (props.isDark ? 'white' : 'black')} !important;
  background-color: ${(props) => (props.isDark ? 'black' : 'white')};
`

export const ItemButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
`

export const ItemBody = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 32px;
  word-break: break-word;
`

export const ButtonWrapper = styled.div`
  justify-content: end;
  width: 100%;
  display: flex;

  @media (max-width: 600px) {
    justify-content: center;
  }
`

export const PageTitle = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 80px;
  color: #000000;
  margin-top: 16px;
  width: 100%;

  @media (max-width: 600px) {
    text-align: center;
    font-size: 2.5rem;
    line-height: normal;
    margin-bottom: 32px;
  }
`

export const MessageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const LoadingCircle = styled(CircularProgress)`
  margin-left: 12px;
`

export const SupportTierCardWrapper = styled.div`
  width: 40%;
  margin-bottom: 16px;

  &.MuiCard-root {
    background-color: #1f1f1f;
    color: white;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`

export const TiersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const AddTiersButton = styled(Button)`
  align-self: center;

  &.MuiButtonBase-root {
    margin-bottom: 24px;
  }
`

export const ErrorText = styled.div`
  color: red;
  margin-bottom: 12px;
`

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

export const FullWidthForm = styled(Form)`
  width: 100%;
`

export const Description = styled.div`
  white-space: break-spaces;
  font-family: Inter;
  font-weight: 300;
`

export const DescriptionTitle = styled.span`
  font-family: SpaceGrotesk;
`
