import React, { useRef, useState, useEffect, useContext } from 'react'
import {
  Container,
  Content,
  Column,
  ImageWrapper,
  Row,
  Title,
  TextInfo,
  Controllers,
  Play,
  SkipForward,
  SkipBack,
  TimeSlider,
  BasePlayer,
  Pause,
  RateSelectItem,
  RateSelect,
  PodcastTitle,
} from './AudioPlayer.styled'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'
import usePlayer from 'hooks/usePlayer'
import parseSecondsToText from '../../utils/parseSecondsToText'
import { updatePlayedTime } from 'services/api'
import { AuthContext } from 'authentication/context'

const PROGRESS_INTERVAL = 1000

export interface AudioPlayerProps {}

const AudioPlayer: React.FC<AudioPlayerProps> = () => {
  const playerRef = useRef<ReactPlayer>(null)
  const [speed, setSpeed] = useState<string>('1')
  const [duration, setDuration] = useState(0)
  const [totalPlayedTime, setTotalPlayedTime] = useState(0)
  const playerController = usePlayer()
  const player = useSelector((state: RootState) => state.playerReducer)

  const { user } = useContext(AuthContext) as {
    user: any
  }

  useEffect(() => {
    if (player.currentEpisode.currentTime) {
      playerRef.current.seekTo(player.currentEpisode.currentTime)
    }
  }, [player.currentEpisode.currentAudioUrl])

  const skipTime = (timeToSkip: number) => {
    playerRef.current?.seekTo(
      (playerRef.current ? playerRef.current.getCurrentTime() : 0) + timeToSkip,
      'seconds',
    )
    playerController.updateCurrentTime(playerRef.current.getCurrentTime())
  }

  const skipToTime = (timeToSkip: number) => {
    playerRef.current?.seekTo(timeToSkip, 'seconds')
    playerController.updateCurrentTime(playerRef.current.getCurrentTime())
  }

  const handleDuration = (value: number): void => {
    setDuration(value)
  }

  const handleProgress = (state): void => {
    playerController.updateCurrentTime(state.playedSeconds)
    setTotalPlayedTime((prevState) => prevState + PROGRESS_INTERVAL / 1000)

    updatePlayedTime(player.currentEpisode.currentEpisodeId, {
      currentTime: state.playedSeconds,
      duration: duration,
      totalPlayedTime: totalPlayedTime,
    })
  }

  useEffect(() => {
    if (!user) {
      playerController.pause()
    }
  }, [user])

  return (
    <>
      <Container>
        <BasePlayer
          ref={playerRef}
          url={player.currentEpisode.currentAudioUrl}
          file={{ forceAudio: true }}
          controls
          playing={player.isPlaying && !!user}
          progressInterval={PROGRESS_INTERVAL}
          onProgress={(state) => {
            handleProgress(state)
          }}
          onDuration={handleDuration}
          width={0}
          height={0}
          playbackRate={Number(speed)}
        />
        <TimeSlider
          onChange={(event, newValue) => {
            skipToTime(Number(newValue))
          }}
          max={duration}
          value={player.currentEpisode.currentTime}
        />
        <Content>
          <Column flex={1}>
            <Row>
              <ImageWrapper>
                <img src={player.currentEpisode.thumbnail} />
              </ImageWrapper>
              <Column>
                <Title>{player.currentEpisode.title}</Title>
                <PodcastTitle>
                  {player.currentEpisode.podcastTitle}
                </PodcastTitle>
                <TextInfo>{`${parseSecondsToText(
                  player.currentEpisode.currentTime,
                )}/${parseSecondsToText(duration)}`}</TextInfo>
              </Column>
            </Row>
          </Column>
          <Column>
            <Controllers>
              <Row>
                <Row align="center">
                  <SkipBack onClick={() => skipTime(-15)} />
                  {!player.isPlaying ? (
                    <Play onClick={() => playerController.play()} />
                  ) : (
                    <Pause onClick={() => playerController.pause()} />
                  )}
                  <SkipForward onClick={() => skipTime(15)} />
                </Row>
                <RateSelect
                  className="rate-select"
                  value={speed}
                  onChange={(event: any) => setSpeed(event?.target?.value)}
                >
                  <RateSelectItem value="0.5">0.5x</RateSelectItem>
                  <RateSelectItem value="0.75">0.75x</RateSelectItem>
                  <RateSelectItem value="1">1.0x</RateSelectItem>
                  <RateSelectItem value="1.25">1.25x</RateSelectItem>
                  <RateSelectItem value="1.5">1.5x</RateSelectItem>
                  <RateSelectItem value="1.75">1.75x</RateSelectItem>
                  <RateSelectItem value="2">2x</RateSelectItem>
                </RateSelect>
              </Row>
            </Controllers>
          </Column>
          <Column flex={1}></Column>
        </Content>
      </Container>
    </>
  )
}

export default AudioPlayer
