import React from 'react'
// import { Text } from 'react-native'
// import { Platform, StatusBar, Text } from 'react-native'
import {Provider} from 'react-redux'
// import { ThemeProvider } from 'styled-components'
import {applyMiddleware, createStore} from 'redux'
import thunk from 'redux-thunk'
import {PersistGate} from 'redux-persist/integration/react'
import {persistor, rootReducer} from './reducers'
import {AuthProvider} from './authentication/context'
import AppContainer from './Routes'
// import * as designSystem from './styles'
// import { Colors } from "styles";
// import logo from "./logo.svg";
// import "./App.css";
import SmartBanner from 'react-smartbanner';
import 'SmartBanner.css';

export const store = createStore(rootReducer, applyMiddleware(thunk))

const App = (): JSX.Element => (
  <Provider store={store}>
    {/* <Text>ok</Text> */}
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider style={{flex: 1}}>
        {/* <ThemeProvider theme={designSystem}> */}
        {/* <StatusBar
          backgroundColor={Colors.BRAND_PRIMARY}
          barStyle={Platform.select({
            android: "light-content",
            ios: "dark-content",
          })}
          animated
        />
        */}
        <AppContainer/>
        <SmartBanner
          title={'Orelo'}
          position={'bottom'}
          button={'Ver'}
          storeText={{
            ios: 'Na App Store',
            android: 'Na Google Play'
          }}
          price={
            {ios: 'Grátis', android: 'Grátis', windows: 'Grátis', kindle: 'Grátis'}
          }/>
        {/* </ThemeProvider> */}
      </AuthProvider>
    </PersistGate>
  </Provider>
)

export default App
