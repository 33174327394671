import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './ArticleCreatePage.styled'
import {useGoToRoute} from "../../Routes/RouteAux";
import ArticleForm from "../../components/ArticleForm/ArticleForm";
import {Snackbar} from "@material-ui/core";
import {createArticle, publishArticle, updateArticle, updateDraftArticle} from "../../services/api";
import {getListArticlesPath} from "../../Routes/RouteNames";

interface EditPodcastSupportPageProps {
  signOut: () => Promise<void>
}

interface FormValues {
  title: string
  description: string
  body?: string
  visibility: string
  stringNotifyOnPublish: string
  image?: string
}

const ArticleCreatePage: React.FC<EditPodcastSupportPageProps> = ({
                                                                    signOut,
                                                                  }) => {
  const {podcastId} = useParams<{ podcastId: string }>()

  const [errorOnSave, setErrorOnSave] = useState<string>()
  const [articleId, setArticleId] = useState<string>()

  const goToRoute = useGoToRoute()

  const initialValues: FormValues = {
    title: '',
    description: '',
    body: null,
    visibility: '',
    stringNotifyOnPublish: '',
  }

  useEffect(() => {
    createArticle(podcastId).then(res => {
      if (res.error) {
        setErrorOnSave(res.error.message)
      } else {
        setArticleId(res.data.id)
      }
    })
  }, [])

  const saveArticle = async (formValues: FormValues): Promise<void> => {
    const notifyOnPublish = formValues.stringNotifyOnPublish === 'true'

    let res = await updateArticle(articleId, {
      body: formValues.body,
      title: formValues.title,
      description: formValues.description,
      image: formValues.image
    })

    if (res.error) {
      setErrorOnSave(res.error.message)
    }

    res = await publishArticle(articleId, notifyOnPublish, formValues.visibility)

    if (res.error) {
      setErrorOnSave(res.error.message)
    }
  }

  const saveDraftArticle = async (formValues: FormValues): Promise<void> => {
    const res = await updateDraftArticle(articleId, {
      image: formValues.image,
      title: formValues.title,
      description: formValues.description,
      body: formValues.body
    })

    if (res.error) {
      setErrorOnSave(res.error.message)
    }
  }

  return (
    <BasePage signOut={signOut} showNavigationBar>
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        open={!!errorOnSave}
        autoHideDuration={10000}
        onClose={() => setErrorOnSave(undefined)}
        message={`Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte`}
      />

      <Styled.PageWrapper>
        <Styled.TitleAndLinkWrapper>
          <Styled.PageTitle>Criar Novo txt</Styled.PageTitle>

          <Styled.ArticleFormWrapper>
            <ArticleForm
              initialValues={initialValues}
              onSubmit={async (values) => {
                await saveArticle(values)
                goToRoute(getListArticlesPath(podcastId))
              }}
              podcastId={podcastId}
              onAutoSave={saveDraftArticle}
              isPublished={false}
            />
          </Styled.ArticleFormWrapper>

        </Styled.TitleAndLinkWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default ArticleCreatePage
