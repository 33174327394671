import styled from 'styled-components'
import RichTextEditor from '../../RichTextEditor'

interface BorderProps {
  borderColor?: string
  error?: boolean
}

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

export const Border = styled.div<BorderProps>`
  padding: 20px;
  border: 1px solid ${(props) => (props.error ? '#f44336' : props.borderColor || '#000000')};
  border-radius: 8px;
`

export const RichTextEditorWidth = styled(RichTextEditor)`
  width: 100%;
`

export const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 22px;
`
