import styled from 'styled-components'
import { Card } from '@material-ui/core'

export const MetricsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  height: 200px;
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 10px;
  &.MuiCard-root {
    background-color: transparent;
    color: #000;
    border: 2px solid #000000;
    border-radius: 36px;
    box-shadow: none;
    padding: 8px;
  }
  @media (max-width: 600px) {
    width: 280px;
    height: 180px;
  }
` 

export const CardTitle = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #000000;
  margin-top: 5px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`

export const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

export const Value = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  text-transform: uppercase;

  color: #000000;

  @media (max-width: 600px) {
    font-size: 40px;
  }
`

export const SecondaryValue = styled.div`
  font-size: 24px;
  color: rgba(255, 255, 255, 0.2);
  margin-left: 8px;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`

export const SubtitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
`

export const Subtitle = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;

  color: #000000;


  @media (max-width: 600px) {
    font-size: 16px;
  }
`

export const SecondarySubtitle = styled.div`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.2);

  @media (max-width: 600px) {
    font-size: 14px;
  }
`