import styled from 'styled-components'
import { ReactComponent as ComparativeTableImage } from '../../../../assets/images/home/comparativeTable.svg'
import { ReactComponent as ComparativeTableMobileImage } from '../../../../assets/images/home/comparativeTableMobile.svg'


export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1.5px solid #212427;
  padding-top: 50px;
  padding-bottom: 70px;
  background-color: #e7e3dd;
`
export const TextWrapper = styled.div`
  margin-left: 140px;
  align-self: center;
`
export const TitleStroke = styled.h1`
  text-transform: uppercase;
  text-shadow: -1px 0 #212427, 0 1px #212427, 1px 0 black, 0 -1px #212427;
  color: #e7e3dd;
  margin-top: 0px;
  margin-bottom: 6px;
  font-size: 56px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 35px;
    width: 85%;
  }
`

export const ImageWrapper = styled.div`
  align-self: center;
  width: 65%;

  @media (max-width: 767px) {
    width: 90%;
  }
`

export const Description = styled.p`
  font-size: 12px;
  line-height: 20px;
  font-weigth: 500;
  letter-spacing: -0.02em;
  color: black;
  padding-top: 15px;
`

export const Image = styled(ComparativeTableImage)`
  width: 100%;
  height: auto;
`

export const ImageMobile = styled(ComparativeTableMobileImage)`
  width: 100%;
  height: auto;
`
