import React from 'react'
import {useField} from 'formik'
import {OutlinedTextFieldProps} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import * as Styled from './TextFieldField.styled'
import {transparentBorderStyle} from './TextFieldField.styled'

interface TextFieldFieldProps extends OutlinedTextFieldProps {
  name: string
  label?: string
  borderColor?: string
  classes?: any
  transparentBorder?: boolean
  fullWidth?: boolean
  style?: {
    fontSize?: number
    fontWeight?: number
    backgroundColor?: string
  }
  darkMode?: boolean
  borderless?: boolean
}

const TextFieldField: React.FC<TextFieldFieldProps> = ({
                                                         name,
                                                         label,
                                                         borderColor,
                                                         classes,
                                                         transparentBorder,
                                                         fullWidth,
                                                         style,
                                                         children,
                                                         darkMode,
                                                         borderless,
                                                         ...props
                                                       }) => {
  const [_field, meta, helpers] = useField(name)

  return (
    <Styled.Wrapper fullWidth={fullWidth}>
      {label && <Styled.StyledLabel htmlFor={name} darkMode={darkMode}>{label}</Styled.StyledLabel>}
      <Styled.StyledTextField
        defaultValue={meta.initialValue}
        onChange={(event) => {
          const {target} = event
          helpers.setValue(target.value)
        }}
        id={name}
        InputProps={
          transparentBorder
            ? {
              classes: {
                root: classes.root,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
                input: classes.input,
              },
              style: {
                fontFamily: 'Gellix',
                ...style,
              },
            }
            : {
              style: {
                fontFamily: 'Gellix',
                ...style,
              },
            }
        }
        darkMode={darkMode}
        borderless={borderless}
        {...props}
      />
      {children}
    </Styled.Wrapper>
  )
}

export default withStyles(transparentBorderStyle)(TextFieldField)
