import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const StyledButton = styled(Button)<{
  buttonColor?: string
  borderColor?: string
  labelColor?: string
  fontSize?: 'small' | 'large'
}>`
  &.MuiButton-root {
    background-color: ${(p) => p.buttonColor};
    border: 1.3px solid ${(p) => p.borderColor};
    box-shadow: 4px 4px 0px ${(p) => p.borderColor};
    border-radius: 23px;
    font-weight: 800;
    font-size: 11px;
    font-family: PP-Neue-Machina;
    text-transform: uppercase;
    padding: 8px 16px 6px;

    @media (max-width: 767px) {
      font-size: 8px;
    }
  }

  .MuiButton-label {
    align-items: center;
    --webkit-align-items: center;
  }

  &.MuiButton-contained {
    background-color: ${(p) => p.buttonColor};
    color: ${(p) => p.labelColor};
  }

  &.MuiButtonBase-root {
    background-color: ${(p) => p.buttonColor};
    color: ${(p) => p.labelColor};
    display: flex;
    align-items: center;
    --webkit-align-items: center;
  }
`
