import React, { useState } from 'react'
import {
  EpisodeAndButtonWrapper,
  EpisodeImage,
  StyledIconButton,
  PlayIcon,
  StopIcon,
  StopRewindAndSkipWrapper,
  RewindIcon,
  SkipIcon,
  Image,
  LoadingIcon,
} from './EpisodeCoverButton.styled'
import PigIcon from '../../assets/icons/PIG.png'
interface EpisodeCoverButtonProps {
  isPlaying: boolean
  episodeImage: string
  onPlay: () => Promise<void> | void
  onStop: () => void
  onRewind: () => void
  onSkip: () => void
  userLoggedIn: boolean
  blockedForSupporters: boolean
  onExclusiveToSupporter: () => void
  onNonLoggedIn: () => void
}

const EpisodeCoverButton: React.FC<EpisodeCoverButtonProps> = ({
  isPlaying,
  episodeImage,
  onPlay,
  onStop,
  onRewind,
  onSkip,
  userLoggedIn,
  blockedForSupporters,
  onExclusiveToSupporter,
  onNonLoggedIn,
}) => {
  const [isLoadingEpisode, setIsLoadingEpisode] = useState(false)

  const renderButtons = () => {
    if (blockedForSupporters) {
      return <button type='button' onClick={onExclusiveToSupporter} style={{ width: '100%', height: '100%', opacity: 1, background: 'transparent', border: 'none' }} >  <PlayIcon /></button>
    }

    if (!userLoggedIn) {
      return (
        <StyledIconButton onClick={onNonLoggedIn}>
          <PlayIcon />
        </StyledIconButton>
      )
    }

    if (isPlaying) {
      return (
        <StopRewindAndSkipWrapper>
          <StyledIconButton
            onClick={() => {
              onRewind()
            }}
            small
          >
            <RewindIcon />
          </StyledIconButton>
          <StyledIconButton
            onClick={() => {
              onStop()
            }}
          >
            <StopIcon />
          </StyledIconButton>
          <StyledIconButton
            onClick={() => {
              onSkip()
            }}
            small
          >
            <SkipIcon />
          </StyledIconButton>
        </StopRewindAndSkipWrapper>
      )
    }

    return (
      <StyledIconButton
        onClick={async () => {
          setIsLoadingEpisode(true)
          await onPlay()
          setIsLoadingEpisode(false)
        }}
      >
        {isLoadingEpisode ? (
          <div>
            <LoadingIcon />
          </div>
        ) : (
          <PlayIcon />
        )}
      </StyledIconButton>
    )
  }

  return (
    <EpisodeAndButtonWrapper>
      <EpisodeImage src={episodeImage} lowOpacity={blockedForSupporters} />
      {renderButtons()}
    </EpisodeAndButtonWrapper>
  )
}

export default EpisodeCoverButton
