import React from 'react'
import { Instagram, Twitter } from '@material-ui/icons'
import Button from '../Button/Button'

import {
  ImageAndTitleWrapper,
  PodcastImage,
  PodcastTitle,
  TitleAndLinksWrapper,
  LinksWrapper,
  StyledAnchor,
} from './PodcastImageAndTitle.styled'

interface PodcastImageAndTitleProps {
  image: string
  title: string
  twitterUrl?: string
  instagramUrl?: string
  oreloUrl?: string
}

const PodcastImageAndTitle: React.FC<PodcastImageAndTitleProps> = ({
  image,
  title,
  twitterUrl,
  instagramUrl,
  oreloUrl,
}) => {
  return (
    <ImageAndTitleWrapper>
      <PodcastImage src={image} />
      <TitleAndLinksWrapper>
        <PodcastTitle>{title}</PodcastTitle>
        <LinksWrapper>
          {instagramUrl && (
            <StyledAnchor href={instagramUrl} target="_blank">
              <Instagram fontSize="large" />
            </StyledAnchor>
          )}
          {twitterUrl && (
            <StyledAnchor href={twitterUrl} target="_blank">
              <Twitter fontSize="large" />
            </StyledAnchor>
          )}
          {oreloUrl && (
            <StyledAnchor href={oreloUrl} target="_blank" underlined>
              <Button
                buttonColor="#fafafa"
                buttonColorOnHover="#c7c7c7"
                fontColor="#000000"
              >
                <b>Escute na Orelo 🎧</b>
              </Button>
            </StyledAnchor>
          )}
        </LinksWrapper>
      </TitleAndLinksWrapper>
    </ImageAndTitleWrapper>
  )
}

export default PodcastImageAndTitle
