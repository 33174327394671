import type {FC} from 'react'
import React from 'react'
import {PlaylistItemDetailDTO} from 'types'
import * as Styled from "./PlaylistListingPage.styled";
import {DateClass} from "../../utils/domain/DateClass";

export interface PlaylistEpisodeProps {
  episode: PlaylistItemDetailDTO
  onRemoveItemFromPlaylist: () => Promise<void>
}

export const PlaylistEpisode: FC<PlaylistEpisodeProps> = ({
                                                            episode,
                                                            onRemoveItemFromPlaylist
                                                          }) => {
  return (
    <Styled.ItemListItem>
      <Styled.ItemIcons>
        {episode.episode.exclusiveToSupporters && <Styled.PigIcon/>}
        <Styled.MicrophoneCircledIcon/>
      </Styled.ItemIcons>

      <Styled.ItemDescription>
        <Styled.ItemTitle title={episode.episode.title}>{episode.episode.title}</Styled.ItemTitle>
        <Styled.ItemReleaseDate>{DateClass.fromLongDTO(episode.episode.longReleaseDate).getValue().toLongDTO(false)}</Styled.ItemReleaseDate>
      </Styled.ItemDescription>

      <Styled.ItemActions>
        <Styled.OrderIcon/>

        <Styled.CloseIcon onClick={() => onRemoveItemFromPlaylist()}/>
      </Styled.ItemActions>
    </Styled.ItemListItem>
  )
}
