import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import * as CPF from 'cpf'
import { Metrics, Colors } from '../../styles'
import {
  validThruValidator,
  cardNumberValidator,
  cvvValidator,
} from './validators'
import { cpfMask, cardNumberMask, validThruMask, cvvMask } from './masks'
import { CreditCardForm as CreditCardFormType } from './types'
import Button from '../Button/Button'
import { TextField, TextFieldProps } from '@material-ui/core'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

const Wrapper = styled.div`
  flex: 1;
  justify-content: space-between;
`

const InputWrapper = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '30%')};
  margin-bottom: 30px;
  font-family: Inter;
`
const ValueInput = styled(TextField) <TextFieldProps>`
  width: 100%;

  .MuiOutlinedInput-root {
    line-height: 30px;
    font-size: 18px;
    font-weight: 700;
    padding: 0 15px;
    border: 1px solid ${Colors.GREY[200]};
    color: ${Colors.GREY[200]};
    font-family: Inter;
    outline: none;
    box-shadow: none;
    border-radius: 8px;
    &.Mui-focused {
      color: ${Colors.GREY[400]};;
    }
  }
  .MuiInputLabel-root {
    color: ${Colors.GREY[200]};
    font-family: Inter;
    font-weight: bold;
    &.Mui-focused {
      font-size: 18px;
    }
  }
  .Mui-focused {
    border-top-color: transparent;
    fieldset {
      border-color: ${Colors.GREY[200]}; !important;
    }
  }
`

type Props = {
  onSave: (creditCard: CreditCardFormType) => void
}

const CardWithDocumentForm = ({ onSave }: Props): JSX.Element => {
  const [form, setForm] = useState({
    cardNumber: '',
    cardName: '',
    cardDueDate: '',
    cardCVV: '',
    cpf: '',
  })
  const [touched, setTouched] = useState({
    cardNumber: false,
    cardName: false,
    cardDueDate: false,
    cardCVV: false,
    cpf: false,
  })

  const cardNumberRef = useRef(null)
  const cardNameRef = useRef(null)
  const cardDueDateRef = useRef(null)
  const cardCVVRef = useRef(null)
  const cpfRef = useRef(null)

  const onTypeFormField = (text: string, id: string): void => {
    setForm({
      ...form,
      [id]: text,
    })
    setTouched({
      ...touched,
      [id]: true,
    })
  }

  const checkIsFormValid = (): boolean => {
    const isCPFValid = CPF.isValid(form.cpf)
    const isCardNumberValid = cardNumberValidator(form.cardNumber)
    const isDueDateValid = validThruValidator(form.cardDueDate).isValid
    const isCVVValid = cvvValidator(form.cardCVV)

    return (
      isCPFValid &&
      isCardNumberValid &&
      isDueDateValid &&
      isCVVValid &&
      !!form.cardNumber &&
      !!form.cardName &&
      !!form.cardDueDate &&
      !!form.cardCVV &&
      !!form.cpf
    )
  }

  return (
    <Wrapper>
      <InputWrapper fullWidth>
        <ValueInput
          label="Número"
          error={touched.cardNumber && !cardNumberValidator(form.cardNumber)}
          helperText={
            touched.cardNumber &&
            !cardNumberValidator(form.cardNumber) &&
            'Inválido'
          }
          variant="outlined"
          type="text"
          name="card-number"
          autoComplete="card number"
          value={form.cardNumber}
          onChange={(event) =>
            onTypeFormField(
              cardNumberMask(event.currentTarget.value),
              'cardNumber',
            )
          }
          ref={cardNumberRef}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              cardDueDateRef &&
                cardDueDateRef.current &&
                cardDueDateRef.current.focus()
              event.preventDefault()
            }
          }}
          inputMode="decimal"
        />
      </InputWrapper>
      <InputWrapper fullWidth>
        <ValueInput
          label="Nome no cartão"
          error={touched.cardName && !form.cardName}
          helperText={touched.cardName && !form.cardName && 'Inválido'}
          variant="outlined"
          type="text"
          name="card-name"
          autoComplete="card name"
          value={form.cardName}
          onChange={(event) =>
            onTypeFormField(event.currentTarget.value, 'cardName')
          }
          ref={cardNameRef}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              cpfRef && cpfRef.current && cpfRef.current.focus()
              event.preventDefault()
            }
          }}
          inputMode="text"
        />
      </InputWrapper>
      <InputWrapper fullWidth>
        <ValueInput
          label="Validade"
          error={touched.cardDueDate && !validThruValidator(form.cardDueDate)}
          helperText={
            touched.cardDueDate &&
            !validThruValidator(form.cardDueDate) &&
            'Inválido'
          }
          variant="outlined"
          type="text"
          name="card-due-date"
          value={form.cardDueDate}
          onChange={(event) =>
            onTypeFormField(
              validThruMask(event.currentTarget.value),
              'cardDueDate',
            )
          }
          ref={cardDueDateRef}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              cardCVVRef && cardCVVRef.current && cardCVVRef.current.focus()
              event.preventDefault()
            }
          }}
          inputMode="text"
        />
      </InputWrapper>
      <InputWrapper fullWidth>
        <ValueInput
          label="CVV"
          error={touched.cardCVV && !cvvValidator(form.cardCVV)}
          helperText={
            touched.cardCVV && !cvvValidator(form.cardCVV) && 'Inválido'
          }
          variant="outlined"
          type="text"
          name="card-CVV"
          value={form.cardCVV}
          onChange={(event) =>
            onTypeFormField(cvvMask(event.currentTarget.value), 'cardCVV')
          }
          ref={cardCVVRef}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              cardNameRef && cardNameRef.current && cardNameRef.current.focus()
              event.preventDefault()
            }
          }}
          inputMode="text"
        />
      </InputWrapper>
      <InputWrapper fullWidth>
        <ValueInput
          label="CPF"
          error={touched.cpf && !CPF.isValid(form.cpf)}
          helperText={touched.cpf && !CPF.isValid(form.cpf) && 'Inválido'}
          variant="outlined"
          value={form.cpf}
          onChange={(event) =>
            onTypeFormField(cpfMask(event.currentTarget.value), 'cpf')
          }
          ref={cpfRef}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              checkIsFormValid() &&
                onSave({
                  ...form,
                })
              event.preventDefault()
            }
          }}
          inputMode="decimal"
        />
      </InputWrapper>
      <ButtonShadow
        disabled={!checkIsFormValid()}
        onPress={() => onSave({ ...form })}
        label='Avançar'
      />
    </Wrapper>
  )
}

export default CardWithDocumentForm
