import React, { useState, Fragment } from 'react'
import DOMPurify from 'dompurify'

import { Episode } from 'types'
import * as Styled from './EpisodeCard.styled'
import EpisodeCoverButton from '../EpisodeCoverButton/EpisodeCoverButton'
import {
  createPathWithQueryParam,
  useGoToRoute,
  usePathName,
} from '../../Routes/RouteAux'
import { useTypedSelector } from '../../reducers'
import { getLoginPath, getSupportPodcastPath } from '../../Routes/RouteNames'
import Dialog from '../Dialog/Dialog'
import isMobile from '../../utils/isMobile'
import OptionsButton from 'components/OptionsButton/OptionsButton'
import renderContent from "../../utils/renderContent";

const padlock_lock_icon = <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 11C4 9.89543 4.89543 9 6 9H18C19.1046 9 20 9.89543 20 11V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V11Z" fill="#212427" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10.5H6C5.72386 10.5 5.5 10.7239 5.5 11V20C5.5 20.2761 5.72386 20.5 6 20.5H18C18.2761 20.5 18.5 20.2761 18.5 20V11C18.5 10.7239 18.2761 10.5 18 10.5ZM6 9C4.89543 9 4 9.89543 4 11V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V11C20 9.89543 19.1046 9 18 9H6Z" fill="#212427" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15 2.25C16.5188 2.25 17.75 3.48122 17.75 5V10H16.25V5C16.25 4.30964 15.6904 3.75 15 3.75L9 3.75C8.30964 3.75 7.75 4.30964 7.75 5V10H6.25V5C6.25 3.48122 7.48122 2.25 9 2.25L15 2.25Z" fill="#212427" />
</svg>

export interface EpisodeCardProps
  extends Pick<Episode, 'image' | 'title' | 'releaseDate' | 'description'> {
  blockedForSupporters: boolean
  duration: string
  onPlay: () => Promise<void> | void
  onStop: () => void
  onSkip: () => void
  onRewind: () => void
  isPlaying: boolean
  currentTime?: string
  userLoggedIn: boolean
  podcastId: string
  episodeId?: string
  idx?: number
}

const EpisodeCard: React.FC<EpisodeCardProps> = ({
  blockedForSupporters,
  duration,
  image,
  title,
  releaseDate,
  description,
  onPlay,
  onStop,
  onSkip,
  onRewind,
  isPlaying,
  currentTime,
  userLoggedIn,
  podcastId,
  episodeId,
  idx
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const currentProfile = useTypedSelector(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const pathName = usePathName()
  const loginRoute = createPathWithQueryParam(getLoginPath(), {
    redirectTo: pathName,
  })

  const goToRoute = useGoToRoute()

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <Dialog
        isDialogOpen={isModalOpen}
        handleCloseDialog={handleCloseModal}
        dialogText={
          currentProfile
            ? `episódio exclusivo para apoiadores`
            : `você precisa estar logado`
        }
        dialogActionButtonText={currentProfile ? `apoiar podcast` : `login`}
        onClickDialogActionButton={() =>
          currentProfile
            ? goToRoute(getSupportPodcastPath(podcastId))
            : goToRoute(loginRoute)
        }
        noActionText="ok"
      />
      <Styled.EpisodeCard isOdd={idx && idx % 2 === 1}>
        <EpisodeCoverButton
          isPlaying={isPlaying}
          episodeImage={image}
          onPlay={onPlay}
          onStop={onStop}
          onSkip={onSkip}
          onRewind={onRewind}
          blockedForSupporters={blockedForSupporters}
          userLoggedIn={userLoggedIn}
          onExclusiveToSupporter={handleOpenModal}
          onNonLoggedIn={handleOpenModal}
        />
        <Styled.InfoWrapper>
          <Styled.EpisodeTitle numOfCharacters={title.length}>{title} {blockedForSupporters && padlock_lock_icon}</Styled.EpisodeTitle>
          <Styled.AdditionalInfo>{releaseDate}</Styled.AdditionalInfo>
        </Styled.InfoWrapper>


      </Styled.EpisodeCard>
    </>
  )
}

export default EpisodeCard
