import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import useFetch from '../../hooks/useFetch'
import BasePage from '../../components/BasePage/BasePage'
import PodcastImageAndTitle from '../../components/PodcastImageAndTitle/PodcastImageAndTitle'
import SupportTierCard from '../../components/SupportTierCard/SupportTierCard'
import RichTextEditor from '../../components/RichTextEditor'
import * as Styled from './PodcastSupport.styled'
import {
  getSupportInformationByPodcastId,
  getSupportInformationByUrlPathName,
} from '../../services/api'
import { FullSupportInformation } from '../../types'
import Button from 'components/Button/Button'
import ShareButton from 'components/ShareButton/ShareButton'
import StyledNavLink from '../../components/StyledNavLink'
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu'
import ReportContentDialog from 'components/ReportContentDialog/ReportContentDialog'
import RoundedCard from 'components/RoundedCard'
import DOMPurify from 'dompurify'
import { Colors } from '../../styles'
import { getPodcastContentPath } from '../../Routes/RouteNames'

interface PodcastSupportProps {
  signOut: () => Promise<void>
}

const renderMainText = (data: FullSupportInformation) => {
  if (!data.supportSummary.canBeSupported)
    return (
      <Styled.DescriptionParagraph>
        Ops, parece que esse podcast ainda não pode ser apoiado aqui na Orelo.
        Entre em contato com o pessoal do podcast para que eles façam o cadastro
        aqui com a gente.
      </Styled.DescriptionParagraph>
    )

  if (data.supportSummary.supportDescription) {
    return (
      <RichTextEditor
        initialValue={data.supportSummary.supportDescription}
        styleProperties={{ linkColor: Colors.PURPLE[400] }}
        readonly
      />
    )
  }

  return (
    <Styled.DescriptionParagraph
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(data.supportSummary.description),
      }}
    />
  )
}

const PodcastSupport: React.FC<PodcastSupportProps> = ({ signOut }) => {
  const { podcastUrlPathName } = useParams<{ podcastUrlPathName: string }>()
  const { podcastId } = useParams<{ podcastId: string }>()

  const [reportDialogOpen, setReportDialogOpen] = useState<boolean>(false)

  const { isLoading, data, error, fetchData } =
    useFetch<FullSupportInformation>(
      useCallback(() => {
        if (podcastUrlPathName) {
          return getSupportInformationByUrlPathName(podcastUrlPathName)
        } else {
          return getSupportInformationByPodcastId(podcastId)
        }
      }, []),
    )

  if (isLoading) {
    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (error) {
    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>
            Ops, parece que tivemos um erro aqui.
          </Styled.MessageWrapper>
          <Button variant="contained" fontColor="black" onClick={fetchData}>
            Tentar novamente
          </Button>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  return (
    <BasePage signOut={signOut} isDark>
      <ReportContentDialog
        contentType="podcast"
        contentId={data.supportSummary.podcastId}
        isDialogOpen={reportDialogOpen}
        handleCloseDialog={() => setReportDialogOpen(false)}
      />
      <Styled.PageWrapper backGroundColor={data.podcastBackgroundColor}>
        <RoundedCard flex={1}>
          <Styled.PodcastMenuWrapper>
            <DropdownMenu
              items={[
                {
                  title: 'Denunciar Creator',
                  onClick: () => {
                    setReportDialogOpen(true)
                  },
                },
              ]}
              color={Colors.BRAND_SECONDARY}
            />
          </Styled.PodcastMenuWrapper>
          <Styled.ImageAndTitleWrapper>
            <PodcastImageAndTitle
              image={data.supportSummary.image}
              title={data.supportSummary.title}
            />
            <Styled.ButtonsWrapper>
              <Styled.ButtonWrapper>
                <StyledNavLink to={getPodcastContentPath(data.supportSummary.podcastId)}>
                  <Styled.FixedSizeButton
                    variant="contained"
                    buttonColor={
                      data.podcastBackgroundColor
                        ? Colors.BRAND_SECONDARY
                        : undefined
                    }
                    fontColor={Colors.BRAND_PRIMARY}
                    buttonColorOnHover={
                      data.podcastBackgroundColor
                        ? Colors.LIGHT_GREY
                        : undefined
                    }
                  >
                    <b>Ver conteúdos</b>
                  </Styled.FixedSizeButton>
                </StyledNavLink>
              </Styled.ButtonWrapper>
              <Styled.ButtonWrapper>
                <ShareButton
                  shareType="podcast"
                  shareId={data.supportSummary.podcastId}
                  linkTitle={data.supportSummary.title}
                  linkImage={data.supportSummary.image}
                  linkDescription={`Conheça a comunidade de ${data.supportSummary.title} na Orelo!`}
                  redirectToMobileApp={true}
                  color={
                    data.podcastBackgroundColor
                      ? Colors.BRAND_SECONDARY
                      : '#C0AC43'
                  }
                  fontColor={
                    data.podcastBackgroundColor
                      ? Colors.BRAND_PRIMARY
                      : undefined
                  }
                  colorOnHover={
                    data.podcastBackgroundColor ? Colors.LIGHT_GREY : '#675b1b'
                  }
                />
              </Styled.ButtonWrapper>
              {data.supportSummary.hideSupportValues && (
                <Styled.ButtonWrapper>
                  <StyledNavLink
                    to={`/payments/support/${data.supportSummary.podcastId}?value=0&allowValueEditing=true`}
                  >
                    <Styled.FixedSizeButton
                      variant="contained"
                      buttonColorOnHover="#675b1b"
                      buttonColor="#C0AC43"
                    >
                      <b>Apoiar</b>
                    </Styled.FixedSizeButton>
                  </StyledNavLink>
                </Styled.ButtonWrapper>
              )}
            </Styled.ButtonsWrapper>
          </Styled.ImageAndTitleWrapper>

          <Styled.DescriptionAndTiersWrapper>
            <Styled.DescriptionAndButtons>
              {renderMainText(data)}
            </Styled.DescriptionAndButtons>
            {!data.supportSummary.hideSupportValues && (
              <Styled.Tiers>
                {data.supportSummary.canBeSupported &&
                  data.supportTiers.map((tier) => (
                    <>
                      <SupportTierCard
                        key={tier.title}
                        title={tier.title}
                        description={tier.description}
                        value={tier.value}
                        supportRoute={`/payments/support/${data.supportSummary.podcastId}?value=${tier.value}`}
                        color={
                          data.podcastBackgroundColor
                            ? Colors.BRAND_SECONDARY
                            : undefined
                        }
                        fontColor={
                          data.podcastBackgroundColor
                            ? Colors.BRAND_PRIMARY
                            : undefined
                        }
                        colorOnHover={
                          data.podcastBackgroundColor
                            ? Colors.LIGHT_GREY
                            : undefined
                        }
                      />
                      <Styled.Whitespace />
                    </>
                  ))}
              </Styled.Tiers>
            )}
          </Styled.DescriptionAndTiersWrapper>
        </RoundedCard>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default PodcastSupport
