import styled from 'styled-components'
import {TextField, Button, CircularProgress} from '@material-ui/core'
import {LIGHTER_GRAY} from "../../../styles/colors";

export const Wrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  margin: 12px 0;
  ${(props) => props.fullWidth && `width: 100%`};
  position: relative;
  align-items: center;
`

export const StyledLabel = styled.label.attrs(
  (props: { darkMode?: boolean, borderless?: boolean }) => props,
)`
  font-weight: ${props => props.darkMode ? '300' : 'bold'};
  font-size: ${props => props.darkMode ? '1rem' : '22px'};
  margin-bottom: ${props => props.darkMode ? '8px' : '0'};
  color: ${props => props.darkMode ? '#000' : 'inherit'};
  margin-right: 8px;

  &.MuiFormLabel-root {
    font-family: 'Gellix';
  }
`

export const ContentWrapper = styled.div`
  position: relative;
`

export const LoadingSpinner = styled(CircularProgress)`
  margin-left: 12px;
`

export const FileWrapper = styled.div.attrs(
  (props: { darkMode?: boolean, borderless?: boolean }) => props,
)`
  font-weight: ${props => props.darkMode ? '300' : 'bold'};
  font-size: ${props => props.darkMode ? '1rem' : '22px'};
  margin-bottom: ${props => props.darkMode ? '8px' : '0'};
  color: ${props => props.darkMode ? '#000' : 'inherit'};
  padding: 18.5px 14px;
  display: block;
  //width: 100%;
  border: 2px solid #000000;
  border-radius: 24px;
  height: 24px;
  margin-top: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledTextField = styled(Button).attrs(
  (props: { darkMode?: boolean, borderless?: boolean }) => props,
)`
  border: ${props => props.borderless ? '0' : '1px solid #000000'} !important;
  border-radius: ${props => props.darkMode ? '24px' : '12px'} !important;

  .MuiOutlinedInput-root {
    &.Mui-disabled input {
      background: ${LIGHTER_GRAY} !important;
      cursor: not-allowed;
    }

    input, textarea {
      color: ${props => props.darkMode ? '#000' : 'inherit'} !important;
      padding: ${props => props.borderless ? '18.5px 0' : (props.darkMode ? '0' : '18.5px 14px')};
    }

    fieldset {
      border: ${props => props.borderless ? '0' : 'inherit'};

      &.MuiOutlinedInput-notchedOutline {
        border: ${props => props.darkMode ? '0' : 'inherit'};
      }
    }
  }
`

// This is a material-ui style. We are using this here, because is easier to override MUI style this way
export const transparentBorderStyle = (theme) => ({
  cssFocused: {
    borderColor: 'transparent',
    '&$cssFocused $notchedOutline': {
      borderColor: 'transparent',
    },
  },
  notchedOutline: {
    borderColor: 'transparent',
  },
  root: {
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: 'transparent',
    },
  },
  input: {
    fontFamily: 'Gellix',
  },
  error: {},
  disabled: {},
})