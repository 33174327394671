export const rootPath = '/'
export const getRootPath = (): string => {
  return rootPath
}

export const homePath = '/home'
export const getHomePath = (): string => {
  return homePath
}

export const loginPath = '/login'
export const getLoginPath = (): string => {
  return loginPath
}

export const errorPath = '/error'
export const getErrorPath = (): string => {
  return errorPath
}

export const searchPath = '/search'
export const getSearchPath = (): string => {
  return searchPath
}

export const creatorDashBoardPath = '/podcast/:podcastId/dashboard'
export const getCreatorDashBoardPath = (podcastId: string): string => {
  return `/podcast/${podcastId}/dashboard`
}

export const paymentsPath = '/payments/support/:podcastId'
export const getPaymentsPath = (podcastId: string, supportId: string = ''): string => {
  return `/payments/support/${podcastId}` + (supportId ? `?supportId=${supportId}` : '')
}

export const deactivateEmailsPath = '/emails/deactivate'
export const getDeactivateEmailsPath = (): string => {
  return deactivateEmailsPath
}

export const metricsPath = '/podcast/:podcastId/metricas'
export const getMetricsPath = (podcastId: string): string => {
  return `/podcast/${podcastId}/metricas`
}

export const editSupportPath = '/podcast/:podcastId/edit/support-page'
export const getEditSupportPath = (podcastId: string): string => {
  return `/podcast/${podcastId}/edit/support-page`
}

export const playlistListingPath = '/podcast/:podcastId/playlists'
export const getPlaylistListingPath = (podcastId: string): string => {
  return `/podcast/${podcastId}/playlists`
}

export const episodeListingPath = '/podcast/:podcastId/episodes'
export const getEpisodeListingPath = (podcastId: string): string => {
  return `/podcast/${podcastId}/episodes`
}

export const episodeCreatePath = '/podcast/:podcastId/episodes/create'
export const getEpisodeCreatePath = (podcastId: string): string => {
  return `/podcast/${podcastId}/episodes/create`
}

export const episodeEditPath = '/podcast/:podcastId/episodes/:episodeId'
export const getEpisodeEditPath = (podcastId: string, episodeId: string): string => {
  return `/podcast/${podcastId}/episodes/${episodeId}`
}

export const listArticlesPath = '/podcast/:podcastId/articles'
export const getListArticlesPath = (podcastId: string): string => {
  return `/podcast/${podcastId}/articles`
}

export const createArticlePath = '/podcast/:podcastId/create/article'
export const getCreateArticlePath = (podcastId: string): string => {
  return `/podcast/${podcastId}/create/article`
}

export const editArticlePath = '/podcast/:podcastId/edit/article/:articleId'
export const getEditArticlePath = (
  podcastId: string,
  articleId: string,
): string => {
  return `/podcast/${podcastId}/edit/article/${articleId}`
}

export const articlePath = '/article/:articleId'
export const getArticlePath = (articleId: string): string => {
  return `/article/${articleId}`
}

export const episodePath = '/episode/:episodeId'
export const getEpisodePath = (episodeId: string): string => {
  return `/episode/${episodeId}`
}

export const embedPath = '/embed/:episodeId'
export const getEmbedPatch = (episodeId: string): string => {
  return `/embed/${episodeId}`
}

export const podcastUrlPathName = '/:podcastUrlPathName'
export const getPodcastUrlPathName = (podcastUrlPathName: string): string => {
  return `${podcastUrlPathName}`
}

export const podcastPath = '/podcast/:podcastId'
export const getPodcastPath = (
  podcastId: string,
  goToForum = false,
): string => {
  return `/podcast/${podcastId}?forum=${goToForum}`
}

export const podcastContentPath = '/podcast/:podcastId/content'
export const getPodcastContentPath = (
  podcastId: string,
  goToForum = false,
): string => {
  return `/podcast/${podcastId}/content?forum=${goToForum}`
}

export const supportPodcastPathName = '/:podcastUrlPathName/apoios'
export const getSupportPodcastPathName = (
  podcastUrlPathName: string,
): string => {
  return `${podcastUrlPathName}/apoios`
}

export const supportPodcastPath = '/podcast/:podcastId/apoios'
export const getSupportPodcastPath = (podcastId: string): string => {
  return `/podcast/${podcastId}/apoios`
}

export const registerCreatorPath = '/register-creator/form'
export const getRegisterCreatorPath = (): string => {
  return registerCreatorPath
}

export const registerCreatorIntroPath = '/register-creator/welcome'
export const getRegisterCreatorIntroPath = (): string => {
  return registerCreatorIntroPath
}

export const registerCreatorOutroPath = '/register-creator/finished'
export const getRegisterCreatorOutroPath = (): string => {
  return registerCreatorOutroPath
}

export const editPodcastInfoPath = '/profile/:podcastId/edit-podcast-info'
export const getEditPodcastInfoPath = (podcastId: string): string => {
  return `/profile/${podcastId}/edit-podcast-info`
}

export const homeForumPath = '/forum/:podcastId'
export const getHomeForumPath = (podcastId: string): string => {
  return `/forum/${podcastId}`
}

export const topicPath = '/topic/:topicId'
export const getTopicPath = (topicId): string => {
  return `/topic/${topicId}`
}

export const forumTopicPath = '/forum/:podcastId/topic/:topicId'
export const getForumTopicPath = (podcastId: string, topicId): string => {
  return `/forum/${podcastId}/topic/${topicId}`
}

export const createTopicPath = '/forum/:podcastId/topic'
export const getCreateTopicPath = (podcastId: string): string => {
  return `/forum/${podcastId}/topic`
}

export const creatorProfilePath = '/podcast/:podcastId/settings'
export const getCreatorProfilePath = (podcastId: string): string => {
  return `/podcast/${podcastId}/settings`
}

export const createTopicWebViewPath = '/forum/:podcastId/webview/topic'
export const getCreateTopicWebViewPath = (podcastId: string): string => {
  return `/forum/${podcastId}/webview/topic`
}

export const confirmSecondaryEmailPath = '/confirmSecondaryEmail/:userId'
export const getConfirmSecondaryEmailPath = (userId: string): string => {
  return `/confirmSecondaryEmail/${userId}`
}

export const editProfilePath = '/editProfile'
export const getEditProfilePath = (): string => {
  return `/editProfile`
}