import styled from 'styled-components'

export const ImageAndTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const PodcastImage = styled.img`
  width: 98px;
  height: 98px;
  border-radius: 10px;
`

export const PodcastTitle = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 20px;
  font-family: Gellix;
  @media (max-width: 600px) {
    font-size: 40px;
    line-height: 90%;
  }
`

export const TitleAndLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 24px;

  @media (max-width: 600px) {
    text-align: center;
    margin-left: 0px;
    align-items: center;
  }
`

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 600px) {
    justify-content: center;
  }
`

export const StyledAnchor = styled.a<{ underlined?: boolean }>`
  text-decoration: none;
  color: black;
  margin-right: 10px;

  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }

  &:link {
    text-decoration: ${(p) => (p.underlined ? 'underline' : 'none')};
  }

  &:hover {
    color: #878787;
  }
  margin-bottom: 10px;
`
