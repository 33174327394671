import styled from 'styled-components'
import { Colors } from 'styles'

interface ButtonProps {
  backgroundColor?: string
  shadowColor?: string
  fontColor?: string
  size?: string
  leftIcon?: boolean
  hoverColor?: string
  disabled?: boolean
}

export const ButtonShadowWrapper = styled.div<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${Colors.GREY[400]};
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  box-shadow: 2px 2px 1px 0px ${(p) => p.shadowColor},
    3px 3px 0px 1px ${Colors.GREY[400]};
  margin: 8px 0px 8px 0px;
  cursor: pointer;
  ${({ disabled }) => disabled && 'opacity: 0.8;'}

  &:hover {
    background-color: ${(p) => p.hoverColor};
  }
`

export const ButtonShadowLabel = styled.span<ButtonProps>`
  font-family: PP-Neue-Machina;
  text-transform: uppercase;
  text-align: center;
  color: ${({ fontColor }) => fontColor};
  font-weight: 800;
  ${({ leftIcon }) => leftIcon && 'margin-left: 8px;'}
`
