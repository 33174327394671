import React from 'react'
import { useDispatch } from 'react-redux'
import {
  changeEpisode,
  play as playEpisode,
  pause as pauseEpisode,
  PlayerEpisodeInterface,
  showPlayer,
  setCurrentTime,
} from 'reducers/player'

const usePlayer = <T>() => {
  const dispatch = useDispatch()

  const show = () => {
    dispatch(showPlayer())
  }

  const playNewAudio = (episode: PlayerEpisodeInterface) => {
    dispatch(changeEpisode(episode))
    show()
  }

  const play = () => {
    dispatch(playEpisode())
  }

  const pause = () => {
    dispatch(pauseEpisode())
  }

  const updateCurrentTime = (time: number) => {
    dispatch(setCurrentTime(time))
  }

  return { show, play, pause, playNewAudio, updateCurrentTime }
}

export default usePlayer
