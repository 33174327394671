import styled from 'styled-components'
import { CircularProgress, Tab } from '@material-ui/core'
import Button from '../../components/Button/Button'
import { ReactComponent as TopicIconSvg } from "../../assets/icons/topic.svg";
import { Colors, Typography } from 'styles';
import { LETTER_SPACING_1 } from 'styles/typography';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const PageWrapperLeft = styled.div`
  display: flex;
  padding: 0px 3.5rem 20px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0px 1.5rem 20px;
  }
`

export const PodcastMenuWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 7rem);
`

export const MessageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #e4e4e4;
  align-self: start;
`

export const PodcastDescription = styled.div`
  font-size: 21px;
  margin-bottom: 35px;
  text-align: justify;

  @media (max-width: 900px) {
    max-width: 330px;
    font-size: 16px;
`
export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    font-weight: bold;
    font-family: SpaceGrotesk;
    text-transform: uppercase;
    padding: 6px 4px;
    border-bottom: 2px solid ${Colors.GREY[200]};
    flex:1;
    margin: 0 auto;

    &.MuiTabs-scroller {
      border-bottom: 2px solid ${Colors.GREY[200]};
    }

    @media (max-width: 900px) {
      width: 100%;
    }
  }


  &.MuiTab-textColorPrimary.Mui-selected {
    color: ${Colors.PURPLE[400]};
  }

  &.MuiTab-textColorInherit{
    opacity: 1;
    color: ${Colors.GREY[200]};
    
  }
  
  &.MuiTab-textColorInherit.Mui-selected{
    color: ${Colors.PURPLE[400]};
  }

`

export const TabPanelStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const Whitespace = styled.div`
  margin-bottom: 15px;
`

export const PlaylistTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  width: 60%;
  font-family: SpaceGrotesk;
  font-weight: 700;
  letter-spacing: -${LETTER_SPACING_1}px;

  @media (max-width: 900px) {
    font-size: 22px;
    width: 90%;
  }
`

export const PlaylistWhitespace = styled.div`
  margin-bottom: 30px;
`

export const CenteredWrapper = styled.div`
  width: 60%;

  @media (max-width: 900px) {
    width: 95%;
  }
`

export const ButtonWithMarginWrapper = styled.div`
  margin-top: 24px;
`
export const SeeMoreButton = styled(Button)`
  &.MuiButton-root {
    margin-top: 24px;
    display: block;
    background-color: #000;
    &:hover {
      color: #000;
    }
  }
`

export const LoadingSpinner = styled(CircularProgress)`
  margin-right: 15px;
  &.MuiCircularProgress-colorPrimary {
    color: white !important;
  }
`

export const TopicIcon = styled(TopicIconSvg)`
  width: 4rem;
  height: 4rem;
  margin-top: 12px;
`

export const ContentWrapper = styled.div`
  width: 50%;
  align-self: start;
  margin-right: 5%;

  @media (max-width: 900px) {
    width: 100%;
  }
`

export const ListHeader = styled.div`
  width: calc(100%); 
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.GREY[200]};
`

export const Title = styled.span`
  font-family: SpaceGrotesk;
  font-weight: 700;
  letter-spacing: -${LETTER_SPACING_1}px;
`

export const AditionalInfo = styled.span`
  font-family: SpaceGrotesk;
  font-weight: 700;
  width: calc( 100% * 0.2) ;
  letter-spacing: -${LETTER_SPACING_1}px;

  @media (max-width: 300px) {
    display: none;
  }
`

export const Tiers = styled.div`
  width: 45%;
  align-self: flex-start;
  margin-top: -150px;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 16px;
  }
`

export const TiersCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  gap: 10px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
      width: 100%;

    > div {
      width: 100%;
    }
  }
`

export const SupportCardContainer = styled.div`
 max-width: 45%;

 @media (max-width: 1023px) {
   max-width: 85%;
 }

 @media (max-width: 900px) {
   max-width: 100%;
}
`

export const SectionTitle = styled.p`
  font-family: PP-Neue-Machina;
  font-size: 3rem;
  margin-bottom: 10px;
  font-weight: 700;
  margin: 0;

  @media (max-width: 1023px) {
   font-size: 2rem;
  }

  @media (max-width: 900px) {
   font-size: 1.5rem;
  }
`

export const PaymentOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const PaymentOptionsContainer = styled.div`
  display: flex;
  gap: 5%;

  @media (max-width: 900px) {
    flex-direction: column;
   }
`

export const SupportCardColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 16px 32px 16px 0;

  @media (max-width: 900px) {
    width: 90%;
    margin: 16px 0;
   }
`

export const SupportText = styled.p`
  font-size: 1.3rem;
  font-family: SpaceGrotesk;
  font-weight: 600;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const SupportTextValue = styled.p`
  font-size: 1.3rem;
  font-family: SpaceGrotesk;
  font-weight: 600;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  color: ${Colors.PURPLE[400]};
  margin-bottom: 0px;
`

export const SupportValue = styled.p`
  font-size: 2rem;
  font-family: PP-Neue-Machina;
  font-weight: 700;
  color: ${Colors.PURPLE[400]};

    @media (max-width: 450px) {
      font-size: 1.5rem;

   }
`

export const SupportValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16;
  padding: 16px 60px;
  margin-bottom: 24px;
  box-shadow: 2px 2px 8px rgba(33, 36, 39, 0.25);
`

export const TopicWrapper = styled.div`
   width: 50%;

   @media (max-width: 900px) {
    width: 100%;
    margin: 16px 0;
   }
`
