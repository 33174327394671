import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './CreatorDashboardPage.styled'
import useFetch from '../../hooks/useFetch'
import {
  ArticleDTO,
  EpisodeDTO,
  FeedDTO,
  FeedItemDTO,
  PodcastDTO,
  SummarizedAnalytics,
} from '../../types'
import {
  getFeed,
  getPaginatedPodcastFollowers,
  getPaginatedPodcastSupporters,
  getPodcastDetails,
  getPodcastPayments,
  getSummarizedAnalytics,
} from '../../services/api'
import Button from '../../components/Button/Button'
import {
  MessageWrapper,
  PageWrapper,
} from '../podcastSupport/PodcastSupport.styled'
import {
  Avatar,
  Dialog as MuiDialog,
  DialogActions,
  Snackbar,
} from '@material-ui/core'
import { useGoToRoute } from '../../Routes/RouteAux'
import {
  getCreatorProfilePath,
  getEditArticlePath,
  getEditSupportPath,
  getEpisodeEditPath,
  getEpisodeListingPath,
  getListArticlesPath,
  getMetricsPath,
} from '../../Routes/RouteNames'
import { useTypedSelector } from '../../reducers'
import { ProfileType } from '../../reducers/profiles'
import FollowerImages from '../podcast/components/PodcastSummary/components/FollowerImages'
import { DateClass } from '../../utils/domain/DateClass'
import SearchBar from '../../components/SearchBar/SearchBar'
import parseSecondsToText from "../../utils/parseSecondsToText";

interface EditPodcastSupportPageProps {
  signOut: () => Promise<void>
}

const CreatorDashboardPage: React.FC<EditPodcastSupportPageProps> = ({
  signOut,
}) => {
  const { podcastId } = useParams<{ podcastId: string }>()

  const [requestError, setRequestError] = useState(false)
  const [recentContent, setRecentContent] = useState<FeedItemDTO[]>([])
  const [recentSupports, setRecentSupports] = useState<any[]>([])
  const [followerThumbnails, setFollowerThumbnails] = useState<string[]>([])
  const [supporterThumbnails, setSupporterThumbnails] = useState<string[]>([])

  const [followersPage, setFollowersPage] = useState<number>(0)
  const [showFollowersModal, setShowFollowersModal] = useState<boolean>(false)
  const [isLoadingFollowers, setIsLoadingFollowers] = useState(false)
  const [hasMoreFollowers, setHasMoreFollowers] = useState(false)
  const [followersSearchTerm, setFollowersSearchTerm] = useState<string>('')
  const [listingFollowers, setListingFollowers] = useState<any[]>([])

  const [supportersPage, setSupportersPage] = useState<number>(0)
  const [showSupportersModal, setShowSupportersModal] = useState<boolean>(false)
  const [isLoadingSupporters, setIsLoadingSupporters] = useState(false)
  const [hasMoreSupporters, setHasMoreSupporters] = useState(false)
  const [supportersSearchTerm, setSupportersSearchTerm] = useState<string>('')
  const [listingSupporters, setListingSupporters] = useState<any[]>([])

  const goToRoute = useGoToRoute()

  const profile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const {
    isLoading: isLoadingPodcastDetails,
    data: podcastDetails,
    error: errorPodcastDetails,
    fetchData: fetchPodcastDetails,
  } = useFetch<PodcastDTO>(
    useCallback(() => {
      return getPodcastDetails(podcastId)
    }, [podcastId]),
  )

  const {
    isLoading: isLoadingAnalytics,
    data: analyticsData,
    error: analyticsError,
    fetchData: fetchAnalytics,
  } = useFetch<SummarizedAnalytics>(
    useCallback(() => {
      return getSummarizedAnalytics(podcastId)
    }, [podcastId]),
  )

  const {
    isLoading: isLoadingFeed,
    data: feedData,
    error: errorFeed,
    fetchData: fetchFeed,
  } = useFetch<FeedDTO>(
    useCallback(() => getFeed(podcastId, '', true), [podcastId]),
  )

  const {
    isLoading: isLoadingPayments,
    data: paymentsData,
    error: errorPayments,
    fetchData: fetchPayments,
  } = useFetch<any>(
    useCallback(() => getPodcastPayments(podcastId), [podcastId]),
  )

  const getFollowers = async () => {
    setIsLoadingFollowers(true)

    const res = await getPaginatedPodcastFollowers(
      podcastId,
      followersPage,
      followersSearchTerm,
    )

    if (res.data && !res.error) {
      setListingFollowers((prevState) =>
        followersPage > 0 ? [...prevState, ...res.data] : res.data,
      )
      setHasMoreFollowers(res.data.length >= 50)
    }

    setIsLoadingFollowers(false)
  }

  const getSupporters = async () => {
    setIsLoadingSupporters(true)

    const res = await getPaginatedPodcastSupporters(
      podcastId,
      supportersPage,
      supportersSearchTerm,
    )

    if (res.data && !res.error) {
      setListingSupporters((prevState) =>
        supportersPage > 0 ? [...prevState, ...res.data] : res.data,
      )
      setHasMoreSupporters(res.data.length >= 50)
    }

    setIsLoadingSupporters(false)
  }

  useEffect(() => {
    if (feedData) {
      setRecentContent(
        feedData.items
          .filter((item) => {
            switch (item.itemType) {
              case 'episode': {
                return DateClass.fromLongDTO(
                  (item.item as unknown as EpisodeDTO).releaseDate,
                )
                  .getValue()
                  .isBefore(DateClass.now())
              }

              case 'article': {
                return (
                  (item.item as unknown as ArticleDTO).state === 'published'
                )
              }

              case 'topic':
              default: {
                return false
              }
            }
          })
          .slice(0, 5),
      )
    }
  }, [feedData])

  useEffect(() => {
    if (paymentsData) {
      console.log(paymentsData)
      setRecentSupports(paymentsData.slice(0, 5))
    }
  }, [paymentsData])

  useEffect(() => {
    if (podcastDetails) {
      setFollowerThumbnails(podcastDetails.followersInfo.thumbnails.slice(0, 5))
      setSupporterThumbnails(
        podcastDetails.supportersInfo.thumbnails.slice(0, 5),
      )
    }
  }, [podcastDetails])

  useEffect(() => {
    if (showFollowersModal) {
      setFollowersSearchTerm('')
      setFollowersPage(0)
    }
  }, [showFollowersModal])

  useEffect(() => {
    getFollowers()
  }, [followersSearchTerm, followersPage])

  useEffect(() => {
    if (showSupportersModal) {
      setSupportersSearchTerm('')
      setSupportersPage(0)
    }
  }, [showSupportersModal])

  useEffect(() => {
    getSupporters()
  }, [supportersSearchTerm, supportersPage])

  if (
    isLoadingPodcastDetails ||
    isLoadingAnalytics ||
    isLoadingFeed ||
    isLoadingPayments
  ) {
    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (errorPodcastDetails || errorFeed) {
    return (
      <BasePage signOut={signOut} isDark>
        <PageWrapper>
          <MessageWrapper>Ops, parece que tivemos um erro aqui.</MessageWrapper>
          <Button
            variant="contained"
            fontColor="black"
            onClick={() =>
              fetchPodcastDetails() &&
              fetchAnalytics() &&
              fetchFeed() &&
              fetchPayments()
            }
          >
            Tentar novamente
          </Button>
        </PageWrapper>
      </BasePage>
    )
  }

  const renderEpisode = (episode: EpisodeDTO) => {
    return (
      <Styled.ItemInformation
        onClick={() =>
          goToRoute(getEpisodeEditPath(podcastId, episode.episodeId))
        }
      >
        <Styled.ItemIcons>
          {episode.exclusiveToSupporters && <Styled.PigIcon />}
          <Styled.MicrophoneCircledIcon />
        </Styled.ItemIcons>

        <Styled.ItemDescription>
          <Styled.ItemTitle title={episode.title}>
            {episode.title}

            <Styled.ItemReleaseDate>
              {DateClass.fromLongDTO(episode.longReleaseDate)
                .getValue()
                .toDTO()}

              <span>{parseSecondsToText(episode.duration)}</span>
            </Styled.ItemReleaseDate>
          </Styled.ItemTitle>
          <Styled.ItemPlayCount>
            {episode.playsCount ?? 0} plays
          </Styled.ItemPlayCount>
        </Styled.ItemDescription>
      </Styled.ItemInformation>
    )
  }

  const renderArticle = (article: ArticleDTO) => {
    return (
      <Styled.ItemInformation
        onClick={() => goToRoute(getEditArticlePath(podcastId, article.id))}
      >
        <Styled.ItemIcons>
          <Styled.QuoteCircledIcon />
        </Styled.ItemIcons>

        <Styled.ItemDescription>
          <Styled.ItemTitle>{article.title}</Styled.ItemTitle>
          <Styled.ItemPlayCount>
            {article.pageViews ?? 0} views
          </Styled.ItemPlayCount>
        </Styled.ItemDescription>
      </Styled.ItemInformation>
    )
  }

  const renderFeedItem = (feedItem: FeedItemDTO) => {
    switch (feedItem.itemType) {
      case 'episode':
        return renderEpisode(feedItem.item as EpisodeDTO)
      case 'article':
        return renderArticle(feedItem.item as unknown as ArticleDTO)
      case 'topic':
      default:
        break
    }
  }

  return (
    <BasePage signOut={signOut}>
      <MuiDialog
        open={showFollowersModal}
        onClose={() => setShowFollowersModal(false)}
        fullWidth={true}
      >
        <Styled.DialogTextWrapper>
          <Styled.DialogText disableTypography>Seguidores</Styled.DialogText>
        </Styled.DialogTextWrapper>
        <DialogActions>
          <Styled.PodcastModalWrapper>
            <SearchBar
              initialSearchTerm={followersSearchTerm}
              placeholder="Buscar por seguidores"
              onChange={(term) => setFollowersSearchTerm(term)}
              fullWidth={true}
            />

            {isLoadingFollowers && (
              <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
            )}

            {!isLoadingFollowers && listingFollowers.length <= 0 && (
              <Styled.EmptyStateMesage>
                Nenhum seguidor encontrado.
              </Styled.EmptyStateMesage>
            )}

            {!isLoadingFollowers && listingFollowers.length > 0 && (
              <>
                <Styled.Table>
                  {listingFollowers.map((follower) => (
                    <Styled.TableRow>
                      <Styled.TableData>
                        {' '}
                        <Avatar src={follower.thumbnail} />
                      </Styled.TableData>
                      <Styled.TableData>{follower.name}</Styled.TableData>
                    </Styled.TableRow>
                  ))}
                </Styled.Table>

                {hasMoreFollowers && (
                  <Button
                    buttonColor="white"
                    buttonColorOnHover="#919191"
                    fontColor="black"
                    borderColor="black"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                      setFollowersPage(followersPage + 1)
                    }}
                  >
                    Ver mais...
                  </Button>
                )}
              </>
            )}
          </Styled.PodcastModalWrapper>
        </DialogActions>
      </MuiDialog>

      <MuiDialog
        open={showSupportersModal}
        onClose={() => setShowSupportersModal(false)}
        fullWidth={true}
      >
        <Styled.DialogTextWrapper>
          <Styled.DialogText disableTypography>Apoiadores</Styled.DialogText>
        </Styled.DialogTextWrapper>
        <DialogActions>
          <Styled.PodcastModalWrapper>
            <SearchBar
              initialSearchTerm={supportersSearchTerm}
              placeholder="Buscar por apoiadores"
              onChange={(term) => setSupportersSearchTerm(term)}
              fullWidth={true}
            />

            {isLoadingSupporters && (
              <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
            )}

            {!isLoadingSupporters && listingSupporters.length <= 0 && (
              <Styled.EmptyStateMesage>
                Nenhum apoiador encontrado.
              </Styled.EmptyStateMesage>
            )}

            {!isLoadingSupporters && listingSupporters.length > 0 && (
              <>
                <Styled.Table>
                  {listingSupporters.map((supporter) => (
                    <Styled.TableRow>
                      <Styled.TableData>
                        {' '}
                        <Avatar src={supporter.thumbnail} />
                      </Styled.TableData>
                      <Styled.TableData>{supporter.name}</Styled.TableData>
                    </Styled.TableRow>
                  ))}
                </Styled.Table>

                {hasMoreSupporters && (
                  <Button
                    buttonColor="white"
                    buttonColorOnHover="#919191"
                    fontColor="black"
                    borderColor="black"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                      setSupportersPage(supportersPage + 1)
                    }}
                  >
                    Ver mais...
                  </Button>
                )}
              </>
            )}
          </Styled.PodcastModalWrapper>
        </DialogActions>
      </MuiDialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!requestError}
        autoHideDuration={10000}
        onClose={() => setRequestError(undefined)}
        message={`Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte`}
      />

      <Styled.PageWrapper>
        <Styled.TopWrapper>
          <Styled.MainWrapper>
            <Styled.AvatarWrapper>
              <Styled.PictureWrapper>
                <Styled.MenuAvatar src={profile.avatar} />
              </Styled.PictureWrapper>

              <Styled.TitleWrapper>
                <Styled.CreatorTitle>{profile.name}</Styled.CreatorTitle>
                <Styled.CreatorLink>
                  orelo.cc/{podcastDetails.pathName}
                </Styled.CreatorLink>
              </Styled.TitleWrapper>
            </Styled.AvatarWrapper>

            <Styled.InfoWrapper>
              <Styled.MembersInfoWrapper
                onClick={() => setShowFollowersModal(true)}
              >
                <FollowerImages
                  images={followerThumbnails}
                  isFollower={false}
                />
                <Styled.MembersInfoCount>
                  {podcastDetails.followersInfo.count} Membro
                  {podcastDetails.followersInfo.count > 1 ? 's' : ''}
                </Styled.MembersInfoCount>
              </Styled.MembersInfoWrapper>

              <Styled.SupportersInfoWrapper
                onClick={() => setShowSupportersModal(true)}
              >
                <FollowerImages
                  images={supporterThumbnails}
                  isFollower={false}
                />
                <Styled.SupportersInfoCount>
                  {podcastDetails.supportersInfo.count} Apoiador
                  {podcastDetails.supportersInfo.count > 1 ? 'es' : ''}
                </Styled.SupportersInfoCount>
              </Styled.SupportersInfoWrapper>
            </Styled.InfoWrapper>

            {!analyticsError && (
              <Styled.SupportsButtonWrapper>
                <Button
                  buttonColor="black"
                  buttonColorOnHover="#919191"
                  fontColor="white"
                  borderColor="black"
                  onClick={() => goToRoute(getMetricsPath(podcastId))}
                >
                  R$ {analyticsData.paymentsAnalytics.thirtyDays.value}{' '}
                  arrecadados nos últimos 30 dias
                </Button>
              </Styled.SupportsButtonWrapper>
            )}
          </Styled.MainWrapper>

          <Styled.MenuWrapper>
            <Styled.MenuButton
              buttonColor="white"
              buttonColorOnHover="#919191"
              fontColor="black"
              borderColor="black"
              onClick={() => goToRoute(getListArticlesPath(podcastId))}
            >
              <Styled.QuoteIcon /> Textos e newsletters
            </Styled.MenuButton>

            <Styled.MenuButton
              buttonColor="white"
              buttonColorOnHover="#919191"
              fontColor="black"
              borderColor="black"
              onClick={() => goToRoute(getEpisodeListingPath(podcastId))}
            >
              <Styled.MicrophoneIcon /> Episódios
            </Styled.MenuButton>

            {profile.podcastRole === 'manager' && (
              <Styled.MenuButton
                buttonColor="white"
                buttonColorOnHover="#919191"
                fontColor="black"
                borderColor="black"
                onClick={() => goToRoute(getEditSupportPath(podcastId))}
              >
                <Styled.DollarCircledIcon /> campanhas e apoios
              </Styled.MenuButton>
            )}

            {profile.podcastRole === 'manager' && (
              <Styled.MenuButton
                buttonColor="white"
                buttonColorOnHover="#919191"
                fontColor="black"
                borderColor="black"
                onClick={() => goToRoute(getMetricsPath(podcastId))}
              >
                <Styled.GraphsIcon /> métricas
              </Styled.MenuButton>
            )}

            {profile.podcastRole === 'manager' && (
              <Styled.MenuButton
                buttonColor="white"
                buttonColorOnHover="#919191"
                fontColor="black"
                borderColor="black"
                onClick={() => goToRoute(getCreatorProfilePath(podcastId))}
              >
                <Styled.SettingsCircledIcon /> Configurações
              </Styled.MenuButton>
            )}
          </Styled.MenuWrapper>
        </Styled.TopWrapper>

        <Styled.BottomWrapper>
          <Styled.LatestContentsWrapper>
            <Styled.LatestContentsTitle>
              <span>ÚLTIMOS CONTEÚDOS</span>
            </Styled.LatestContentsTitle>

            <Styled.LatestContentContainer>
              {recentContent.map((feedItem) => renderFeedItem(feedItem))}
            </Styled.LatestContentContainer>
          </Styled.LatestContentsWrapper>

          {!errorPayments && (
            <>
              <Styled.BottomWrapperDivider />

              <Styled.LatestSupportsWrapper>
                <Styled.LatestSupportsTitle>
                  <span>APOIOS RECENTES</span>
                </Styled.LatestSupportsTitle>

                <Styled.Table>
                  {recentSupports.map((payment) => (
                    <Styled.TableRow>
                      <Styled.TableData>{payment.payerName}</Styled.TableData>
                      <Styled.TableData>
                        R$ <span>{payment.value}</span>
                      </Styled.TableData>
                    </Styled.TableRow>
                  ))}
                </Styled.Table>
              </Styled.LatestSupportsWrapper>
            </>
          )}
        </Styled.BottomWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default CreatorDashboardPage
