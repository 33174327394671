import React, { Fragment, useState } from 'react'
import * as Yup from 'yup'
import { MenuItem, Tooltip } from '@material-ui/core'
import { Formik, FormikValues } from 'formik'
import * as Styled from './ArticleForm.styled'
import { LastAutoSave } from './ArticleForm.styled'
import TextFieldField from '../Fields/TextFieldField/TextFieldField'
import RichTextEditorField from '../Fields/RichTextEditorField/RichTextEditorField'
import SelectField from '../Fields/SelectField/SelectField'
import Button from '../Button/Button'
import Info from '@material-ui/icons/Info'
import { uploadArticleImage } from '../../services/api'
import { Colors } from 'styles'
import { debounce } from "debounce";

interface FormValues {
  title: string
  description: string
  body: string
  visibility: string
  stringNotifyOnPublish: string
  image?: string
}

const createArticleValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório')
    .max(62, 'Tamanho máximo 62 caracteres'),
  description: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório')
    .max(200, 'Tamanho máximo 200 caracteres'),
  body: Yup.string()
    .required('Campo obrigatório')
    .nullable(),
  visibility: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório'),
  stringNotifyOnPublish: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório'),
})

interface ArticleFormProps {
  initialValues: {
    title?: string
    description?: string
    body?: string
    visibility?: string
    notifyOnPublish?: boolean
    image?: string
  }
  onSubmit: (values: FormValues) => void | Promise<void>
  podcastId: string
  isEdit?: boolean
  onAutoSave?: (values: FormValues) => Promise<void>
  isPublished: boolean
}

const ArticleForm: React.FC<ArticleFormProps> = ({
                                                   initialValues,
                                                   onSubmit,
                                                   isEdit,
                                                   podcastId,
                                                   onAutoSave,
                                                   isPublished
                                                 }) => {
  const [waitingForResponse, setWaitingForResponse] = useState(false)
  const [lastAutoSave, setLastAutoSave] = useState<string>()

  const uploadFile = async (file: File) => {
    const uploadFileResponse = {
      error: null,
      imageUrl: null,
    }
    const res = await uploadArticleImage(podcastId, file)

    if (res.error) {
      uploadFileResponse.error = 'Erro ao fazer upload da imagem. Tente novamente ou entre em contato com o suporte'
    } else {
      uploadFileResponse.imageUrl = res.data
    }
    return uploadFileResponse
  }

  const notifyOnPublishLabel = isEdit ? (
    <Fragment>
      <Styled.TextLabel>
        Este texto {initialValues.notifyOnPublish ? '' : 'não '}foi enviado via
        e-mail na data da sua publicação.
      </Styled.TextLabel>
      <div>Esta configuração não se aplica para textos já publicados</div>
    </Fragment>
  ) : (
    <Styled.LabelWrapper>
      <Styled.InfoLabel>Deseja enviar também como newsletter?</Styled.InfoLabel>
      <Tooltip
        title={`A newsletter será enviada via email para os seus seguidores caso a visibilidade seja "Aberto para todos" ou apenas para os seus apoiadores caso a visibilidade seja "Exclusivo para apoiadores"`}
        placement="top"
      >
        <Info/>
      </Tooltip>
    </Styled.LabelWrapper>
  )

  const onKeyUp = debounce((values: FormikValues) => {
    if (onAutoSave && !isPublished) {
      setWaitingForResponse(true)

      onAutoSave(values as FormValues).then(() => {
        setWaitingForResponse(false)
        setLastAutoSave((new Date).toLocaleTimeString())
      })
    }
  }, 1000)

  return (
    <Formik
      initialValues={{
        stringNotifyOnPublish: initialValues.notifyOnPublish ? 'true' : 'false',
        ...initialValues,
      }}
      onSubmit={async (values) => {
        setWaitingForResponse(true)
        await onSubmit(values as FormValues)
        setWaitingForResponse(false)
      }}
      validationSchema={createArticleValidationSchema}
    >
      {({ handleSubmit, errors, touched, values, setFieldValue }) => {
        return (
          <Styled.FlexForm>
            <Styled.InputWrapper>
              <TextFieldField
                placeholder="Título"
                name="title"
                variant="outlined"
                error={!!(touched.title && errors?.title)}
                helperText={touched.title && errors?.title}
                transparentBorder
                fullWidth
                style={{ fontSize: 48, fontWeight: 800 }}
                value={values.title}
                onKeyUp={() => onKeyUp(values)}
                darkMode
              />
            </Styled.InputWrapper>

            <Styled.InputWrapper>
              <Styled.SubtitleInput
                placeholder="Subtítulo"
                name="description"
                variant="outlined"
                multiline
                error={!!(touched.description && errors?.description)}
                helperText={touched.description && errors?.description}
                transparentBorder
                fullWidth
                style={{ fontSize: 24 }}
                value={values.description}
                onKeyUp={() => onKeyUp(values)}
                darkMode
              />
            </Styled.InputWrapper>

            <RichTextEditorField
              name="body"
              placeholder="Comece seu texto..."
              error={!!(touched.body && errors?.body)}
              helperText={touched.body && errors?.body}
              toolbarOptions={[
                'bold',
                'italic',
                'underline',
                'code',
                'heading',
                'block-quote',
                'numbered-list',
                'bulleted-list',
                'hyperlink',
                'image',
              ]}
              fontFamily="PT-Serif"
              uploadFile={uploadFile}
              onInsertImage={(imageUrl) => setFieldValue('image', imageUrl)}
              onKeyUp={() => onKeyUp(values)}
              darkMode
            />

            <SelectField
              name="visibility"
              label="Quem poderá ver esse texto?"
              variant="outlined"
              displayEmpty
              error={!!(touched.visibility && errors?.visibility)}
              helperText={touched.visibility && errors?.visibility}
              disabled={isEdit}
              darkMode
            >
              <MenuItem value="" disabled>
                Escolha a visibilidade
              </MenuItem>
              <MenuItem value={'open'}>Aberto para todos</MenuItem>
              <MenuItem value={'supporters'}>
                Exclusivo para apoiadores
              </MenuItem>
            </SelectField>

            <SelectField
              name="stringNotifyOnPublish"
              label={notifyOnPublishLabel}
              variant="outlined"
              displayEmpty
              error={!!(touched.visibility && errors?.visibility)}
              helperText={touched.visibility && errors?.visibility}
              disabled={isEdit}
              darkMode
            >
              <MenuItem value="" disabled>
                Sim ou não
              </MenuItem>
              <MenuItem value={'true'}>Sim</MenuItem>
              <MenuItem value={'false'}>Não</MenuItem>
            </SelectField>

            <Styled.SubmitButtonWrapper>
              <Button
                variant="contained"
                size="large"
                fontSize="large"
                buttonColorOnHover={Colors.TEXT_DARK}
                buttonColor={Colors.BRAND_PRIMARY}
                fontColor={Colors.TEXT_LIGHTEN}
                onClick={() => handleSubmit()}
                disabled={waitingForResponse}
              >
                { isPublished ? 'Salvar' : 'Publicar' }
              </Button>
              {!isPublished && <Button
                variant="contained"
                size="large"
                fontSize="large"
                buttonColorOnHover={Colors.TEXT_DARK}
                buttonColor={Colors.BRAND_PRIMARY}
                fontColor={Colors.TEXT_LIGHTEN}
                onClick={() => onKeyUp(values)}
                disabled={waitingForResponse}
                style={{ marginLeft: '8px' }}
              >
                Salvar Rascunho
              </Button>}
              {waitingForResponse && <Styled.LoadingCircle/>}
              {!isPublished && lastAutoSave && <LastAutoSave>Salvo às {lastAutoSave}</LastAutoSave>}
            </Styled.SubmitButtonWrapper>
          </Styled.FlexForm>
        )
      }}
    </Formik>
  )
}

export default ArticleForm
