import React, { useState } from 'react'
import { Search as SearchIcon } from '@material-ui/icons'
import * as Styled from './SearchBar.styled'
import { createPathWithQueryParam, useGoToRoute } from '../../Routes/RouteAux'
import { getSearchPath } from '../../Routes/RouteNames'

interface SearchBarProps {
  initialSearchTerm?: string
  placeholder?: string
  onChange?: (term: string) => void
  fullWidth?: boolean
}

const SearchBar: React.FC<SearchBarProps> = ({
  initialSearchTerm,
  placeholder,
  onChange,
  fullWidth,
}) => {
  const [searchString, setSearchString] = useState(initialSearchTerm || '')

  const goToRoute = useGoToRoute()

  const searchCurrentString = () => {
    const path = createPathWithQueryParam(getSearchPath(), {
      searchTerm: searchString,
    })

    goToRoute(path)
  }

  return (
    <Styled.SearchWrapper fullWidth={fullWidth}>
      <Styled.SearchInput
        placeholder={placeholder ?? 'BUSCAR'}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !onChange) searchCurrentString()
        }}
        onChange={(e) => {
          setSearchString(e.target.value)

          if (onChange) {
            onChange(e.target.value)
          }
        }}
        defaultValue={initialSearchTerm}
        style={{ textTransform: 'uppercase' }}
        id="searchInput"
      />
      <Styled.SearchIconWrapper>
        <SearchIcon />
      </Styled.SearchIconWrapper>
    </Styled.SearchWrapper>
  )
}

export default SearchBar
