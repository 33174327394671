import styled from 'styled-components'
import { Colors, Typography } from 'styles'

export const PageWrapper = styled.div`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin: 0 10%;
  align-items: center;

  /* width: 60%; */

  @media (max-width: 600px) {
    width: 95%;
  }
`

export const MessageWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: SpaceGrotesk;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  color: ${Colors.GREY[400]};
`