import styled from 'styled-components'
import NumberFormat from 'react-number-format'

import { Colors, Typography } from 'styles'
import Button from '../../../components/Button/Button'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Column = styled.div.attrs((props: { flex: number }) => props)`
  width: 45%;
  display: flex;
  padding: 15px;
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  align-items: center;
  margin-top: 80px;
  color:${Colors.GREY[400]};
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  flex: 1;
  align-items: baseline;
  width: 100%;
  @media (max-width: 600px) {
    display: block;
    padding: 0;
  }
`

export const PaymentDataWrapper = styled.div`
  padding: 16px;
  flex-direction: column;
  flex: 1;
  border-radius: 12px;
  border: 2px solid ${Colors.PURPLE[400]};
  padding: 33px;
  margin-bottom: 24px;
  @media (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
  }
`

export const TitleText = styled.div`
  font-size: 22px;
  font-weight: bold;
  font-family: SpaceGrotesk;
  text-align: center;
`

export const SubtitleText = styled.div`
  font-size: 20px;
  font-weight: bold;
`

export const RedTitleText = styled(TitleText)`
  color: #910d24;
  margin-bottom: 16px;
  text-align: center;
`

export const BodyText = styled.div`
  font-size: 16px;
  font-family: Inter;
  text-align: center;
`

export const ErrorText = styled.div`
  font-size: 16px;
  color: ${Colors.SUPPORT_ERROR};
`

export const SupporterSubtitle = styled(SubtitleText)`
  margin: 12px auto;
  font-family: SpaceGrotesk;
  text-align: center;
`

export const SupporterBodyText = styled(BodyText)`
  margin: 12px 0;
`

export const Price = styled(TitleText)`
  margin: 8px 0;
`

export const NumberFormatStyled = styled(NumberFormat)`
  font-size: 22px;
  font-weight: bold;
  margin: 8px 0;
  background-color: transparent;
  text-align: center;
  border: 1px solid ${Colors.TEXT_LIGHT};
  border-left: none;
  border-top: none;
  border-right: none;
  color: ${Colors.TEXT_LIGHT};
  outline: none;
  &::placeholder {
    color: ${Colors.TEXT_LIGHT};
  }
`

export const SubTitle = styled(TitleText)`
  margin: 8px 0;
`

export const PriceHeader = styled(BodyText)`
  margin-top: 8px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const LoadingText = styled.div`
  margin-top: 16px;
  color: ${Colors.GREY[400]};
  font-family: Inter;
`

export const FinishedPaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PaymentButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`

export const WrapperWithMargin = styled.div`
  margin: 16px 0;
`

export const BodyTextWithMargin = styled(BodyText)`
  margin-bottom: 12px;
  text-align: center;
`

export const TitleTextWithMargin = styled(TitleText)`
  margin-bottom: 16px;
  text-align: center;
  font-family: PP-Neue-Machina;
  text-transform: uppercase;
`

export const BodyTextWithBiggerMargin = styled(BodyText)`
  margin-bottom: 64px;
`

export const ButtonMargin = styled.div`
  margin-top: 32px;
`

export const PixFormWrapper = styled.div`
  padding: 32px;
`

export const Title = styled.h1`
  font-size: 34px;
  color: white;
  font-family: Gellix;
  font-weight: bold;
  margin-top: 34px;
  margin-bottom: 34px;
`
export const Thumbnail = styled.img`
  width: 147px;
  height: 147px;
  border-radius: 8px;
  background-color: black;
  object-fit: cover;
`
export const Description = styled.div`
  color: ${Colors.GREY[400]};
  font-size: 20px;
`

export const SupportPriceWrapper = styled.div`
  background: ${Colors.GREY[50]};
  box-shadow: 2px 2px 8px rgba(33, 36, 39, 0.25);
  padding: 24px 16px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const SupportPriceWrapperColumn = styled.div`
  background: ${Colors.GREY[50]};
  box-shadow: 2px 2px 8px rgba(33, 36, 39, 0.25);
  padding: 24px 16px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 56px;
`

export const SupportPriceWrapperWithMargin = styled(SupportPriceWrapper)`
  margin-top: 10px;
  margin-right: 56px;
`

export const PixWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SupportPrice = styled.div`
  line-height: 64px;
  color: ${Colors.MILK_WHITE};
  font-size: 2rem;
  font-weight: bold;
  font-family: Fraunces;
`

export const CustomStyleButton = styled(Button)`
  &.MuiButton-root {
    &.MuiButton-contained {
      background-color: #fff;
      font-size: 16px;
      line-height: 24px;
      padding-left: 24px;
      padding-right: 24px;
      border-radius: 100px;
    }
    &.MuiButton-contained.Mui-disabled {
      background-color: #666;
      font-size: 12px;
      .MuiButton-label {
        color: #222;
      }
    }
    .MuiButton-label {
      color: #000;
      font-family: Fraunces;
    }
  }
`

export const CopyPixButtonWrapper = styled.div`
  margin: 12px 0;
`

export const PixLogo = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 8px;
`

export const ErrorContainer = styled.div`
  width: 60%;
  text-align: center;
`

export const SupportCardColumn = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  margin: 16px 32px 16px 3.5rem;

  @media (max-width: 900px) {
    width: 90%;
    margin: 16px 2rem;
   }
`

export const SupportText = styled.p`
  font-size: 1.3rem;
  font-family: SpaceGrotesk;
  font-weight: 600;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const SupportTextValue = styled.p`
  font-size: 1.3rem;
  font-family: SpaceGrotesk;
  font-weight: 600;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  color: ${Colors.PURPLE[400]};
  margin-bottom: 0px;
`

export const SupportValue = styled.p`
  font-size: 2rem;
  font-family: PP-Neue-Machina;
  font-weight: 700;
  color: ${Colors.PURPLE[400]};

    @media (max-width: 450px) {
      font-size: 1.5rem;
   }
`

export const SupportValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16;
  padding: 16px 60px;
  margin-bottom: 24px;
  box-shadow: 2px 2px 8px rgba(33, 36, 39, 0.25);
`
