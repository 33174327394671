import styled from 'styled-components'

interface PageWrapperStyleProps {
  isDark?: boolean
  flex?: number
}
export const PageWrapper = styled.div.attrs(
  (props: PageWrapperStyleProps) => props,
)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
  background-color: ${(props) => (props.isDark ? '#121212' : 'transparent')};
  flex: ${(props) => (props.flex ? props.flex : 'inherit')};
`

export const ContentWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  flex: 1;
`
